import { actionTypes, plotTypeOptions } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus } from 'helpers/constants';
import { createParamMeasurementAssetObjTrend, getZoomingDates, createParamMeasurementAssetObj } from '../../helpers';
import { pagesWithTrendChart } from '../../constants';
import { transformPlotRequestData, getPlotAPI, transformPlotRequestDataTrend } from './helpers';

const resetChartAction = () => {
    return {
        type: actionTypes.RESET_CHART,
    };
};

export const setActiveTab = (activeTab) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ACTIVE_TAB, activeTab });
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};

export const resetChart = () => {
    return (dispatch) => {
        dispatch(resetChartAction());
    };
};

const setChartDataLoadingAction = getApiActionCreator(actionTypes.SET_CHART_DATA_LOADING_STATUS);

const loadKPIDataAction = (data, kpi, component, kpiType, trendChartZoom, color = undefined) => {
    return {
        type: actionTypes.LOAD_KPI_DATA,
        data,
        kpi,
        component,
        color,
        kpiType,
        trendChartZoom: trendChartZoom
    };
};

export const removeKPIData = (data) => {
    return {
        type: actionTypes.REMOVE_SELECTED_KPI,
        data
    };
};

export const removeSelectedKPI = (data, selectedAsset, selectedKPIType) => {
    return {
        type: actionTypes.REMOVE_SELECTED_KPI_CHKBOX,
        data,
        selectedAsset,
        selectedKPIType
    };
};

export const setSelectedDates = (from, to) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_DATE_SELECTION, dateSelection: { from, to } });
    };
};

export const getChartDataForNewDate = (list, isTrendChartZoomed, fromZoom, toZoom, selectedKPIType) => {
    const component = list.map((kpi) => kpi.component);
    const series = list.map((kpi) => kpi.kpi);
    const componentKPITypes = selectedKPIType === 1 ? transformPlotRequestDataTrend(list) : transformPlotRequestData(component, series);
    return (dispatch, getState) => {
        const { chartView: { selectedKPIType } } = getState();
        let { fromDate, toDate, zoomed } = getZoomingDates(getState(), pagesWithTrendChart.crossAssetVisualization);

        if (isTrendChartZoomed) {
            fromDate = fromZoom;
            toDate = toZoom;
            zoomed = true;
        } else if (fromZoom && toZoom) {
            fromDate = fromZoom;
            toDate = toZoom;
            zoomed = false;
        }

        dispatch(setChartDataLoadingAction(apiCallStatus.LOADING));
        const apiKpiData = getPlotAPI(getState());
        apiKpiData(componentKPITypes, fromDate, toDate)
            .then((chartData) => {
                list.forEach((item) => {
                    if (chartData && Array.isArray(chartData)) {
                        dispatch(loadKPIDataAction(chartData, item.kpi, item.component, selectedKPIType, {
                            isZoomed: zoomed,
                            from: fromDate,
                            to: toDate
                        }));
                    }
                });
                dispatch(setChartDataLoadingAction(apiCallStatus.SUCCESS));
            })
            .catch((err) => {
                dispatch(setChartDataLoadingAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const getChartData = (kpi, component, selectedKPIType) => {
    let formattedRequest;
    if (selectedKPIType === 1) {
        formattedRequest = createParamMeasurementAssetObjTrend(kpi, component);
    } else if (kpi.plotTypeName === plotTypeOptions.histogram || kpi.plotTypeName === plotTypeOptions.scatterPlot) {
        formattedRequest = createParamMeasurementAssetObj(kpi, component);
    }
    return (dispatch, getState) => {

        const { chartView: { selectedKPIType } } = getState();
        const { fromDate, toDate, zoomed } = getZoomingDates(getState(), pagesWithTrendChart.crossAssetVisualization);

        dispatch(setChartDataLoadingAction(apiCallStatus.LOADING));
        const apiKpiData = getPlotAPI(getState());
        apiKpiData(formattedRequest, fromDate, toDate)
            .then((chartData) => {
                dispatch(loadKPIDataAction(chartData, kpi, component, selectedKPIType, {
                    isZoomed: zoomed,
                    from: fromDate,
                    to: toDate
                }));
                dispatch(setChartDataLoadingAction(apiCallStatus.SUCCESS));
            })
            .catch((err) => {
                dispatch(setChartDataLoadingAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const setIsZoomed = (isTrendChartZoomed) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ZOOMED, isTrendChartZoomed });
    };
};

export const resetChartData = (list, selectedKPIType) => {
    return (dispatch, getState) => {
        const { from, to } = getState().overview.dateSelection;
        dispatch(getChartDataForNewDate(list, false, from, to, selectedKPIType));
    };
};

export const zoomInToSelectedTrendKpiData = (list, isTrendChartZoomed, from, to, selectedKPIType) => {
    return (dispatch) => {
        return dispatch(getChartDataForNewDate(list, isTrendChartZoomed, from, to, selectedKPIType));
    };
};
