import React from 'react';
import {
    IconFault,
    IconAlarm,
    IconWarningType,
    IconNotification,
    IconInfoType
} from 'svgIcons/MotionPortalIcons';
import colors from 'theme/_colors.scss';


export const SET_EVENT_LOG_FILTERS = 'eventlog/SET_EVENT_LOG_FILTERS';
export const SET_EVENT_LOG_DATE_KIND = 'eventlog/SET_EVENT_LOG_DATE_KIND';
export const SET_IS_EXPAND = 'eventlog/SET_IS_EXPAND';
export const SET_DATE_SELECTION = 'overview/SET_DATE_SELECTION';
export const CLEAR_ASSISTANCE_PAGE = 'eventlist/assistance/CLEAR_ASSISTANCE_PAGE';
export const SET_SELECTED_EVENT = 'eventlog/SET_SELECTED_EVENT';
export const GET_EVENT_LOGS = 'detailedInformation/GET_EVENT_LOGS';
export const SET_SELECTED_ASSET = 'powertrainAssetDetails/SET_SELECTED_ASSET';
export const CLOSE_EVENT_LOG = 'eventDetailsSS/CLOSE_EVENT_LOG';
export const UPDATE_EVENT_LOGS = 'eventlog/UPDATE_EVENT_LOGS';
export const CLEAR_STORE = 'eventlog/CLEAR_STORE';
export const LOAD_CAPABILITIES = 'eventlog/LOAD_CAPABILITIES';
export const SET_SELECTED_ASSETS = 'eventlog/SET_SELECTED_ASSETS';

export const EventTypeAssetApplicability = {
    ALL: 0,
    DRIVE: 3,
    SMART_SENSOR: 1
};

export const dateKind = {
    UTC: 0,
    Local: 1
};

export const eventStatus = {
    ALL: 'All',
    OPEN: 'Open',
    CLOSED: 'Closed'
};

export const EventTypeIDs = {
    FAULT: 'Fault',
    ALERT: 'Alert',
    ALARM: 'Alarm',
    WARNING: 'Warning',
    NOTIFICATION: 'Notification',
    INFO: 'Info'
};

export const eventClosingReason = {
    Dismiss: 'Dismiss',
    FalseAlarm: 'FalseAlarm',
    Corrected: 'Corrected',
    Auto: 'Auto'
};

export const causeOfEvents = {
    All: 'All',
    Unclassified: 'Unclassified',
    Irrelevant: 'Irrelevant',
    Drive: 'Drive',
    Motor: 'Motor',
    OtherPTC: 'OtherPTC',
    Process: 'Process',
    Ambient: 'Ambient',
    Unclear: 'Unclear'
};

export const EventTypes = [
    {
        typeID: EventTypeIDs.FAULT,
        icon: <IconFault />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeFault',
        applicableTo: EventTypeAssetApplicability.DRIVE
    },
    {
        typeID: EventTypeIDs.ALERT,
        icon: <IconWarningType />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeAlert',
        applicableTo: EventTypeAssetApplicability.SMART_SENSOR
    },
    {
        typeID: EventTypeIDs.ALARM,
        icon: <IconAlarm />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeAlarm',
        applicableTo: EventTypeAssetApplicability.SMART_SENSOR

    },
    {
        typeID: EventTypeIDs.WARNING,
        icon: <IconWarningType />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeWarning',
        applicableTo: EventTypeAssetApplicability.ALL
    },
    {
        typeID: EventTypeIDs.NOTIFICATION,
        icon: <IconNotification />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeNotification',
        applicableTo: EventTypeAssetApplicability.DRIVE
    },
    {
        typeID: EventTypeIDs.INFO,
        icon: <IconInfoType color={colors.jungleGreen} />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeInfo',
        applicableTo: EventTypeAssetApplicability.DRIVE
    },
];

export const EventTypesIconWithName = [
    {
        typeID: EventTypeIDs.FAULT,
        icon: <IconFault />,
        applicableTo: EventTypeAssetApplicability.DRIVE
    },
    {
        typeID: EventTypeIDs.ALARM,
        icon: <IconAlarm />,
        applicableTo: EventTypeAssetApplicability.SMART_SENSOR
    },
    {
        typeID: EventTypeIDs.ALERT,
        icon: <IconWarningType />,
        applicableTo: EventTypeAssetApplicability.SMART_SENSOR
    },
    {
        typeID: EventTypeIDs.WARNING,
        icon: <IconWarningType />,
        applicableTo: EventTypeAssetApplicability.ALL
    },
    {
        typeID: EventTypeIDs.NOTIFICATION,
        icon: <IconNotification />,
        applicableTo: EventTypeAssetApplicability.DRIVE
    },
    {
        typeID: EventTypeIDs.INFO,
        icon: <IconInfoType color={colors.jungleGreen} />,
        applicableTo: EventTypeAssetApplicability.DRIVE
    },
];
