import { createSelector } from 'reselect';
import { getAllAssets } from 'helpers/selectors';

const getSubscriptionStatus = ({ powertrainTools }) => {
    return powertrainTools?.subscriptionStatus?.subscriptionArr?.data?.assetSubscriptionsInfos;
};

const getNewCapability = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails?.newAssetCapability;
};

const getProductTypeCode = ({ assetOverview }) => {
    return assetOverview?.assetOverview;
};

export const getModel = createSelector([
    getSubscriptionStatus,
    getAllAssets,
    getNewCapability,
    getProductTypeCode
], (
    subscriptionStatus,
    allAssets,
    newSelectedAssetCapability,
    productTypeCode
) => {
    return {
        subscriptionStatus,
        allAssets,
        newSelectedAssetCapability,
        productTypeCode
    };
});
