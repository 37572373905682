import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import HealthIcon from './HealthIcon';
import { SECTION_BUTTONS, KPI_BUTTONS } from './constants';
import clsx from 'clsx';
import { addTrendKpi, removeSelectedKpi } from '../../actions';
import { Checkbox } from 'sharedComponents/Checkbox';
import { SearchBox } from 'sharedComponents/SearchBox';

const ParameterSection = (props) => {
    const { t: translate } = useTranslation();
    const { section, capability, selectedTrendKPIs } = props;
    const [searchboxFilter, setSearchboxFilter] = useState('');
    const dispatch = useDispatch();
    if (!section || !section.data || isEmpty(section.data)) {
        return null;
    }
    const { data } = section;
    let kpiData = data;
    const serchBoxValue = searchboxFilter.toLowerCase();
    if (serchBoxValue !== '' && serchBoxValue !== null) {
        kpiData = data?.filter((kpi) => {
            return kpi?.label?.toLowerCase().includes(serchBoxValue);
        });
    }
    const toggleMeasurement = (kpi, isSelected) => {
        if (isSelected) {
            dispatch(removeSelectedKpi(kpi));
        }
        else {
            console.log('The Selected KPI Value', kpi);
            console.log('The Old Selected KPI List', selectedTrendKPIs);
            dispatch(addTrendKpi(kpi));
        }
    };

    return <Col xl lg={12} className='measurement-list'>
        <div className='measurement-list-title'>
            <div className='measurement-list-buttons'>
                <div>{translate('ABB.Powertrain.Frontend.detailedInformationOperationalParametersTab')}</div>
                {
                    map(SECTION_BUTTONS, (SectionButton, index) => {
                        return <SectionButton section={section} capability={capability} key={index} />;
                    })
                }
            </div>
            <div className='signal-group-header-info'>
                <span>{selectedTrendKPIs.length}{' '}{translate('ABB.Powertrain.Frontend.selected')}</span>
                <div className='kpisignal__filter__search'>
                    <SearchBox
                        placeholder={translate('ABB.Powertrain.Frontend.searchSignal')}
                        onChange={(searchTerm) => setSearchboxFilter(searchTerm)}
                        value={searchboxFilter} />
                </div>
            </div>
        </div>
        <div className='measurement-list-body'>
            {map(kpiData, (kpi) => {
                const isSelected = !!find(selectedTrendKPIs, (selectedTrendKPI) => kpi.timeseriesKey === selectedTrendKPI);
                return <div className={clsx('measurement-list-item', isSelected && 'selected-measurement-list-item')}
                    onClick={() => kpi?.isPlottableToTrendChart && toggleMeasurement(kpi, isSelected)}
                    key={kpi.label}>
                    <div className='data-command-checkbox'>
                        <Checkbox checked={isSelected}
                            disabled={!kpi?.isPlottableToTrendChart}
                        />
                    </div>
                    <div className='data-label'>
                        <div className='health-icon'>
                            <HealthIcon kpi={kpi} />
                        </div>
                        <div>{kpi.label}</div>
                    </div>
                    <div className='data-value'>{kpi.displayValue ?? '-'}</div>
                    <div className='data-command'>
                        {
                            map(KPI_BUTTONS, (KpiButton, index) => {
                                return <KpiButton
                                    kpi={kpi}
                                    capability={capability}
                                    isSelected={isSelected}
                                    key={index} />;
                            })
                        }

                    </div>
                </div>;
            })}
        </div>
    </Col>;
};

ParameterSection.propTypes = {
    section: PropTypes.shape({
        data: PropTypes.array,
        sectionId: PropTypes.any
    }),
    capability: PropTypes.object,
    selectedTrendKPIs: PropTypes.array
};

export default ParameterSection;
