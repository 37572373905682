import { createSelector } from 'reselect';

const getLoggers = ({ eventDetailsCMD }) => {
	return eventDetailsCMD?.loggers?.data;
};

const geteventLogDateKind = ({ eventLog }) => {
	return eventLog.dateKind;
};

const getLoggersLoadingStatus = ({ eventDetailsCMD }) => {
	return eventDetailsCMD.loggers?.loadingStatus;
};

const getLoggerFileLoadingStatus = ({ eventDetailsCMD }) => {
	return eventDetailsCMD.loggerFile?.loadingStatus;
};

const getLoggerId = ({ eventDetailsCMD }) => eventDetailsCMD.loggerId?.data;

export const getModel = createSelector([
	getLoggers,
	geteventLogDateKind,
	getLoggersLoadingStatus,
	getLoggerFileLoadingStatus,
	getLoggerId,
],
	(
		loggers,
		dateKind,
		loggersLoadingStatus,
		loggerFileLoadingStatus,
		loggerId
	) => {
		return {
			loggers,
			dateKind,
			loggersLoadingStatus,
			loggerFileLoadingStatus,
			loggerId
		};
	});
