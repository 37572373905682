import { t } from 'i18next';

export const translate = (...args) => {
    return t(...args);
};

export const getTranslatedKPIName = (kpi) => {
    return kpi.appendCodeToTranslation && translate(kpi.measurementTypeKey)
        && `${kpi.measurementTypeIdentifier} ${translate(kpi.measurementTypeKey)}` ||
        translate(kpi.measurementTypeKey) || kpi.displayName || '';
};

export const getTranslatedKPINameV2 = (kpi) => {
    if (kpi.timeseriesNameTextKey) {
        return kpi.appendCodeToTranslation && kpi.timeseriesKey ? `${kpi.timeseriesKey} ${translate(kpi.timeseriesNameTextKey) || kpi.timeseriesName}` : `${translate(kpi.timeseriesNameTextKey) || kpi.timeseriesName}`;
    }
    return kpi.timeseriesName ? translate(kpi.timeseriesName) : kpi.displayName;
};

export const getTranslatedKPINameEvent = (kpi) => {
    if (kpi.timeseriesNameTextKey) {
        return kpi.timeseriesGroupCode === 'trends' ? `${translate(kpi.timeseriesNameTextKey) || kpi.timeseriesName}` : `${kpi.timeseriesKey || ''} ${translate(kpi.timeseriesNameTextKey) || kpi.timeseriesName}`;
    }
    return kpi.timeseriesName ? translate(kpi.timeseriesName) : kpi.displayName;
};
