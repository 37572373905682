import { ComponentApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import { actionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';

const exportTrendKpiAction = getApiActionCreator(actionTypes.EXPORT_EXCEL_MEASUREMENT);

export const exportExcelForMeasurementTypeGroup = (asset, params) => {
    return (dispatch) => {
        dispatch(exportTrendKpiAction(apiCallStatus.LOADING));
        return ComponentApi.exportExcelForMeasurementTypeGroup(asset, params)
            .then((data) => {
                dispatch(exportTrendKpiAction(apiCallStatus.SUCCESS, data));
            })
            .catch((err) => {
                dispatch(exportTrendKpiAction(apiCallStatus.ERROR, null, err));
            });
    };
};


export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};
