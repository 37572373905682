import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import IconDownload from 'svgIcons/MotionPortalIcons/IconDownload';
import { isNull } from 'lodash';

import { actionTypes, assetMenuItems } from '../../../../../../Header/constants';
import { setMeasurementDialogParams } from '../../actions';


const DownloadButton = (props) => {
    const dispatch = useDispatch();
    const { kpi, capability } = props;

    const downloadMeasurement = (e) => {
        e.stopPropagation();
        dispatch(setMeasurementDialogParams({
            measurementTypeIds: kpi?.timeseriesKey ? kpi?.timeseriesKey.split() : ''
        }));
        dispatch({ type: actionTypes.SELECT_MENU_ITEM, selectedMenuItem: assetMenuItems.EXPORT_MEASUREMENTS });
    };
    return <>
        {capability.hasMeasurementExportCapability() && !isNull(kpi?.value) ? <span onClick={downloadMeasurement}>
            <IconDownload width={16} height={16} />
        </span> : null
        }
    </>;
};

DownloadButton.propTypes = {
    kpi: PropTypes.object
};

export default DownloadButton;
