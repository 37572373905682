import { createSelector } from 'reselect';


const getSelectedAssets = ({ notificationSettings }) => {
    return notificationSettings?.selectedAssets;
};

const getMutedAssetsLoadingStatus = ({ notificationSettings }) => {
    return notificationSettings?.mutedAssets?.loadingStatus;
};

const getMutedAssetList = ({ notificationSettings }) => {
    return notificationSettings?.mutedAssets?.data || [];
};

const getAssetMuteSettings = ({ notificationSettings }) => {
    return notificationSettings?.assetMuteSettings?.data;
};

const getAssetMuteSettingsLoadingStatus = ({ notificationSettings }) => {
    return notificationSettings?.assetMuteSettings?.loadingStatus;
};

const getUpdateAssetMuteSettingsLoadingStatus = ({ notificationSettings }) => {
    return notificationSettings?.updateAssetMuteSettingsLoadingStatus;
};

const getMutedAssets = createSelector([
    getMutedAssetsLoadingStatus,
    getMutedAssetList
], (
    mutedAssetsLoadingStatus,
    assetList
) => {
    return {
        mutedAssetsLoadingStatus,
        assetList
    };
});

export const getModel = createSelector(
    [
        getSelectedAssets,
        getMutedAssets,
        getAssetMuteSettings,
        getAssetMuteSettingsLoadingStatus,
        getUpdateAssetMuteSettingsLoadingStatus
    ],
    (
        selectedAssets,
        mutedAssets,
        assetMuteSettings,
        assetMuteSettingsLoadingStatus,
        updateAssetMuteSettingsLoadingStatus
    ) => {
        return {
            selectedAssets,
            mutedAssets,
            assetMuteSettings,
            assetMuteSettingsLoadingStatus,
            updateAssetMuteSettingsLoadingStatus
        };
    }
);
