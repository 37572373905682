import { actionTypes } from './constants';

const initialState = {
	measurementDownloadDialogParams: {
		measurementTypeIds: [],
	},
	measurementExport: {
		loadingStatus: undefined
	},
};


export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_STORE:
			return initialState;
		case actionTypes.SET_MEASUREMENT_DIALOG_PARAMS:
			return {
				...state,
				measurementExport: { ...initialState.measurementExport },
				measurementDownloadDialogParams: { ...action.measurementDownloadDialogParams }
			};
		case actionTypes.EXPORT_EXCEL_MEASUREMENT:
			return {
				...state,
				measurementExport: { ...state.measurementExport, ...action }
			};
		default:
			return state;
	}
};
