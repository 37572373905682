import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { TrendChart } from '../../CommonCharts/Trend';
import {
    getUserLimitsForChart
} from './helpers';
import './style.scss';


const TrendChartsComponent = (props) => {
    const { model, actions, multiChart } = props;

    const {
        availableKPIs,
        multiChartPreselection,
        multiChartData,
        userLimits,
        userLimitsLoadingStatus,
        dateSelection,
        loadingStatusForCharts
    } = model;
    const {
        loadMultiChartData
    } = actions;

    const debouncedLoadData = useCallback(
        debounce((kpis, selection, date, isZoom = false) => {
            loadMultiChartData(kpis, selection, date, isZoom);
        }, 300),
        []
    );

    useEffect(() => {
        if (availableKPIs && dateSelection) {
            debouncedLoadData(availableKPIs, multiChartPreselection, dateSelection);
        }
    }, [multiChartPreselection, dateSelection, availableKPIs]);

    const reloadAllSelectedTrendKpiData = () => {
        loadMultiChartData(availableKPIs, multiChartPreselection, dateSelection);
    };

    const zoomInMultiCharts = (availableTrendKPIs, selectedTrendKPIs, from, to) => {
        loadMultiChartData(availableTrendKPIs, multiChartPreselection, { from, to }, true);
    };

    const renderChart = (chart) => {
        const { data, zoom, chartID, loadingStatus } = chart;

        return <div key={chartID} id={chartID} className={`multi-graph-item ${chartID}`}>
            <TrendChart
                selectedTrendKPIs={map(data, (item) => item.kpiIdentifier)}
                selectedTrendsData={data}
                trendDataLoadingStatus={loadingStatus}
                userLimits={getUserLimitsForChart(
                    userLimits,
                    map(data, (item) => item.kpiIdentifier)
                )}
                enableUniqueScaling={false}
                lastMeasurementTimeStampLabel={null}
                isZoomed={zoom.isZoomed}
                availableTrendKPIs={availableKPIs}
                chartKey={`${chartID}`}
                reloadAllSelectedTrendKpiData={reloadAllSelectedTrendKpiData}
                zoomInToSelectedTrendKpiData={zoomInMultiCharts}
                setIsZoomed={() => { }}
                yAxisTitleVisible={false}
                legendVisible={true}
                multiChart={multiChart}
            />
        </div>;
    };
    return <LoadingStatusWrapper
        useCustomAnchor
        transparent
        indicatorConfig={{ placement: 'sticky' }}
        loadingStatus={[loadingStatusForCharts, userLimitsLoadingStatus]}
    >
        <div className='multi-graph-view ' >
            {map(multiChartData, (chart) => {
                return renderChart(chart);
            })}
        </div>
    </LoadingStatusWrapper >;
};

TrendChart.propTypes = {
    actions: PropTypes.shape({
        loadMultiChartData: PropTypes.func
    }),
    model: PropTypes.shape({
        availableKPIs: PropTypes.array,
        multiChartPreselection: PropTypes.array,
        multiChartData: PropTypes.array,
        userLimits: PropTypes.array,
        userLimitsLoadingStatus: PropTypes.string,
        dateSelection: PropTypes.object,
        loadingStatusForCharts: PropTypes.string
    })
};

export default TrendChartsComponent;
