import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';

const IconSortDecending = ({ width = 16, height = 16, viewBox = '0 0 12 15', color = colors.codGray, style }) => {
    return (
        <svg style={style} width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M4.66654 1.3335V12.1002L6.0332 10.7335L7.0332 11.7335L4.0332 14.7335L1.0332 11.7335L2.0332 10.7335L3.38654 12.0868V1.3335H4.66654ZM9.99987 6.66683V8.00016H6.66654V6.66683H9.99987ZM11.9999 4.00016V5.3335H6.66654V4.00016H11.9999ZM13.9999 1.3335V2.66683H6.66654V1.3335H13.9999Z' fill={color} />
        </svg>
    );
};

IconSortDecending.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    color: PropTypes.string,
    style: PropTypes.object
};

export default IconSortDecending;
