import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { apiCallStatus } from 'helpers/constants';
import { GeneralDialog, LoadingStatusWrapper } from 'sharedComponents';
import { getDefaultDateSelection } from '../../helpers';
import './styles.scss';

const DownloadMeasurementsComponent = (props) => {
    const { t: translate } = useTranslation();
    const { model, actions } = props;
    const {
        measurementTypeIds,
    } = model.measurementDownloadDialogParams;

    return <GeneralDialog
        show={props.show}
        close={props.close}
        title={translate('ABB.Powertrain.Frontend.exportMeasurementsConfirmationTitle')}
        dialogClassName='download-measurements-dialog'
        cancelButtonProps={{
            text: translate('ABB.Powertrain.Frontend.cancelLabel'),
            onClick: () => { }
        }}
        acceptButtonProps={{
            disabled: model.trendKpisLoadingStatus === apiCallStatus.LOADING || model.measurementExportLoadingStatus === apiCallStatus.LOADING,
            text: translate('ABB.Powertrain.Frontend.okLabel'),
            onClick: () => {
                actions.exportExcelForMeasurementTypeGroup(model.asset,
                    {
                        measurementTypes: measurementTypeIds,
                        from: model.dateSelection.from || getDefaultDateSelection().from,
                        to: model.dateSelection.to || getDefaultDateSelection().to,
                        clientTimeZoneOffset: new Date().getTimezoneOffset()
                    }).then(() => props.close());
            }
        }}
        closeButton={true}
    >
        <LoadingStatusWrapper loadingStatus={[model.measurementExportLoadingStatus, model.trendKpisLoadingStatus]}>
            <div className='export-measurement-description'>
                {translate('ABB.Powertrain.Frontend.exportMeasurementsConfirmationDescription')}
            </div>
        </LoadingStatusWrapper>
    </GeneralDialog>;
};

DownloadMeasurementsComponent.propTypes = {
    actions: PropTypes.shape({
        exportExcelForMeasurementTypeGroup: PropTypes.func
    }),
    close: PropTypes.func,
    model: PropTypes.shape({
        asset: PropTypes.any,
        dateSelection: PropTypes.shape({
            from: PropTypes.any,
            to: PropTypes.any
        }),
        measurementDownloadDialogParams: PropTypes.shape({
            measurementTypeIds: PropTypes.array
        }),
        measurementExportLoadingStatus: PropTypes.string,
        trendKpisLoadingStatus: PropTypes.string
    }),
    show: PropTypes.bool
};

export default DownloadMeasurementsComponent;
