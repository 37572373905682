export const actionTypes = {
    GET_NOTIFICATION_SETTINGS: 'notificationSettings/GET_NOTIFICATION_SETTINGS',
    SELECT_NOTIFICATION_GROUP: 'notificationSettings/SELECT_NOTIFICATION_GROUP',
    GET_MUTED_ASSETS: 'notificationSettings/GET_MUTED_ASSETS',
    SELECT_MUTED_ASSET: 'notificationSettings/SELECT_MUTED_ASSET',
    GET_ASSET_MUTE_SETTINGS: 'notificationSettings/GET_ASSET_MUTE_SETTINGS',
    SET_UPDATE_MUTE_SETTINGS_LOADING: 'notificationSettings/SET_UPDATE_MUTE_SETTINGS_LOADING',
    CLEAR_STORE: 'notificationSettings/CLEAR_STORE',

    LOAD_NOTIFICATION_SETTINGS_REQUEST: 'notificationSettings/LOAD_NOTIFICATION_SETTINGS_REQUEST',
    SAVE_NOTIFICATION_SETTINGS_REQUEST: 'notificationSettings/SAVE_NOTIFICATION_SETTINGS_REQUEST',
    LOAD_MUTED_ASSETS_REQUEST: 'notificationSettings/LOAD_MUTED_ASSETS_REQUEST',
    UPDATE_MUTED_ASSET_REQUEST: 'notificationSettings/UPDATE_MUTED_ASSET_REQUEST',
    DELETE_MUTED_ASSETS_REQUEST: 'notificationSettings/DELETE_MUTED_ASSETS_REQUEST',
    LOAD_ASSET_MUTE_SETTINGS_REQUEST: 'notificationSettings/LOAD_ASSET_MUTE_SETTINGS_REQUEST'
};

export const notificationSettingsTabs = {
    NOTIFICATION_CHANNELS: 'NOTIFICATION_CHANNELS',
    MUTED_ASSETS: 'MUTED_ASSETS'
};

export const digestOptions = {
    NONE: 'none',
    DAILY: 'dailyEnabled',
    WEEKLY: 'weeklyEnabled'
};
