import { formatText } from 'helpers/formatters';

const endpoints = {
    authentication: {
        loginWithToken: { url: '/Home/Login', version: null },
        refreshToken: { url: '/Home/Refresh', version: null },
    },
    profile: {
        getUserProfile: { url: '/user-profile/UserProfile', version: '1.0' },
        updateUserProfile: { url: '/user-profile/UserProfile', version: '1.0' },
        getAvailableLanguages: { url: '/user-profile/Languages', version: '1.0' }
    },
    log: {
        getIssueTypes: { url: '/AppLog/IssueTypes', version: null },
        reportIssue: { url: '/AppLog/SendBug', version: null },
    },
    subscription: {
        getSubscriptionList: { url: 'subscription/Subscriptions/Search', version: '1.0' }
    },
    system: {
        getSystems: { url: '/System/List?userName={userName}&isSuperAdmin={isSuperAdmin}&refreshData={refreshData}', version: null },
        getAssetList: { url: '/organization/Assets/Search', version: '1.0' }
    },
    powertrain: {
        getPowertrains: { url: '/organization/Powertrains/Search', version: '1.0' },
        getPowertrain: { url: '/organization/Powertrains/{id}', version: '1.0' },
        deletePowertrain: { url: '/organization/Powertrains/{id}', version: '1.0' },
        addPowertrain: { url: '/organization/Powertrains', version: '1.0' },
        updatePowertrain: { url: '/organization/Powertrains', version: '1.0' },
        addPowertrainAssets: { url: '/organization/Powertrains/{id}/Assets', version: '1.0' }
    },
    site: {
        getSites: { url: '/organization/Sites/Search', version: '1.0' },
        addSite: { url: '/Site', version: null },
    },
    environmentProperties: { url: '/Home/EnvironmentVariables', version: null },
    Plot: {
        MeasurementType: { url: '/Plot/MeasurementType?plotType={plotType}', version: null },
        List: { url: '/Plot/List?plotType={plotType}', version: null },
        Trend: { url: '/Plot/Trend/Data?from={from}&to={to}&isZoom={isZoom}', version: null, featureCode: 'WEB_AssetTrendData' },
        Scatter: { url: '/Plot/ScatterPlot/Data?from={from}&to={to}&isZoom={isZoom}', version: null, featureCode: 'WEB_AssetScatterGraph' },
        Histogram: { url: '/Plot/Histogram/Data?from={from}&to={to}&isZoom={isZoom}', version: null, featureCode: 'WEB_AssetHistogramGraph' },
    },
    timeSeries: {
        MeasurementType: { url: '/timeseries/timeseries/trend/config?assetId={assetId}', version: '1.0' },
        Trend: { url: '/timeseries/timeseries/trend/data?from={from}&to={to}', version: '1.0', featureCode: 'WEB_AssetTrendData' },
    },
    organizationManagement: {
        getUserOrganizations: { url: '/organization/Organizations/Search', version: '1.0' },
        getOrganizationByAsset: { url: '/organization/Organizations/SearchByAsset/{filter}', version: '1.0' }
    },
    eventLogs: {
        eventSearch: { url: '/eventservice/Events/Search', version: '1.0' },
        eventComment: { url: '/eventservice/EventComments', version: '1.0' },
        deleteComment: { url: '/eventservice/EventComments/{id}', version: '1.0' },
        eventComments: { url: '/eventservice/Events/{id}/Comments', version: '1.0' },
        loggerId: { url: '/eventservice/Events/{id}/Attachments', version: '1.0' },
        logger: {
            loggers: { url: '/datalogger/DataLogger/Logger/{loggerId}', version: '1.0' },
            downloadLogger: { url: '/datalogger/DataLogger/Download/{loggerId}', version: '1.0' },
            signal: { url: '/datalogger/DataLogger/Signals/{loggerId}', version: '1.0' },
        },
    },
    limitConfiguration: {
        getLimitConfigurations: { url: '/asset/timeseries/limits', version: '1.0' },
        getTimeseriesLimits: { url: '/timeseries/timeseries/limits?assetId={assetId}', version: '1.0' },
        postTimeseriesLimits: { url: '/timeseries/timeseries/limits', version: '1.0' },
        getLimitKpi: { url: '/timeseries/timeseries/trend/config?assetId={assetId}', version: '1.0' }
    },
    parameterFile: {
        getList: { url: '/ParameterFile/List?from={from}&to={to}', version: null },
        download: { url: '/ParameterFile/Download?id={id}', version: null },
        changedParameters: { url: '/ParameterFile/ChangedParameters?from={from}&to={to}', version: null },
    },
    emailSubscription: {
        getSubscription: { url: '/EmailSubscription/List', version: null },
        setSubscription: { url: '/EmailSubscription/Subscribe', version: null },
    },
    assetDetails: {
        getAssetGroups: { url: '/organization/AssetGroups/Search', version: '1.0' },
        getAssetInfo: { url: '/AssetDetail/Info/{assetID}', version: null },
        getAssetDetails: { url: '/asset/assets/{assetID}/detail', version: '1.0' },
        getAssetOverview: { url: '/asset/Assets/{assetID}/Overview', version: '1.0' },
        getSensorOfAssets: { url: '/asset/assets/GetSensorOfAsset', version: '1.0' },
        operationalParameters: {
            saveMeasurementCalibration: { url: '/AssetDetail/MeasurementCalibration/{assetID}', version: null }
        },
        sensorDetails: {
            setSensorFeatureValues: { url: '/AssetDetail/SensorFeature?sensorIdentifier={sensorIdentifier}&sensorTypeID={sensorTypeID}', version: null },
            getSensorFeatures: { url: '/AssetDetail/SensorFeature?firmwareID={firmwareID}&sensorIdentifier={sensorIdentifier}&sensorTypeID={sensorTypeID}', version: null },
            getSensorProperties: { url: '/AssetDetail/SensorProperty?sensorIdentifier={sensorIdentifier}&sensorTypeID={sensorTypeID}', version: null },
            getGatewayInfo: { url: '/AssetDetail/GatewayInfo/{assetID}', version: null },
        },
        fftData: {
            getFFTData: { url: '/TimeBasedAnalytics/FFTCalculation?assetID={assetID}&dataSource={dataSource}', version: null, featureCode: 'WEB_TimeBasedAnalytics' },
            downloadRawData: { url: '/TimeBasedAnalytics/CSVRawDataArchive?assetID={assetID}&assetName={assetName}&dataSource={dataSource}', version: null, featureCode: 'WEB_TimeBasedAnalytics' },
        },
        pictures: {
            getAssetNameplatePictures: { url: '/AssetDetail/Picture/{assetID}', version: null },
            getAssetNameplatePicturesV2: { url: '/asset/assets/{assetID}/pictures', version: '1.0' },
            generateUploadURL: { url: '/asset/assets/{assetID}/picture/uploadUrl', version: '1.0' },
            uploadAssetPicture: { url: '/asset/assets/{assetID}/pictures', version: '1.0' },
            deleteAssetPicture: { url: '/asset/Assets/{assetID}/Pictures/{pictureId}', version: '1.0' }
        },
        labels: {
            getLabels: { url: '/AssetDetail/Label/{assetID}', version: null, featureCode: 'WEB_AssetLabel' },
            getSuggestedLabels: { url: '/AssetDetail/Label?organizationID={organizationID}', version: null, featureCode: 'WEB_AssetLabel' },
            addLabelToAsset: { url: '/Component/Label/{id}?labelValue={labelValue}&dataSource={dataSource}', version: null, featureCode: 'WEB_AssetLabel' },
            removeLabelFromAsset: { url: 'AssetDetail/Label/{assetID}?labelID={labelID}', version: null, featureCode: 'WEB_AssetLabel' },
        },
        tags: {
            getTags: { url: '/organization/Assets/{assetID}/Tags', version: '1.0' },
            addTags: { url: '/organization/Assets/{assetID}/Tags', version: '1.0' },
            deleteTags: { url: '/organization/AssetTag/{tagID}', version: '1.0' },
            getSuggestedTags: { url: '/organization/Organizations/{organizationID}/Tags', version: '1.0' }
        }

    },
    component: {
        updateComponent: { url: '/Component', version: null },
        capability: { url: '/Component/Capability/{id}?dataSource={dataSource}', version: null },
        details: { url: '/Component/Details/{id}?dataSource={dataSource}', version: null },
        capabilities: { url: '/Component/Capability', version: null },
        healthStatus: {
            get: { url: '/Component/HealthStatus/{id}?dataSource={dataSource}', version: null },
            save: { url: '/Component/HealthStatus/{id}?dataSource={dataSource}', version: null },
            calculate: { url: '/Component/HealthStatus/Calculate/{id}?dataSource={dataSource}&measurementTypeID={measurementTypeID}&from={from}&to={to}', version: null },
            default: { url: '/Component/HealthStatus/Default?dataSource={dataSource}&measurementTypeID={measurementTypeID}&assetTypeID={assetTypeID}', version: null },
        },
        bulkUpload: {
            processBulkUpload: { url: '/Component/BulkUpload/Start', version: null },
            getBulkUploadStatus: { url: '/Component/BulkUpload/Status', version: null },
            initializeBulkUpload: { url: '/Component/BulkUpload/Initialize', version: null },
        },
        exportExcelForMeasurementTypeGroup: {
            url: '/timeseries/timeseries/export?assetId={assetId}&from={from}&to={to}&locale=en-us',
            version: '1.0',
        },
        components: { url: '/Component/List?isSuperAdmin={isSuperAdmin}&refreshData={refreshData}', version: null },
        loadMeasurement: {
            url: '/Component/CollectTrendDataOnDemand?sensorIdentifier={sensorIdentifier}&sensorTypeID={sensorTypeID}&dataSource={dataSource}',
            version: null,
            featureCode: 'WEB_LoadMeasurementOnDemand'
        },
        collectRawData: {
            url: '/Component/CollectRawData?sensorIdentifier={sensorIdentifier}&sensorTypeID={sensorTypeID}&dataSource={dataSource}',
            version: null,
            featureCode: 'WEB_LoadRawData'
        }

    },
    userLimits: {
        getUserLimits: { url: 'Component/UserLimits', version: null },
        saveUserLimits: { url: 'Component/UserLimits', version: null },
    },
    userProfile: {
        update: { url: '/UserProfile', version: null },
        acceptEULA: { url: '/UserProfile/AcceptEULA', version: null }
    },
    maintenance: {
        getAssets: { url: '/Analytics/CBM/Component/Summary', version: null },
        getPowertrains: { url: '/Analytics/CBM/System/Summary?userName={userName}&isSuperAdmin={isSuperAdmin}', version: null },
        getDetails: { url: '/Analytics/CBM/Component/Details', version: null },
    },
    report: {
        getReports: { url: '/reporting/Report/List?isAdmin={isAdmin}', version: '1.1' },
        downloadReport: { url: '/reporting/Report/Download?reportId={reportId}', version: '1.0' },
        generateReport: { url: '/reporting/Report/RequestReport', version: '1.1' },
        uploadReport: { url: '/reporting/Report/Upload', version: '1.1' },
        publishReport: { url: '/reporting/Report/Publish/{reportId}', version: '1.0' },
        rejectReport: { url: '/reporting/Report/Reject/{reportId}', version: '1.0' },
        generateFleetReport: { url: '/reporting/Report/RequestFleetReport', version: '1.1' },
        deleteReport: { url: '/reporting/Report/Delete/{reportId}', version: '1.0' },
    },
    capability: {
        getCapabilities: { url: '/subscription/Capabilities/Search', version: '1.0' },
        checkCapabilities: { url: '/subscription/Capabilities/Check', version: '1.0' },
    },
    notification: {
        deleteUserBroadcastMessage: { url: '/NotificationConfiguration/Broadcast?broadcastMessageID={broadcastMessageID}&userID={userID}', version: null },
        getBroadcastedMessages: { url: '/NotificationConfiguration/Broadcast', version: null },
    },
    dailyAgingStress: {
        getDailyAgingStress: { url: '/Analytics/CBM/Component/DailyAgingStress', version: null }
    },
    conditionIndices: {
        getAssetOverview: { url: '/analytics/analytics/condition/asset', version: '1.0' },
        getAssetConditionInsights: { url: '/analytics/analytics/condition/asset/{assetID}?startTime={startTime}&endTime={endTime}', version: '1.0' },
        getAssetGroupOverview: { url: '/analytics/analytics/condition/assetgroup', version: '1.0' },
        getPowertrainOverview: { url: '/analytics/analytics/condition/powertrain', version: '1.0' }
    },
    assetRename: {
        updateAssetList: { url: '/asset/assets/{assetId}/name', version: '1.0' }
    },
    notifications: {
        getNotificationSettings: { url: '/notification/Notification/Settings?organizationId={organizationId}', version: '1.0' },
        saveNotificationSettings: { url: '/notification/Notification/Settings?organizationId={organizationId}', version: '1.0' },
        getMutedAssetList: { url: '/notification/MutedAssets/Search', version: '1.0' },
        deleteMutedAssets: { url: '/notification/MutedAssets', version: '1.0' },
        getAssetMuteSettings: { url: '/notification/MutedAssets/{assetID}', version: '1.0' },
        updateAssetMuteSettings: { url: '/notification/MutedAssets', version: '1.0' }
    }
};

/**
 * Formats endpoint objects url to include query parameter.
 * @param {{url:string, version?: string }} endpoint
 * @param {object} placeholders
 * @returns {{url:string, version?: string }}
 */
export const formatEndpoint = (endpoint, placeholders) => {
    return {
        ...endpoint,
        url: formatText(endpoint.url, placeholders)
    };
};

export default endpoints;
