import EventLogs from 'api/EventLogsApi/eventLogs';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { orderBy } from 'lodash';
import { getApiActionCreator } from 'helpers/actionHelper';
import * as actionTypes from './constants';


const postCommentAction = getApiActionCreator(actionTypes.POST_EVENT_COMMENT);
const getCommentAction = getApiActionCreator(actionTypes.GET_COMMENTS);
const deleteCommentAction = getApiActionCreator(actionTypes.DELETE_COMMENT);

export const getComments = (event) => {
	return dispatch => {
		dispatch(getCommentAction(apiCallStatus.LOADING, []));
		EventLogs.getEventComments(event.eventId).then((comments) => {
			let sortedComments = comments || [];
			sortedComments = orderBy(sortedComments, report => new Date(report.commentTimeStamp), 'desc');
			dispatch(getCommentAction(apiCallStatus.SUCCESS, sortedComments));
		}).catch((err) => {
			if (err) {
				dispatch(getCommentAction(apiCallStatus.ERROR, null, err));
				logger.error(err.message);
			}
		});
	};
};

export const postComment = (event, comment, authorEmail, causeCode, isInitialCause) => {
	return dispatch => {
		dispatch(postCommentAction(apiCallStatus.LOADING));
		EventLogs.postEventComment({
			eventIds: [event.eventId],
			comment,
			authorEmail,
			causeCode,
			isInitialCause
		}).then(() => {
			dispatch(postCommentAction(apiCallStatus.SUCCESS));
			dispatch(getComments(event));
		}).catch((err) => {
			if (err) {
				dispatch(postCommentAction(apiCallStatus.ERROR, null, err));
				logger.error(err.message);
			}
		});
	};
};

export const deleteComment = (event, comment) => {
	return dispatch => {
		dispatch(deleteCommentAction(apiCallStatus.LOADING));
		EventLogs.deleteComment(comment.id).then(() => {
			dispatch(deleteCommentAction(apiCallStatus.SUCCESS));
			dispatch(getComments(event));
		}).catch((err) => {
			if (err) {
				dispatch(deleteCommentAction(apiCallStatus.ERROR, null, err));
				logger.error(err.message);
			}
		});
	};
};
