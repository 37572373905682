import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'sharedComponents/Checkbox';
import { OrganizationSelector } from './OrganizationSelector';
import { AssetGroupSelector } from './AssetGroupSelector';
import { SiteSelector } from './SiteSelector';
import { CountrySelector } from './CountrySelector';
import LoadingStatusWrapper from 'sharedComponents/WithLoadingStatus/LoadingStatusWrapper';

const GeneralFilterEditComponent = (props) => {
    const { t: translate } = useTranslation();

    const { selectedFilters,
        selectedOrganization,
        availableAssetGroups,
        organizations,
        availableCountries,
        availableSites } = props.model;

    const {
        countries: selectedCountries,
        sites: selectedSites,
        assetGroup: selectedAssetGroup,
        sitesChecked,
        assetGroupChecked
    } = selectedFilters;


    const {
        organizationsLoadingStatus,
        sitesLoadingStatus,
        assetGroupsLoadingStatus
    } = props.model.loadingData;

    const {
        setSelectedFilters
    } = props.actions;

    const onChangeOrganization = (newOrganization) => {
        const organizations = [newOrganization];

        setSelectedFilters(
            {
                ...selectedFilters,
                assetGroupChecked: false,
                sitesChecked: false,
                organizations,
                countries: [],
                sites: [],
                assetGroup: null
            }
        );
    };

    const onChangeCountry = (countries) => {
        setSelectedFilters(
            {
                ...selectedFilters,
                countries,
                sites: [],
            }
        );
    };

    const onChangeSite = (sites) => {
        setSelectedFilters(
            {
                ...selectedFilters,
                sites
            }
        );
    };

    const onChangeAssetGroup = (assetGroup) => {
        setSelectedFilters(
            {
                ...selectedFilters,
                assetGroup
            }
        );
    };

    useEffect(() => {
        assetGroupChecked && !selectedAssetGroup && availableAssetGroups?.length
            && onChangeAssetGroup(availableAssetGroups[0]);
    }, [assetGroupChecked, availableAssetGroups]);

    return <Container className='filter-edit-container'>
        <LoadingStatusWrapper
            loadingStatus={[organizationsLoadingStatus, sitesLoadingStatus, assetGroupsLoadingStatus]}
            transparent
            useCustomAnchor>
            <form role='form'>
                <Row className='main-section-row g-0'>
                    <Col md={12} className='sub-section-row'>
                        <Col xs={1} md={1} className='px-3'>
                        </Col>
                        <Col md={11} className='px-3'>
                            <div className='filter-form-group'>
                                <label htmlFor='organizations-select'
                                    className='filter-form-label'>
                                    {translate('ABB.Powertrain.Frontend.generalFiltersOrganizationsLabel')}
                                </label>
                                <OrganizationSelector
                                    onChange={onChangeOrganization}
                                    options={organizations}
                                    selectedOrganization={selectedOrganization}
                                    inputId='organizations-select'
                                    name='organizations-select'
                                />
                            </div>
                        </Col>
                    </Col>
                </Row>

                <Row className='main-section-row g-0'>
                    <Col md={12} className='sub-section-row'>
                        <Col xs={1} md={1} className='px-3'>
                            <Checkbox
                                id={'countries_filter_checkbox'}
                                checked={sitesChecked}
                                onToggle={() => props.actions.setSelectedFilters({ ...selectedFilters, countries: [], sites: [], sitesChecked: !sitesChecked })}
                                width={'16px'}
                                height={'16px'}
                            />
                        </Col>
                        <Col md={11} className='px-3'>
                            <div className='filter-form-group'>
                                <label htmlFor='countries-select'
                                    className='filter-form-label'>
                                    {translate('ABB.Powertrain.Frontend.generalFiltersCountriesLabel')}
                                </label>
                                <CountrySelector
                                    sitesChecked={sitesChecked}
                                    onChange={onChangeCountry}
                                    options={availableCountries}
                                    value={selectedCountries}
                                    inputId='countries-select'
                                    name='countries-select'
                                />
                            </div>
                        </Col>
                    </Col>
                    <Col md={12} className='sub-section-row'>
                        <Col xs={1} md={1}>
                        </Col>
                        <Col md={11} className='px-3'>
                            <div className='filter-form-group'>
                                <label htmlFor='sites-select'
                                    className='filter-form-label'>
                                    {translate('ABB.Powertrain.Frontend.generalFiltersSitesLabel')}
                                </label>
                                <SiteSelector
                                    sitesChecked={sitesChecked}
                                    options={availableSites}
                                    value={selectedSites}
                                    onChange={onChangeSite}
                                    inputId='sites-select'
                                    name='sites-select'
                                />
                            </div>
                        </Col>
                    </Col>
                </Row >
                <Row className='main-section-row g-0'>
                    <Col md={12} className='sub-section-row'>
                        <Col xs={1} md={1} className='px-3'>
                            <Checkbox
                                id={'assetgroup_filter_checkbox'}
                                checked={assetGroupChecked}
                                onToggle={() => setSelectedFilters({ ...selectedFilters, assetGroup: null, assetGroupChecked: !assetGroupChecked })}
                                width={'16px'}
                                height={'16px'}
                            />
                        </Col>
                        <Col md={11} className='px-3'>
                            <div className='filter-form-group'>
                                <label htmlFor='asset-group-select'
                                    className='filter-form-label'>
                                    {translate('ABB.Powertrain.Frontend.generalFiltersAssetGroupLabel')}
                                </label>
                                <AssetGroupSelector
                                    assetGroupChecked={assetGroupChecked}
                                    onChange={onChangeAssetGroup}
                                    options={availableAssetGroups}
                                    value={assetGroupChecked ? selectedAssetGroup || availableAssetGroups[0] : selectedAssetGroup}
                                    inputId='asset-group-select'
                                    name='asset-group-select'
                                />
                            </div>
                        </Col>
                    </Col>
                </Row>
            </form >
        </LoadingStatusWrapper >
    </Container >;
};

GeneralFilterEditComponent.propTypes = {
    actions: PropTypes.shape({
        setSelectedFilters: PropTypes.func
    }),
    model: PropTypes.shape({
        selectedSSOrganization: PropTypes.object,
        selectedCMDOrganization: PropTypes.object,
        ssOrganizations: PropTypes.object,
        cmdOrganizations: PropTypes.object,
        availableAssetGroups: PropTypes.array,
        availableCountries: PropTypes.array,
        availableSites: PropTypes.array,
        loadingData: PropTypes.shape({
            organizationsLoadingStatus: PropTypes.string,
            sitesLoadingStatus: PropTypes.string,
            assetGroupsLoadingStatus: PropTypes.string
        }),
        organizations: PropTypes.array,
        selectedFilters: PropTypes.shape({
            assetGroup: PropTypes.object,
            assetGroupChecked: PropTypes.bool,
            countries: PropTypes.array,
            organizations: PropTypes.array,
            sites: PropTypes.array,
            sitesChecked: PropTypes.bool
        })
    }),
};

export default GeneralFilterEditComponent;
