import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    LoadingStatusWrapper,
    GeneralTable,
    GeneralButton,
    GeneralDialog
} from 'sharedComponents';
import { apiCallStatus, popupTypes } from 'helpers/constants';
import { IconBellStrikeThrough } from 'svgIcons/MotionPortalIcons';
import { EditMutedAsset } from './EditMuteSettingsPopup';
import {
    getColumns,
    formatData
} from './helpers';
import './style.scss';
import { isEqual } from 'lodash';


const MutedAssetsComponent = (props) => {
    const { t: translate } = useTranslation();

    const { mutedAssets,
        selectedAssets,
        assetMuteSettings,
        assetMuteSettingsLoadingStatus,
        updateAssetMuteSettingsLoadingStatus } = props.model;
    const { selectMutedAsset, saveMutedAssetSettings, getAssetMutedSettings } = props.actions;

    const [selectedSingleAsset, setSelectedSingleAsset] = useState(null);
    const [editPopupOpen, setEditPopupOpen] = useState(false);
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);

    const [disabledForPeriod, setDisabledForPeriod] = useState(null);
    const [period, setPeriod] = useState(null);
    const [mutedGroups, setMutedGroups] = useState(null);
    const timePeriod = assetMuteSettings?.startDate && assetMuteSettings?.endDate ?
        {
            from: assetMuteSettings.startDate,
            to: assetMuteSettings.endDate
        } : null;
    const muteForTimePeriod = !!(assetMuteSettings?.startDate && assetMuteSettings?.endDate);

    const setSelectedAssetOptions = () => {
        setDisabledForPeriod(muteForTimePeriod);
        setPeriod(timePeriod);
        if (selectedSingleAsset?.assetId) {
            getAssetMutedSettings(selectedSingleAsset?.assetId);
        }
    };

    useEffect(() => {
        setSelectedAssetOptions();
    }, [selectedSingleAsset]);

    useEffect(() => {
        setMutedGroups(assetMuteSettings ? assetMuteSettings?.notificationGroups || [] : []);
    }, [assetMuteSettings]);

    const toggleEditPopupVisibility = () => {
        if (editPopupOpen) {
            setSelectedSingleAsset(null);
        }
        setEditPopupOpen(!editPopupOpen);
    };

    const toggleDeletePopupVisibility = () => {
        if (deletePopupOpen) {
            setSelectedSingleAsset(null);
        }
        setDeletePopupOpen(!deletePopupOpen);
    };

    const save = () => {
        saveMutedAssetSettings(
            props.organization?.organizationID,
            mutedGroups,
            period,
            selectedSingleAsset.assetId,
            toggleEditPopupVisibility
        );
    };

    const renderEditPopup = () => {
        return <GeneralDialog
            show={editPopupOpen}
            close={toggleEditPopupVisibility}
            title={<>
                <IconBellStrikeThrough />{`${translate('ABB.Powertrain.Frontend.mutedAssetsEditPopupTitle')} - ${selectedSingleAsset?.assetName}`}
            </>}
            closeButton={true}
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.saveButton'),
                onClick: () => { save(); },
                disabled: assetMuteSettingsLoadingStatus === apiCallStatus.LOADING || updateAssetMuteSettingsLoadingStatus === apiCallStatus.LOADING || isEqual(mutedGroups, assetMuteSettings?.notificationGroups) && isEqual(disabledForPeriod, muteForTimePeriod) && isEqual(period, timePeriod)
            }}
            cancelButtonProps={{
                text: translate('ABB.Powertrain.Frontend.cancelLabel'),
                onClick: () => { toggleEditPopupVisibility(); }
            }}
            modalClassName={'edit-muted-asset-popup'}
        >
            <LoadingStatusWrapper loadingStatus={[
                assetMuteSettingsLoadingStatus,
                updateAssetMuteSettingsLoadingStatus
            ]}>
                <EditMutedAsset
                    notificationGroups={props.notificationSettings?.notificationChannels?.notificationGroups}
                    disabledForPeriod={disabledForPeriod}
                    setDisabledForPeriod={setDisabledForPeriod}
                    period={period}
                    setPeriod={setPeriod}
                    mutedGroups={mutedGroups}
                    setMutedGroups={setMutedGroups}
                />
            </LoadingStatusWrapper>
        </GeneralDialog>;
    };

    const renderDeletePopup = () => {
        return <GeneralDialog
            show={deletePopupOpen}
            close={toggleDeletePopupVisibility}
            notificationType={popupTypes.WARNING}
            title={translate('ABB.Powertrain.Frontend.mutedAssetsDeletePopupTitle')}
            closeButton={true}
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.yesLabel'),
                onClick: () => {
                    props.deleteMutedAssets(props.organization?.organizationID,
                        selectedSingleAsset ? [selectedSingleAsset.assetId] : selectedAssets);
                    toggleDeletePopupVisibility();
                }
            }}
            cancelButtonProps={{
                text: translate('ABB.Powertrain.Frontend.noLabel'),
                onClick: () => { toggleDeletePopupVisibility(); }
            }}
            modalClassName={'edit-muted-asset-popup'}
        >
            <div>{translate('ABB.Powertrain.Frontend.mutedAssetsDeletePopupDescription')}</div>
        </GeneralDialog>;
    };

    return <LoadingStatusWrapper
        loadingStatus={[
            mutedAssets.mutedAssetsLoadingStatus,
            props.notificationSettings.notificationSettingsLoadingStatus
        ]}
    >
        <div className='muted-assets-container'>
            {renderEditPopup()}
            {renderDeletePopup()}
            <div className='muted-assets-description'>{translate('ABB.Powertrain.Frontend.mutedAssetsDescription')}</div>
            <div className='muted-assets-bulk-operations'>
                {selectedAssets?.length > 0 && <>
                    <div className='assets-selected'>
                        {`${selectedAssets?.length} / ${mutedAssets?.assetList?.length} ${translate('ABB.Powertrain.Frontend.mutedAssetsAssetsSelected')}`}
                    </div>
                    <GeneralButton
                        type='primary'
                        text={translate('ABB.Powertrain.Frontend.mutedAssetsUnmuteButton')}
                        onClick={() => {
                            toggleDeletePopupVisibility();
                        }}
                    />
                </>}
            </div>
            <GeneralTable
                className='muted-assets-table-container'
                columns={getColumns(
                    selectMutedAsset,
                    toggleEditPopupVisibility,
                    toggleDeletePopupVisibility,
                    setSelectedSingleAsset,
                    selectedAssets,
                    mutedAssets?.assetList.length,
                    props.notificationSettings?.notificationChannels?.notificationGroups?.length
                )}
                data={formatData(mutedAssets)}
                emptyText={translate('ABB.Powertrain.Frontend.noMutedAssets')}
                type={'primary'}
                tableKey='id'
            />
        </div>
    </LoadingStatusWrapper>;
};

MutedAssetsComponent.propTypes = {
    model: PropTypes.shape({
        mutedAssets: PropTypes.object,
        selectedAssets: PropTypes.array
    }),
    actions: PropTypes.shape({
        selectMutedAsset: PropTypes.func,
        saveMutedAssetSettings: PropTypes.func
    }),
    deleteMutedAssets: PropTypes.func,
    organization: PropTypes.object,
    notificationSettings: PropTypes.object
};

export default MutedAssetsComponent;
