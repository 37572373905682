import { createSelector } from 'reselect';
import { find } from 'lodash';


const getActiveTab = ({ eventDetailsCMD }) => {
	return eventDetailsCMD.activeTab;
};

const getSelectedEvent = ({ eventLog }) => {
	return eventLog.selectedEvent;
};
const getDateKind = ({ eventLog }) => {
	return eventLog.dateKind;
};

const getSelectedAsset = ({ overview, eventLog }) => {
	return find(overview?.assets?.data, (asset) => {
		return asset?.id === eventLog?.selectedEvent?.rawEvent?.assetId;
	});
};

const getLoggerId = ({ eventDetailsCMD }) => {
	return eventDetailsCMD.loggerId?.data;
};

export const getModel = createSelector([
	getActiveTab,
	getSelectedEvent,
	getDateKind,
	getSelectedAsset,
	getLoggerId
],
	(
		activeTab,
		selectedEvent,
		dateKind,
		selectedAsset,
		loggerId
	) => {
		return {
			activeTab,
			selectedEvent,
			dateKind,
			selectedAsset,
			loggerId
		};
	});
