import { createSelector } from 'reselect';


const getNameplatePictures = ({ assetDetailsInfoHeader }) => {
	return {
		data: assetDetailsInfoHeader.nameplatePictures.data,
		loadingStatus: assetDetailsInfoHeader.nameplatePictures.loadingStatus
	};
};

const getAssetNameplateImageUploadInfo = ({ assetNameplateImage }) => {
	return {
		data: assetNameplateImage.assetPictureUpload.data,
		loadingStatus: assetNameplateImage.assetPictureUpload.loadingStatus
	};
};

const setShowDeleteConfirm = ({ assetNameplateImage }) => {
	return assetNameplateImage.showDeleteConfirm;
};

export const getModel = createSelector([
	getNameplatePictures,
	getAssetNameplateImageUploadInfo,
	setShowDeleteConfirm
], (
	pictures,
	assetNameplateImage,
	showDeleteConfirm
) => {
	return {
		pictures,
		assetNameplateImage,
		showDeleteConfirm
	};
});
