import { actionOptions } from './constants';


export const getActionOptions = (translate) => {
    return [
        {
            id: actionOptions.AddComment,
            title: translate(`ABB.Powertrain.Frontend.eventDetailsAction${actionOptions.AddComment}`),
        },
        {
            id: actionOptions.CloseEvent,
            title: translate(`ABB.Powertrain.Frontend.eventDetailsAction${actionOptions.CloseEvent}`),
        }
    ];
};
