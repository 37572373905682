import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import React from 'react';
import { getKPIListForAsset, isKpiSelected, isKpiSelectedTrend, getSelectedParameterSignals } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import { removeSelectedKPI } from '../../ChartView/actions';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import CapabilityHelper from 'helpers/capabilityHelper';
import { contractInfoMessages, apiCallStatus } from 'helpers/constants';
import { ParameterSignalSelector } from './ParameterSignalSelector';
import { Checkbox } from 'sharedComponents/Checkbox';

const KPIListComponent = ({ selectedAsset, selectedAssetCapability, chartData, setKpi, selectedKPIType }) => {
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();
    const { kpis, loadingStatus } = useSelector(
        ({ crossAssetAnalysisSidepanel }) => getKPIListForAsset(crossAssetAnalysisSidepanel.kpiList, translate)
    );
    const capabilityHelper = new CapabilityHelper(selectedAssetCapability, selectedAsset);
    const hasActiveSubscription = capabilityHelper.subscriptionStatus() === contractInfoMessages.activeSubscription;
    const selectedParameterSignals = getSelectedParameterSignals(chartData);
    const toggleMeasurement = (kpi, isSelected) => {
        if (isSelected) {
            dispatch(removeSelectedKPI(kpi, selectedAsset, selectedKPIType));
        }
        else {
            dispatch(setKpi({ ...kpi, isChecked: true }, selectedAsset));
        }
    };
    return (
        <LoadingStatusWrapper
            className='loading-indicator-content'
            loadingStatus={[loadingStatus, selectedAssetCapability.loadingStatus]}
            indicatorConfig={{ size: 'small', style: { 'padding': '8px 0px' } }}>
            <div className='kpi-list'>
                {
                    selectedAsset?.isUserAccessible ?
                        <>
                            {
                                loadingStatus === apiCallStatus.SUCCESS && !kpis?.standardKPIList?.length &&
                                !kpis?.parameterKPIList?.length &&
                                hasActiveSubscription &&
                                <div className='kpi-list-empty'>{translate('ABB.Powertrain.Frontend.noSupportedMeasurementTypes')}</div>
                            }
                            {
                                hasActiveSubscription ?
                                    map(kpis?.standardKPIList, (kpi, index) => {
                                        const isSelected = selectedKPIType === 1 ? isKpiSelectedTrend(chartData, kpi, selectedAsset.componentID) : isKpiSelected(chartData, kpi);
                                        return <>
                                            <div className={isSelected ? 'kpi-list-item selected' : 'kpi-list-item'}
                                                onClick={() => { kpi?.isPlottableToTrendChart && toggleMeasurement(kpi, isSelected); }}
                                                key={index} >
                                                <div><Checkbox checked={isSelected} disabled={!kpi?.isPlottableToTrendChart}
                                                /></div>
                                                <div className='kpi-name'>
                                                    {kpi.displayName}
                                                </div>
                                            </div>
                                            {/* eslint-disable-next-line semi */}
                                        </>
                                    })
                                    :
                                    <div className='kpi-list-empty'>{translate('ABB.Powertrain.Frontend.noSubscriptionOrContract')}
                                    </div>
                            }
                            {
                                kpis?.parameterKPIList.length && hasActiveSubscription ?
                                    <div className='kpi-parameter-signals'>
                                        <ParameterSignalSelector
                                            options={kpis?.parameterKPIList}
                                            selectedKPIType={selectedKPIType}
                                            setKpi={setKpi}
                                            removeSelectedKPI={removeSelectedKPI}
                                            chartData={chartData}
                                            selectedAsset={selectedAsset}
                                            placeholder={translate('ABB.Powertrain.Frontend.additionaltrends')}
                                            value={selectedParameterSignals}

                                        />
                                    </div> : null
                            }


                        </>
                        :
                        <div className='kpi-list-empty'>{translate('ABB.Powertrain.Frontend.detailedInformationPermissionErrorTitle')}</div>
                }
            </div>
        </LoadingStatusWrapper >
    );
};

KPIListComponent.propTypes = {
    selectedAsset: PropTypes.any,
    kpis: PropTypes.any,
    chartData: PropTypes.array,
    selectedAssetCapability: PropTypes.object,
    setKpi: PropTypes.func,
    selectedKPIType: PropTypes.number,
};

export default KPIListComponent;
