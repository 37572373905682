import { actionTypes } from './constants';
import { apiCallStatus } from 'helpers/constants';

const initialState = {
    subscriptionArr: {
        data: {
            assetSubscriptionsInfos: []
        },
        error: null,
        loadingStatus: apiCallStatus.LOADING
    },
    assetOverview: null
};
export default function(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ACTIVE_SUB_DATA: {
            return {
                ...state,
                subscriptionArr: action
            };
        }
        case actionTypes.GET_ASSET_OVERVIEW: {
            return {
                ...state,
                assetOverview: action.data
            };
        }
        default:
            return state;
    }
}
