import { createSelector } from 'reselect';
import {
    getAssetsLoadingStatus, getPowertrainLoadingStatus
} from 'helpers/selectors';


const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getAssetCapabilityLoadingStatus = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAssetCapability?.loadingStatus;
};

const getNewCapabilityLoadingStatus = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.newAssetCapability?.loadingStatus;
};

const getGeneralFilterLoadingStatuses = ({ generalFilter }) => {
    return {
        organizationsLoadingStatus: generalFilter.organizations.loadingStatus,
        sitesLoadingStatus: generalFilter.sites.loadingStatus,
        assetGroupsLoadingStatus: generalFilter.assetGroups.loadingStatus,
        contextLoadingStatus: generalFilter.contextLoadingStatus
    };
};

const getSitesCheckedFilters = ({ generalFilter }) => {
    return generalFilter.selectedFilters.sitesChecked;
};

export const getModel = createSelector([
    getSelectedAsset,
    getAssetCapabilityLoadingStatus,
    getNewCapabilityLoadingStatus,
    getGeneralFilterLoadingStatuses,
    getSitesCheckedFilters,
    getAssetsLoadingStatus,
    getPowertrainLoadingStatus
],
    (
        asset,
        capabilityLoadingStatus,
        newCapabilityLoadingStatus,
        generalFilterLoadingStatuses,
        sitesChecked,
        assetsLoadingStatus,
        powertrainsLoadingStatus
    ) => {
        return {
            asset,
            capabilityLoadingStatus,
            newCapabilityLoadingStatus,
            generalFilterLoadingStatuses,
            sitesChecked,
            assetsLoadingStatus,
            powertrainsLoadingStatus
        };
    });
