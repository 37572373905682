import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GeneralButton } from 'sharedComponents/GeneralButton';
import { GeneralSelect } from 'sharedComponents/GeneralSelect';
import { TextArea } from 'sharedComponents/TextArea';
import { getClosingReasons } from '../../helpers';
import { maxCommentSize } from './constants';
import { getActionOptions } from './helpers';


const EventDetailsSSCommentActions = ({
    model,
    actions
}) => {
    const { t: translate } = useTranslation();
    const event = model.selectedEvent.rawEvent;
    const actionOptions = getActionOptions(translate);

    const [selectedCause, setSelectedCause] = useState(null);
    const [comment, setComment] = useState('');
    const [selectedAction, setSelectedAction] = useState(actionOptions[0]);

    const handleClick = () => {
        if (comment.length > 0) {
            if (selectedAction.id === 1) {
                actions.postComment(event, comment, model?.user?.email);
                setComment('');
            }
            else {
                actions.closeEventLog(event, comment, model?.user?.email, selectedCause);
            }
        }
    };

    const setActionButton = () => {
        return selectedAction.id === 1 && comment.length > 0 || comment.length > 0 && selectedAction.id === 2 && selectedCause;
    };

    const setActionButtonText = () => {
        return selectedAction.id === actionOptions[1].id ? translate('ABB.Powertrain.Frontend.closeBtnLbl') : translate('ABB.Powertrain.Frontend.addButton');
    };

    return (
        <Col className='event-details-comments-actions' xl={5} xs={12}>
            <span className='close-or-comment-label'>
                {translate('ABB.Powertrain.Frontend.eventDetailsCloseOrCommentLbl')}
            </span>
            <div className='selects-container'>
                <div className='select-action'>
                    <span className='action-label'>
                        {translate('ABB.Powertrain.Frontend.eventDetailsActionLbl')}
                    </span>
                    <GeneralSelect
                        className='action-dropdown'
                        options={actionOptions}
                        defaultValue={selectedAction}
                        onChange={(e) => {
                            setSelectedAction(e);
                            e.id === actionOptions[0].id && setSelectedCause(null);
                        }}
                        getOptionLabel={(option) => option.title}
                        getOptionValue={(option) => option.id}
                        menuShouldScrollIntoView={false}
                        isSearchable={false}
                    />
                </div>
                <div className='select-cause'>
                    <span className='cause-label'>
                        {translate('ABB.Powertrain.Frontend.eventDetailsCauseLbl')}
                    </span>
                    <GeneralSelect
                        className='cause-dropdown'
                        options={getClosingReasons(translate)}
                        defaultValue={selectedCause}
                        onChange={(e) => setSelectedCause(e)}
                        isDisabled={selectedAction.id === actionOptions[0].id}
                        getOptionLabel={(option) => option.title}
                        getOptionValue={(option) => option.id}
                        menuShouldScrollIntoView={false}
                        isSearchable={false}
                    />
                </div>
            </div>

            <div className='action-comment'>
                <span className='comment-label'>
                    {translate('ABB.Powertrain.Frontend.eventDetailsCommentLbl')}{' '}
                </span>
                <span className='comment-length'>{`${comment.length} / ${maxCommentSize}`}</span>
                <TextArea
                    className='action-textarea'
                    resizable={false}
                    maxLength={maxCommentSize}
                    onChange={(e) => {
                        setComment(e);
                    }}
                    value={comment}
                    placeholder={translate(
                        'ABB.Powertrain.Frontend.eventDetailsCommentPlaceholderText'
                    )}
                />
            </div>
            <div className='action-button-container'>
                <GeneralButton
                    disabled={!setActionButton()}
                    type='normal'
                    className='action-button'
                    text={setActionButtonText()}
                    onClick={() => {
                        handleClick();
                    }}
                />
            </div>
        </Col>
    );
};

EventDetailsSSCommentActions.propTypes = {
    actions: PropTypes.shape({
        postComment: PropTypes.func,
        closeEventLog: PropTypes.func
    }),
    model: PropTypes.shape({
        selectedEvent: PropTypes.object,
        user: PropTypes.object
    }),
};

export default EventDetailsSSCommentActions;
