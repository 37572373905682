export const assetCondition = {
    CONDITION_POOR: 'Poor',
    CONDITION_TOLERABLE: 'Tolerable',
    CONDITION_ALARM: 'Alarm',
    CONDITION_ALERT: 'Alert',
    CONDITION_GOOD: 'Good'
};


export const actionTypes = {
    ACTIVE_SUB_DATA: 'powertrainTools/subscriptionStatus/ACTIVE_SUB_DATA',
    SET_SELECTED_ASSET: 'powertrainAssetDetails/SET_SELECTED_ASSET',
    GET_ASSET_NEWCAPABILITIES: 'powertrainAssetDetails/GET_ASSET_NEWCAPABILITY',
    GET_ASSET_OVERVIEW: 'detailedInformation/GET_ASSET_OVERVIEW'
};
