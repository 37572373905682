import PropTypes from 'prop-types';
import React from 'react';

import { Expandable } from 'sharedComponents';
import { getAssetIcon } from 'helpers/icons';
import KPIListComponent from './KPIListComponent';


const AssetItemComponent = ({
    asset,
    selectedAsset,
    selectedAssetCapability,
    onAssetClick,
    selectedKPIType,
    getKPIsForKPIType,
    getTrendKPIsForAssetV2,
    chartData,
    setKpi,
    kpiCount
}) => {


    const handleAssetToggle = () => {
        if (selectedAsset?.componentID !== asset?.componentID) {
            onAssetClick(asset.componentID);
        } else {
            onAssetClick(null);
        }
    };

    return <Expandable
        title={
            <div className='asset-list-item'>
                <div className='asset-icon-container'>{getAssetIcon(asset.componentType?.typeName, { width: 20, height: 20 })}</div>
                <div className='asset-list-item-name'>{asset.componentName}</div>
            </div>
        }
        onClick={handleAssetToggle}
        open={selectedAsset?.componentID === asset?.componentID}
        kpiCount={kpiCount}
    >
        <KPIListComponent
            selectedAsset={selectedAsset}
            selectedAssetCapability={selectedAssetCapability}
            getKPIsForKPIType={getKPIsForKPIType}
            getTrendKPIsForAssetV2={getTrendKPIsForAssetV2}
            selectedKPIType={selectedKPIType}
            setKpi={setKpi}
            chartData={chartData}
        />
    </Expandable>;
};

AssetItemComponent.propTypes = {
    asset: PropTypes.any,
    selectedAsset: PropTypes.any,
    selectedAssetCapability: PropTypes.any,
    selectedKPIType: PropTypes.any,
    kpis: PropTypes.any,
    kpiLoadingStatus: PropTypes.any,
    setSelectedAsset: PropTypes.func,
    getKPIsForKPIType: PropTypes.func,
    getTrendKPIsForAssetV2: PropTypes.func,
    kpiCount: PropTypes.number
};

export default AssetItemComponent;
