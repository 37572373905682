import { isEqual, omit } from 'lodash';
import { actionTypes } from './constants';

const initialState = {
    limitKPI: {
        kpiList: [],
        error: null,
        isLoading: null
    },
    currentTimeSeries: [],
    timeSeries: {
        timeSeriesList: [],
        error: null,
        isLoading: null,
        assetId: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TIME_SERIES:
            return {
                ...state,
                timeSeries: {
                    ...state.timeSeries,
                    isLoading: true,
                },
            };
        case actionTypes.GET_TIME_SERIES_SUCCESS: {
            const { temp, assetId } = action.payload;
            return {
                ...state,
                timeSeries: {
                    timeSeriesList: [...temp],
                    isLoading: false,
                    error: null,
                    assetId: assetId,
                },
                currentTimeSeries: [...temp].reverse()
            };
        }
        case actionTypes.GET_TIME_SERIES_FAILURE:
            return {
                ...state,
                timeSeries: {
                    timeSeriesList: [],
                    isLoading: false,
                    error: action.payload
                },
            };
        case actionTypes.GET_LIMIT_KPI_SUCCESS:
            return {
                ...state,
                limitKPI: {
                    kpiList: action.payload,
                    error: null,
                    isLoading: 'success'
                }
            };
        case actionTypes.ADD_SIGNAL: {
            const signalExists = state.currentTimeSeries.some(
                item => item.displayName === action.payload.displayName
            );

            //to show error if signal is not selected and trying to add more signals
            if (signalExists) {
                return {
                    ...state,
                    currentTimeSeries: state.currentTimeSeries.map(item => !item.displayName
                        ? { ...item, isSignalError: true }
                        : item
                    )
                };
            }

            return {
                ...state,
                currentTimeSeries: [action.payload, ...state.currentTimeSeries]
            };
        }
        case actionTypes.UPDATE_SIGNAL: {
            const { uniqId, field, value } = action.payload;
            return {
                ...state,
                currentTimeSeries: state.currentTimeSeries?.reverse().map(item =>
                    item.uniqId === uniqId ? { ...item, [field]: value } : item
                )
            };
        }
        case actionTypes.LIMIT_TYPE_CHANGE: {
            const { updatedItem } = action.payload;
            const updatedTimeSeries = state.currentTimeSeries.map(item =>
                item.uniqId === updatedItem.uniqId ? updatedItem : item
            );

            return {
                ...state,
                currentTimeSeries: updatedTimeSeries,
            };
        }
        case actionTypes.DELETE_LIMIT: {
            const { item } = action.payload;

            // Find the currentTimeSeries item to update
            const currentTimeSeriesItemIndex = state.currentTimeSeries.findIndex(currentItem =>
                currentItem.limits.some(limit => limit.uniqId === item.uniqId)
            );

            const updatedCurrentTimeSeries = {
                ...state.currentTimeSeries[currentTimeSeriesItemIndex],
                limits: state.currentTimeSeries[currentTimeSeriesItemIndex].limits.filter(
                    limit => limit.uniqId !== item.uniqId
                )
            };

            // Find the corresponding item in timeSeriesList for comparison
            const correspondingTimeSeriesItem = state.timeSeries.timeSeriesList.find(item =>
                item.uniqId === state.currentTimeSeries[currentTimeSeriesItemIndex].uniqId
            );

            if (correspondingTimeSeriesItem !== undefined) {
                // Update the currentTimeSeries item by removing the limit

                const isModified = !isEqual(updatedCurrentTimeSeries.limits, correspondingTimeSeriesItem.limits);

                // Update isModified in updatedCurrentTimeSeries
                updatedCurrentTimeSeries.isModified = isModified;


            } else {
                updatedCurrentTimeSeries.isModified = true;
            }

            // Update state with updated currentTimeSeries item
            return {
                ...state,
                currentTimeSeries: [
                    ...state.currentTimeSeries.slice(0, currentTimeSeriesItemIndex),
                    updatedCurrentTimeSeries,
                    ...state.currentTimeSeries.slice(currentTimeSeriesItemIndex + 1)
                ]
            };
        }

        case actionTypes.UPDATE_LIMIT: {
            const { limitUniqId, field, value, currentTimeSeriesUniqId } = action.payload;

            // Find the currentTimeSeries item to update
            const currentTimeSeriesItemIndex = state.currentTimeSeries.findIndex(item => item.uniqId === currentTimeSeriesUniqId);
            const correspondingTimeSeriesItem = state.timeSeries.timeSeriesList.find(item => item.uniqId === currentTimeSeriesUniqId);

            // Update the currentTimeSeries item with the payload
            const updatedCurrentTimeSeries = {
                ...state.currentTimeSeries[currentTimeSeriesItemIndex],
                limits: state.currentTimeSeries[currentTimeSeriesItemIndex].limits.map(limit => {
                    if (limit.uniqId === limitUniqId) {
                        const updatedLimit = { ...limit, [field]: value };
                        const initialLimit = correspondingTimeSeriesItem?.limits.find(initialLimit => initialLimit.uniqId === limitUniqId);
                        const isModified = !isEqual(omit(updatedLimit, 'isModified'), omit(initialLimit, 'isModified'));
                        return { ...updatedLimit, isModified };
                    }
                    return limit;
                })
            };

            if (correspondingTimeSeriesItem !== undefined) {
                // Compare with correspondingTimeSeriesItem to determine isModified
                const isModified = !isEqual(
                    updatedCurrentTimeSeries.limits.map(limit => {
                        return {
                            ...limit,
                            isModified: undefined // Exclude isModified from the comparison
                        };
                    }),
                    correspondingTimeSeriesItem?.limits.map(limit => ({
                        ...limit,
                        isModified: undefined // Exclude isModified from the comparison
                    }))
                );
                // Update isModified in updatedCurrentTimeSeries
                updatedCurrentTimeSeries.isModified = isModified;

            } else {
                updatedCurrentTimeSeries.isModified = true;
            }
            // Update state with updated currentTimeSeries item
            return {
                ...state,
                currentTimeSeries: [
                    ...state.currentTimeSeries.slice(0, currentTimeSeriesItemIndex),
                    updatedCurrentTimeSeries,
                    ...state.currentTimeSeries.slice(currentTimeSeriesItemIndex + 1)
                ]
            };
        }

        case actionTypes.ADD_LIMIT: {
            const { currentTimeSeriesUniqId, limit } = action.payload;

            const currentTimeSeriesItemIndex = state.currentTimeSeries.findIndex(item => item.uniqId === currentTimeSeriesUniqId);
            const correspondingTimeSeriesItem = state.timeSeries.timeSeriesList.find(item => item.uniqId === currentTimeSeriesUniqId);

            const updatedCurrentTimeSeries = {
                ...state.currentTimeSeries[currentTimeSeriesItemIndex],
                limits: [...state.currentTimeSeries[currentTimeSeriesItemIndex].limits, { ...limit, isModified: true }]
            };

            if (correspondingTimeSeriesItem !== undefined) {
                const isModified = !isEqual(updatedCurrentTimeSeries.limits, correspondingTimeSeriesItem.limits);
                updatedCurrentTimeSeries.isModified = isModified;
            } else {
                updatedCurrentTimeSeries.isModified = true;
            }

            return {
                ...state,
                currentTimeSeries: [
                    ...state.currentTimeSeries.slice(0, currentTimeSeriesItemIndex),
                    updatedCurrentTimeSeries,
                    ...state.currentTimeSeries.slice(currentTimeSeriesItemIndex + 1)
                ]
            };
        }

        case actionTypes.DISCARD_LIMIT: {
            const { currentTimeSeriesUniqId } = action.payload;

            // Find the index of the currentTimeSeries item to update
            const currentTimeSeriesItemIndex = state.currentTimeSeries.findIndex(item => item.uniqId === currentTimeSeriesUniqId);

            // Find the corresponding item in timeSeriesList for comparison
            const correspondingTimeSeriesItem = state.timeSeries.timeSeriesList.find(item => item.uniqId === currentTimeSeriesUniqId);

            if (currentTimeSeriesItemIndex !== -1) {
                let updatedCurrentTimeSeries;

                if (correspondingTimeSeriesItem) {
                    // If correspondingTimeSeriesItem exists, copy it back to currentTimeSeries
                    updatedCurrentTimeSeries = {
                        ...correspondingTimeSeriesItem,
                        isModified: false // Assuming the copied item is not modified
                    };
                } else {
                    // If correspondingTimeSeriesItem does not exist, reset limit values to empty string
                    updatedCurrentTimeSeries = {
                        ...state.currentTimeSeries[currentTimeSeriesItemIndex],
                        limits: state.currentTimeSeries[currentTimeSeriesItemIndex].limits.map(limit => ({
                            ...limit,
                            limitValue: '',
                            limitLevel: '',
                            limitDirection: '',
                            isModified: false,
                        })),
                        isModified: false
                    };
                }

                // Update state with the updated currentTimeSeries item
                return {
                    ...state,
                    currentTimeSeries: [
                        ...state.currentTimeSeries.slice(0, currentTimeSeriesItemIndex),
                        updatedCurrentTimeSeries,
                        ...state.currentTimeSeries.slice(currentTimeSeriesItemIndex + 1)
                    ]
                };
            } else {
                // Handle the case where currentTimeSeriesItem is not found
                return state;
            }
        }


        case actionTypes.REMOVE_SIGNAL: {
            const { currentTimeSeriesUniqId } = action.payload;
            return {
                ...state,
                currentTimeSeries: state.currentTimeSeries.filter(
                    (item) => item.uniqId !== currentTimeSeriesUniqId
                ),
            };
        }
        case actionTypes.CANCEL: {
            // Create a new currentTimeSeries array based on timeSeriesList
            const newCurrentTimeSeries = state.timeSeries?.timeSeriesList;

            // Update the state with the new currentTimeSeries array
            return {
                ...state,
                currentTimeSeries: newCurrentTimeSeries
            };
        }
        case actionTypes.POST_LIMIT_REQUEST:
            return {
                ...state,
                timeSeries: {
                    ...state.timeSeries,
                    error: null,
                    isLoading: true,
                }
            };
        case actionTypes.POST_LIMIT_SUCCESS:
            return {
                ...state,
                timeSeries: {
                    ...state.timeSeries,
                    isLoading: false,
                    error: null
                }
            };
        case actionTypes.POST_LIMIT_FAILURE:
            return {
                ...state,
                timeSeries: {
                    ...state.timeSeries,
                    isLoading: false,
                    // error: action.error
                }
            };
        case actionTypes.HANDLE_SIGNAL_UPDATE: {
            const updatedItem = action.payload;
            const updatedItemIndex = state.currentTimeSeries.findIndex(item => item.uniqId === updatedItem.uniqId);

            if (updatedItemIndex !== undefined) {
                // Create a new array with the updated item
                const updatedCurrentTimeSeries = [
                    ...state.currentTimeSeries.slice(0, updatedItemIndex),
                    updatedItem,
                    ...state.currentTimeSeries.slice(updatedItemIndex + 1)
                ];

                return {
                    ...state,
                    currentTimeSeries: updatedCurrentTimeSeries
                };
            }

            // If the item is not found, return the state unchanged
            return state;
        }

        case actionTypes.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
