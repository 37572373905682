import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router';
import { GeneralRouterTabs } from 'sharedComponents';
import { useHistory, useLocation } from 'react-router-dom';
import { getTabOptions } from './helpers';
import { ConditionIndicesOverview } from './ConditionIndicesOverview';
import { MaintenanceOverviewLazy } from './MaintenanceOverview';
import featureConfigHelper from 'helpers/featureConfigHelper';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { Breadcrumbs } from 'sharedComponents/Breadcrumbs';
import { routes } from 'routes';


const PowertrainConditionMonitoringComponent = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { t: translate } = useTranslation();
    const featureConfig = new featureConfigHelper();
    const [breadcrumbRoute, setBreadcrumbRoute] = useState([]);
    const [tabOptions, setTabOptions] = useState(getTabOptions(translate));


    useEffect(() => {
        setTabOptions(getTabOptions(translate));
    }, [translate]);

    const updateBreadcrumbRoute = () => {
        if (pathname === routes.ConditionOverview) {
            const updatedRoute = [
                    { path: routes.Overview, name: translate('ABB.Powertrain.Frontend.ptConditionMonitoringMainMenu') },
                    { path: '', name: translate('ABB.Powertrain.Frontend.ptConditionMonitoringOverviewTab')  }
                ];
            setBreadcrumbRoute(updatedRoute);
        }
        if (pathname === routes.MaintenanceOverview) {
            const updatedRoute = [
                { path: routes.Overview, name: translate('ABB.Powertrain.Frontend.ptConditionMonitoringMainMenu') },
                { path: '', name: translate('ABB.Powertrain.Frontend.ptMaintenanceOverviewTab')  }
            ];
            setBreadcrumbRoute(updatedRoute);
        }
    };

    useEffect(() => {
        updateBreadcrumbRoute();
    }, [pathname]);

    const handleBreadcrumbClick = (clickedRoute) => {
        const clickedPathname = clickedRoute?.pathname;

        if (clickedPathname === routes.Overview) {
            history.push(routes.Overview);
        }
    };

    return (
        <div className='general-page-content'>
            <div className='general-page-content__container'>
                <Container fluid className='px-4'>
                    <Row>
                        <Col>
                            <Stack direction='horizontal' gap={3}>
                                <Breadcrumbs
                                    routes={breadcrumbRoute}
                                    onBreadcrumbClick={handleBreadcrumbClick}
                                />
                            </Stack>
                        </Col>
                    </Row>
                </Container>
            </div>
            <GeneralRouterTabs
                options={tabOptions}
                variant='primary'
                removeSearchFilter={true}
            >
                <Switch>
                    <Route Route path={tabOptions.conditionOverview.route}>
                        <ConditionIndicesOverview />
                    </Route>
                    {featureConfig.isCBMFeatureEnabled() &&
                        <Route path={tabOptions.maintenanceOverview.route}>
                            <MaintenanceOverviewLazy />
                        </Route>
                    }
                    <Route path={tabOptions.cbm.route}>
                        <div>
                            Work in progress...
                        </div>
                    </Route>
                    <Redirect from='*' to={tabOptions.conditionOverview.route} />
                </Switch>
            </GeneralRouterTabs>
        </div >
    );
};

export default PowertrainConditionMonitoringComponent;
