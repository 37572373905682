import React from 'react';
import { useTranslation } from 'react-i18next';
import { includes, map } from 'lodash';
import PropTypes from 'prop-types';
import {
    GeneralSelect,
    GeneralButton
} from 'sharedComponents';
import { IconCondition } from 'svgIcons/MotionPortalIcons';
import {
    newConditionIndices
} from 'helpers/constants';
import {
    getFormattedAssetTypes,
    getFormattedAssetType
} from './helpers';
import './style.scss';


const FilterComponent = ({
    setAssetTypeFilter,
    assetTypeFilter,
    toggleConditionFilter,
    conditionFilter,
    resetFilters,
    isDisabled,
    assetTypeFilterVisible
}) => {
    const { t: translate } = useTranslation();

    return <>
        {<div className='powertrain-asset-overview-filters-container'>
            {assetTypeFilterVisible && <><div className='filter-section asset-type-filter'>
                <label htmlFor='asset-type-filter' className='section-title'>
                    {translate('ABB.Powertrain.Frontend.assetTypesLabel')}
                </label>

                <GeneralSelect
                    className='asset-type-filter'
                    inputId='asset-type-filter'
                    options={getFormattedAssetTypes(translate)}
                    getOptionLabel={option => option.title}
                    placeholder={translate('ABB.Powertrain.Frontend.allassettypesLabel')}
                    getOptionValue={option => option.id}
                    value={getFormattedAssetType(assetTypeFilter, translate)}
                    menuShouldScrollIntoView={false}
                    onChange={value => setAssetTypeFilter(value.id)}
                    isSearchable={false}
                    isDisabled={isDisabled}
                />
            </div>
                <hr className='separator' /></>
            }
            <div className='filter-section  condition-index-filter'>
                <div className='section-title'>
                    {translate('ABB.Powertrain.Frontend.overallStatus')}
                </div>
                <div className='condition-indexes'>
                    {map(newConditionIndices, (conditionIndex, index) => {
                        return <div className='item' key={index}>
                            <button className={
                                'condition-index' +
                                (includes(conditionFilter, conditionIndex.conditionIndexID) ? ' selected' : '') +
                                (isDisabled ? ' disabled' : '')}
                                onClick={() => { !isDisabled && toggleConditionFilter(conditionIndex.conditionIndexID); }}
                            >
                                <IconCondition condition={conditionIndex.iconID} />
                            </button>
                            <span>{translate(`ABB.Powertrain.Frontend.${conditionIndex.translationKey}Label`)}</span>
                        </div>;
                    })}
                </div>
            </div>
            <hr className='separator' />
            <div className='filter-section reset-filters'>
                <GeneralButton
                    type='normal'
                    disabled={isDisabled}
                    text={translate('ABB.Powertrain.Frontend.resetFiltersButton')}
                    onClick={() => resetFilters()}
                />
            </div>
        </div>}

    </>;
};

FilterComponent.propTypes = {
    setAssetTypeFilter: PropTypes.func,
    assetTypeFilter: PropTypes.string,
    toggleConditionFilter: PropTypes.func,
    conditionFilter: PropTypes.array,
    resetFilters: PropTypes.func,
    isDisabled: PropTypes.bool,
    assetTypeFilterVisible: PropTypes.bool
};

export default FilterComponent;
