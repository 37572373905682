import { createSelector } from 'reselect';
import {
	getGeneralFilteredAssets,
	getSelectedOrganization
} from 'helpers/selectors';


const getSelectedEvent = ({ eventLog }) => {
	return eventLog.selectedEvent;
};

const getSelectedAsset = ({ powertrainAssetDetails }) => {
	return powertrainAssetDetails.selectedAsset;
};

const getIsExpanded = ({ eventLog }) => {
	return eventLog.isExpanded;
};

const getNewCapabilityLoadingStatus = ({ powertrainAssetDetails }) => {
	return powertrainAssetDetails.newAssetCapability?.loadingStatus;
};


export const getModel = createSelector([
	getSelectedEvent,
	getSelectedAsset,
	getSelectedOrganization,
	getGeneralFilteredAssets,
	getIsExpanded,
	getNewCapabilityLoadingStatus
], (
	selectedEvent,
	selectedAsset,
	selectedOrganization,
	generalFilteredAssets,
	isExpanded,
	assetCapabilityLoadingStatus
) => {
	return {
		selectedEvent,
		selectedAsset,
		selectedOrganization,
		generalFilteredAssets,
		isExpanded,
		assetCapabilityLoadingStatus
	};
});


