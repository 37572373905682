import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import {
    GeneralSelect,
    PopOutChart,
    InfoMessage
} from 'sharedComponents';
import { MAX_LOGGER_SIGNALS } from '../constants';
import useChartConfig, { chartPlugins } from './useChartConfig';
import { apiCallStatus } from 'helpers/constants';
import { loggerChartContainerId } from './constants';
import {
    PopOutButton,
    ResetZoomButton,
    withLeftButtonGroup,
    withPopOutRow,
    withRightButtonGroup
} from 'sharedComponents/Chart/headers';
import './style.scss';


const LoggerVisualisationComponent = ({
    model: {
        loggers,
        loggerId = [],
        loggersLoadingStatus,
        selectedLoggerSignals,
        signalDataLoadingStatus
    },
    actions: {
        getLoggers,
        getEventLoggerSignals,
        removeSelectedSignal,
    }
}) => {
    const { t: translate } = useTranslation();

    const [selectedLogger, setSelectedLogger] = useState();
    const [selectedSignal, setSelectedSignal] = useState();

    const [isZoomed, setIsZoomed] = useState(false);

    useEffect(() => {
        if (loggerId && loggerId.length) {
            getLoggers(loggerId[0].id);
        }
    }, [loggerId, getLoggers]);

    useEffect(() => {
        if (loggers.length && loggersLoadingStatus === apiCallStatus.SUCCESS) {
            const firstLogger = loggers[0];
            setSelectedLogger(firstLogger);
            const signals = firstLogger.signals.slice(0, Math.min(firstLogger.signals.length, MAX_LOGGER_SIGNALS));
            getEventLoggerSignals(firstLogger.id, signals);
        }
    }, [loggersLoadingStatus]);

    useEffect(() => {
        if (loggers.length) {
            if (selectedLogger) {
                setSelectedLogger(find(loggers, { id: selectedLogger.id }));
            }
            if (selectedSignal) {
                setSelectedSignal(null);
            }
        }
    }, [loggers]);


    useEffect(() => {
        if (selectedSignal) {
            const newSignals = [...selectedLoggerSignals];
            if (newSignals.length >= MAX_LOGGER_SIGNALS) {
                newSignals.pop();
            }
            getEventLoggerSignals(loggerId[0].id, [selectedSignal]);
        }
    }, [selectedSignal]);

    const isLoading = loggersLoadingStatus === apiCallStatus.LOADING || signalDataLoadingStatus === apiCallStatus.LOADING;
    const chartConfig = useChartConfig(selectedLoggerSignals, removeSelectedSignal);

    const availableSignals = selectedLogger?.signals.filter(
        signal => !selectedLoggerSignals.some(s => s.signalID === signal.id)
    );

    const DataLoggerSelector = () => {
        return <Col xl={3} md={6}>
            <div className='data-logger-selector-label'>
                {translate('ABB.Powertrain.Frontend.eventListAssistanceSelectLogger')}
            </div>
            <GeneralSelect
                id='data-logger-selector'
                options={loggers}
                value={selectedLogger}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                onChange={setSelectedLogger}
                isLoading={isLoading}
                isDisabled={isLoading}
                noOptionsMessage={() => translate('ABB.Powertrain.Frontend.lblNoOptions')}
            />
        </Col>;
    };

    const TrendSignalSelector = () => {
        return <Col xl={3} md={6}>
            <div className='trend-signal-selector-label'>
                {translate('ABB.Powertrain.Frontend.eventListAssistanceTrendSelectSignal')}
            </div>
            <GeneralSelect
                id='trend-signal-selector'
                placeholder={translate('ABB.Powertrain.Frontend.eventListAssistanceTrendSelectSignal')}
                options={availableSignals}
                value={selectedSignal}
                onChange={setSelectedSignal}
                isLoading={isLoading}
                isDisabled={isLoading}
                noOptionsMessage={() => translate('ABB.Powertrain.Frontend.lblNoOptions')}
            />
        </Col>;
    };

    const MainChartHeader = withPopOutRow([
        withLeftButtonGroup([DataLoggerSelector, TrendSignalSelector]),
        withRightButtonGroup([ResetZoomButton, PopOutButton])
    ]);

    const PopOutChartHeader = withPopOutRow([
        withRightButtonGroup([ResetZoomButton, PopOutButton])
    ]);

    return <Container fluid id='assistance-logger-visualisation-container'>
        <Row className='assistance-trend-chart'>
            {
                <Col >
                    <PopOutChart
                        hasKpi={!!selectedLoggerSignals.length}
                        renderNoKpi={() => <InfoMessage
                            title='ABB.Powertrain.Frontend.eventListAssistanceNoSignalSelected' />}
                        options={chartConfig}
                        loadingStatus={signalDataLoadingStatus}
                        container={loggerChartContainerId}
                        modules={chartPlugins}
                        isZoomed={isZoomed}
                        setIsZoomed={setIsZoomed}
                        mainChartHeader={MainChartHeader}
                        popOutChartHeader={PopOutChartHeader}
                    />
                </Col>
            }
        </Row>
    </Container>;
};

LoggerVisualisationComponent.propTypes = {
    selectedAsset: PropTypes.object.isRequired,
    selectedEvent: PropTypes.object.isRequired,
    model: PropTypes.shape({
        showUtcDates: PropTypes.bool.isRequired,
        loggers: PropTypes.array,
        loggersLoadingStatus: PropTypes.string,
        selectedLoggerSignals: PropTypes.array,
        signalDataLoadingStatus: PropTypes.string,
    }),
    actions: PropTypes.shape({
        removeSelectedSignal: PropTypes.func.isRequired,
        getLoggers: PropTypes.func,
        getEventLoggerSignals: PropTypes.func
    }),

};

export default LoggerVisualisationComponent;
