import { ComponentApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import { actionTypes, commonActionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';

const getAssetDetailsAction = getApiActionCreator(actionTypes.GET_ASSET_DETAILS);

export const getAssetDetails = (asset) => {
    return (dispatch, getState) => {
        const savedAssetDetails = getState().detailedInformation.assetDetails.data;

        if (savedAssetDetails?.componentID !== asset.componentID) {
            dispatch(getAssetDetailsAction(apiCallStatus.LOADING));
            ComponentApi.getAssetDetailsV2(asset)
                .then(details => {
                    dispatch(getAssetDetailsAction(apiCallStatus.SUCCESS, { ...details, details: { ...details?.details, ...asset?.site } }));
                }, (err) => {
                    dispatch(getAssetDetailsAction(apiCallStatus.ERROR, null, err));
                });
        }
    };
};

export const setActiveTab = (activeTab) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ACTIVE_TAB, activeTab });
    };
};
export const setAssetConditionInsightStatus = (conditionInsightStatus) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_SELECTED_ASSET_CONDITION_INSIGHTS_STATUS, conditionInsightStatus });
    };
};
export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};

export const clearDownloadMeasurementStore = () => {
    return (dispatch) => {
        dispatch({ type: commonActionTypes.CLEAR_STORE });
    };
};
