import forEach from 'lodash/forEach';
import transform from 'lodash/transform';
import isNumber from 'lodash/isNumber';

import { getTranslatedKPINameV2 } from 'helpers/translateHelper';
import { chartLegendItemClick } from '../../../../helpers';


export const getTrendsUniqueYScaling = (selectedTrends) => {
    let trendsUniqueYScalingAvailable = false;

    if (selectedTrends?.length > 1) {
        const unit = selectedTrends[0]?.data[0]?.config?.unit || '';

        forEach(selectedTrends, (trendData) => {
            trendsUniqueYScalingAvailable = trendData.data[0]?.config?.unit === unit;
            return trendsUniqueYScalingAvailable;
        });
    }

    return trendsUniqueYScalingAvailable;
};

const getMeasurements = (measurements, showRangeData) => {
    const measurementValues = [];
    const minMaxValues = [];

    forEach(measurements, (measurement) => {
        if (measurement.timestamp !== null) {
            const date = new Date(measurement.timestamp).getTime();
            measurementValues.push([date, measurement?.value]);
            if (showRangeData) {
                minMaxValues.push([date,
                    isNumber(measurement?.min) ? measurement?.min : null,
                    isNumber(measurement?.max) ? measurement?.max : null]);
            }
        }
    });

    return { measurementValues, minMaxValues };
};

export const getSeries = (selectedKpisData) => {
    const trendsData = { yAxis: [], seriesData: [], allSeriesLength: 0 };

    return transform(selectedKpisData, (acc, item, index) => {
        const config = item.data[0]?.config ? item.data[0]?.config : {};
        const unit = item.kpi?.unit || '';
        const precision = config && config.precision;

        const kpiName = getTranslatedKPINameV2(item.kpi);
        const componentName = item.component.componentName;
        const trendDataValues = item.data[0]?.values;

        const seriesId = index;
        const yAxisId = `y_${index}`;


        const { measurementValues, minMaxValues } = getMeasurements(trendDataValues, selectedKpisData?.length === 1);

        acc.yAxis.push({
            labels: {
                format: '{value} ' + unit,
                style: {
                    color: item.color
                }
            },
            id: yAxisId,
            title: {
                text: kpiName,
                enabled: true,
                style: {
                    color: item.color
                }
            },
            opposite: index % 2 !== 0,
            visible: true
        });

        acc.seriesData.push({
            name: `${kpiName}`,
            data: measurementValues,
            tooltip: {
                valueSuffix: ` ${unit}`,
                valueDecimals: precision
            },
            color: item.color,
            marker: {
                enabled: true,
                radius: 2
            },
            lineWidth: 1,
            id: seriesId,
            component: componentName,
            yAxis: yAxisId,
            events: {
                legendItemClick: chartLegendItemClick
            },
        });
        if (minMaxValues?.length) {
            acc.seriesData.push(
                {
                    name: `${kpiName}`,
                    data: minMaxValues,
                    type: 'arearange',
                    id: 'rangeSeries',
                    lineWidth: 2,
                    radius: 2,
                    linkedTo: ':previous',
                    fillOpacity: 0.3,
                    opacity: 0.3,
                    color: item.color,
                    zIndex: 0,
                    tooltip: {
                        valueSuffix: ` ${unit}`,
                    },
                    marker: {
                        enabled: true,
                        radius: 2
                    }
                }
            );
        }

        acc.allSeriesLength = acc.allSeriesLength + item.data[0]?.values.length;
    }, trendsData);
};
