import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { GeneralDialog, TextArea, GeneralSelect } from 'sharedComponents';
import { getClosingReasons } from '../helpers';
import { maxCommentSize } from '../EventDetails/EventDetailsSS/constants';


const CloseEventDialogComponent = (props) => {
    const { t: translate } = useTranslation();
    const closingReasonOptions = getClosingReasons(translate);
    const [closingReason, setClosingReason] = useState(closingReasonOptions[0]);
    const [comment, setComment] = useState('');

    useEffect(() => {
        setClosingReason(closingReasonOptions[0]);
        setComment('');
    }, [props.show]);

    return <GeneralDialog
        modalClassName='close-event-dialog'
        show={props.show}
        close={() => { props.close(); }}
        title={translate('ABB.Powertrain.Frontend.detailedInfoEventLogCloseEventDialogTitle')}
        cancelButtonProps={{
            text: translate('ABB.Powertrain.Frontend.detailedInfoEventLogCloseEventDialogCancelButton')
        }}
        acceptButtonProps={{
            disabled: comment === '',
            text: translate('ABB.Powertrain.Frontend.detailedInfoEventLogCloseEventDialogCloseButton'),
            onClick: () => { props.closeEventHandler(closingReason, comment); props.close(); }
        }}
    >
        <div>
            <div>{translate('ABB.Powertrain.Frontend.detailedInfoEventLogCloseEventDialogSelectCauseLabel')}</div>
            <GeneralSelect
                className='event-cause-ddn'
                options={closingReasonOptions}
                defaultValue={closingReason}
                getOptionLabel={option => option.title}
                getOptionValue={option => option.id}
                onChange={(option) => setClosingReason(option)}
                menuShouldScrollIntoView={false}
                isSearchable={false}
            />
        </div>
        <div>
            <TextArea
                value={comment}
                onChange={(c) => { setComment(c); }}
                label={translate('ABB.Powertrain.Frontend.detailedInfoEventLogCloseEventDialogCommentLabel')}
                readonly={false}
                maxLength={maxCommentSize}
            />
        </div>
    </GeneralDialog>;
};

CloseEventDialogComponent.propTypes = {
    closeEventHandler: PropTypes.func,
    close: PropTypes.func,
    show: PropTypes.bool
};

export default CloseEventDialogComponent;
