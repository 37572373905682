import React from 'react';
import {
    lowerCase,
    upperFirst,
    orderBy,
    groupBy,
    map,
    forIn,
    find
} from 'lodash';
import { convertUtcToLocalDateTime, getUTCDateString, toLocaleDateTimeString } from 'helpers/dateHelper';
import { IconDataLogger, IconComment, IconClose } from 'svgIcons/MotionPortalIcons';
import { dateKind, EventTypesIconWithName, eventStatus, causeOfEvents } from '../constants';
import { eventTypeOrderSmSe, pageSize } from './constants';


const getSSColumns = (translate, closeEventHandler, setSelectedEvent) => {
    return [
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogNameTitle'),
            dataIndex: 'messageText',
            fixed: 'left',
            width: 200,
            isSorting: true,
            onCell: (event) => ({
                onClick: () => setSelectedEvent(event)
            })
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogAdviceTitle'),
            dataIndex: 'description',
            width: 200,
            isSorting: true,
            onCell: (event) => ({
                onClick: () => setSelectedEvent(event)
            })
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogActionsTitle'),
            dataIndex: 'rawEvent',
            width: 100,
            // eslint-disable-next-line react/display-name
            render: (event) => <div>{event.lifecycle !== eventStatus.CLOSED ?
                <div className='close-button'>
                    <button onClick={() => { closeEventHandler(event); }}>
                        <IconClose />
                    </button>
                </div> : null}
            </div>
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogOccurredOnTitle'),
            dataIndex: 'timestamp',
            width: 200,
            isSorting: true,
            onCell: (event) => ({
                onClick: () => setSelectedEvent(event)
            }),
            render: (occurredOn) => convertUtcToLocalDateTime(occurredOn)
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogStatusTitle'),
            dataIndex: 'lifecycle',
            width: 150,
            onCell: (event) => ({
                onClick: () => setSelectedEvent(event)
            })
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogClosingReasonTitle'),
            dataIndex: 'closingReason',
            width: 150,
            onCell: (event) => ({
                onClick: () => setSelectedEvent(event)
            })
        }
    ];
};

const getDriveColumns = (translate, setSelectedEvent, assistanceCapability, selectedDateKind, nameWithType = false) => {
    const eventNameColumn = {
        title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogNameTitle'),
        dataIndex: 'messageText',
        fixed: 'left',
        isSorting: true,
        onCell: (event) => ({
            onClick: () => assistanceCapability && setSelectedEvent(event)
        })
    };

    if (nameWithType) {
        eventNameColumn.dataIndex = 'rawEvent';
        eventNameColumn.render = (event) => <div className='event-name-with-type'>
            <div className='event-type-icon'>{EventTypesIconWithName.find(et => et.typeID === event.severityCode)?.icon}</div>
            <div>{event.messageText}</div>
        </div>;
    }

    return [
        eventNameColumn,
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogCauseTitle'), //*
            dataIndex: 'cause',
            width: 200,
            isSorting: true,
            onCell: (data) => ({
                onClick: () => assistanceCapability && setSelectedEvent(data)
            }),
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogTimeTitle'), //*
            dataIndex: 'timestamp',
            width: 200,
            isSorting: true,
            onCell: (data) => ({
                onClick: () => assistanceCapability && setSelectedEvent(data)
            }),
            render: (time) => toLocaleDateTimeString(time, { asUTC: selectedDateKind === dateKind.UTC, showOffset: true })
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogDeviceTimestampTitle'), //*
            dataIndex: 'deviceTimestamp',
            width: 200,
            isSorting: true,
            onCell: (data) => ({
                onClick: () => assistanceCapability && setSelectedEvent(data)
            })
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogAssetName'), //*
            dataIndex: 'assetName',
            width: 200,
            isSorting: true,
            onCell: (data) => ({
                onClick: () => assistanceCapability && setSelectedEvent(data)
            }),
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogInfoTitle'),
            dataIndex: 'info',
            width: 100,
            // eslint-disable-next-line react/display-name
            render: (info) => <div className='other-info'>
                {info.hasDataLoggers || info.hasBinaryLogger ? <div className='action-icon'><IconDataLogger /></div> : null}
                {info.hasComments ? <div className='action-icon'><IconComment /></div> : null}
            </div>,
            onCell: (data) => ({
                onClick: () => assistanceCapability && setSelectedEvent(data)
            })
        }
    ];
};

const getAssetNameById = (assets, id) => {
    const assetById = find(assets, (asset) => {
        return asset.id === id;
    });
    return assetById?.label;
};

const mapEvents = (assets, translate, events = []) => {
    return map(events, (event) => {
        return {
            eventId: event.eventId,
            rawEvent: {
                ...event,
                sortingItem: event.messageText
            },
            assetName: getAssetNameById(assets, event.assetId),
            messageText: event.messageText,
            description: event.description,
            occurredOn: event.timestamp,
            timestamp: event.timestamp,
            lifecycle: event.lifecycle ? translate(`ABB.Powertrain.Frontend.eventListFilterSSEventStatus${event.lifecycle}`) : '',
            closingReason: event.closingReasonCode ? translate(`ABB.Powertrain.Frontend.eventClosingReason${event.closingReasonCode}`) : '',
            cause: event.causeCode ? translate(`ABB.Powertrain.Frontend.eventCauseOption${event.causeCode}`) : '',
            deviceTimestamp: event.deviceTimestamp,
            time: event.timestamp,
            info: {
                hasComments: event.hasComments,
                hasDataLoggers: event.hasDataLoggers,
                hasBinaryLogger: event.hasBinaryLogger
            }
        };
    });
};

const formatDriveEventLogGroups = (eventLogs, assets, translate) => {
    const groupedEvents = groupBy(eventLogs || [], 'messageText');
    const formattedData = [];

    forIn(groupedEvents, (eventGroup, index) => {
        formattedData.push({
            id: `eventGroup_${index}`,
            eventGroupName: `${index} (${eventGroup.length})`,
            eventType: eventGroup[0].severityCode,
            events: mapEvents(assets, translate, eventGroup)
        });
    });

    return formattedData.sort((a, b) => a.eventGroupName.localeCompare(b.eventGroupName));
};

const getEventTypeLabel = (key) => `ABB.Powertrain.Frontend.lblEventListEventType${upperFirst(lowerCase(key))}`;

const formatSmartSensorEventLogGroups = (eventLogs, assets, translate) => {
    const groupedEvents = groupBy(eventLogs, 'severityCode');
    const formattedData = [];
    forIn(groupedEvents, (eventGroup, key) => {
        formattedData.push({
            eventGroupName: `${translate(getEventTypeLabel(key))} (${eventGroup.length} ${translate('ABB.Powertrain.Frontend.groupsLbl')})`,
            eventType: eventGroup[0].severityCode,
            events: mapEvents(assets, translate, eventGroup),
            orderID: eventGroup[0]?.severityCode && eventTypeOrderSmSe[eventGroup[0].severityCode]
        });
    });

    return orderBy(formattedData, 'orderID');
};

const formatEventLogList = (eventLogs, assets, translate) => {
    return {
        events: mapEvents(assets, translate, eventLogs),
    };
};

export const formatEventLogs = (eventLogs, assets, isSS, grouped, translate) => {
    if (isSS) {
        return formatSmartSensorEventLogGroups(eventLogs, assets, translate);
    }
    else if (grouped) {
        return formatDriveEventLogGroups(eventLogs, assets, translate);
    }
    return formatEventLogList(eventLogs, assets, translate);
};


export const getColumnConfig = (isSS, grouped, translate, closeEventHandler, setSelectedEvent, assistanceCapability, dateKind) => {
    return isSS ?
        getSSColumns(translate, closeEventHandler, setSelectedEvent) :
        getDriveColumns(translate, setSelectedEvent, assistanceCapability, dateKind, !grouped);
};

export const getDateInterval = (dateSelection, selectedDateKind) => {
    if (selectedDateKind === dateKind.UTC) {
        const from = new Date(dateSelection.from);
        const offsetInHours = from.getTimezoneOffset();
        const to = new Date(dateSelection.to);
        dateSelection = {
            from: getUTCDateString(new Date(from.getTime() - offsetInHours * 60 * 1000)),
            to: getUTCDateString(new Date(to.getTime() - offsetInHours * 60 * 1000))
        };
    }
    return dateSelection;
};


export const formatEventListRequest = (assets, dateSelection, filters) => {
    return {
        assetIds: map(assets, (asset) => asset.id),
        timestampFrom: dateSelection.from,
        timestampTo: dateSelection.to,
        pageSize,
        ...filters?.eventTypes ? { severityCodes: filters?.eventTypes } : {},
        ...filters?.eventStatus && filters?.eventStatus !== eventStatus.ALL ? { statusCodes: [filters.eventStatus] } : {},
        ...filters?.causeOfEvent && filters?.causeOfEvent !== causeOfEvents.All ? { causeCodes: [filters.causeOfEvent] } : {},
        ...filters.withCommentsOnly ? { hasComments: filters.withCommentsOnly } : {},
        ...filters.withDataLoggersOnly ? { hasDataLoggers: filters.withDataLoggersOnly } : {},
        messageTextContains: filters.eventName,
        externalEventIds: []
    };
};
