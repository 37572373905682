import React from 'react';
import PropTypes from 'prop-types';

const IconInfoType = ({ width = 16, height = 16, color = 'none' }) => {
	return <svg width={width} height={height} viewBox='0 0 16 16' fill={color} xmlns='http://www.w3.org/2000/svg'>
		<path d='M8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1V1ZM9 12H7V8H6V7H8C8.27756 7.02527 8.53518 7.15495 8.72081 7.36285C8.90643 7.57075 9.00622 7.84136 9 8.12V12ZM7.75 6C7.41848 6 7.10054 5.8683 6.86612 5.63388C6.6317 5.39946 6.5 5.08152 6.5 4.75C6.5 4.41848 6.6317 4.10054 6.86612 3.86612C7.10054 3.6317 7.41848 3.5 7.75 3.5C8.08152 3.5 8.39946 3.6317 8.63388 3.86612C8.8683 4.10054 9 4.41848 9 4.75C9 5.08152 8.8683 5.39946 8.63388 5.63388C8.39946 5.8683 8.08152 6 7.75 6V6ZM8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1V1ZM9 12H7V8H6V7H8C8.27756 7.02527 8.53518 7.15495 8.72081 7.36285C8.90643 7.57075 9.00622 7.84136 9 8.12V12ZM7.75 6C7.41848 6 7.10054 5.8683 6.86612 5.63388C6.6317 5.39946 6.5 5.08152 6.5 4.75C6.5 4.41848 6.6317 4.10054 6.86612 3.86612C7.10054 3.6317 7.41848 3.5 7.75 3.5C8.08152 3.5 8.39946 3.6317 8.63388 3.86612C8.8683 4.10054 9 4.41848 9 4.75C9 5.08152 8.8683 5.39946 8.63388 5.63388C8.39946 5.8683 8.08152 6 7.75 6V6Z' fill={color} />
	</svg>;
};

IconInfoType.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string
};
export default IconInfoType;
