import {
    getLoadingStatuses,
    getAssetsLoadingStatus,
    getPowertrainLoadingStatus,
    getContextLoadingStatus
} from 'helpers/selectors';
import { createSelector } from 'reselect';

const getSelectedPowertrain = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails?.selectedPowertrain;
};

const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails?.selectedAsset;
};

const getNewCapability = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails?.newAssetCapability;
};


export const getModel = createSelector(
    [
        getLoadingStatuses,
        getPowertrainLoadingStatus,
        getAssetsLoadingStatus,
        getContextLoadingStatus,
        getSelectedPowertrain,
        getSelectedAsset,
        getNewCapability
    ],
    (
        generalFilterLoadingStatus,
        powertrainsLoadingStatus,
        assetsLoadingStatus,
        contextLoadingStatus,
        selectedPowertrain,
        selectedAsset,
        newSelectedAssetCapability
    ) => {
        return {
            generalFilterLoadingStatus,
            powertrainsLoadingStatus,
            assetsLoadingStatus,
            contextLoadingStatus,
            selectedPowertrain,
            selectedAsset,
            newSelectedAssetCapability
        };
    }
);
