import React from 'react';
import { Header } from './Header';
import Tabs from './Tabs';
import { InfoMessage } from 'sharedComponents/InfoMessage';
import { NotificationStatusBar } from './NotificationStatusBar';
import './style.scss';

const DetailedInformationComponent = ({ model, actions }) => {
    const { asset } = model;
    function renderTabContent() {
        if (asset.isUserAccessible) {
            return <Tabs model={model} actions={actions} />;
        }
        return (
            <div className='pt-asset-permission-error'>
                <InfoMessage
                    title='ABB.Powertrain.Frontend.detailedInformationPermissionErrorTitle'
                    text='ABB.Powertrain.Frontend.detailedInformationPermissionErrorText'
                />
            </div>
        );

    }

    function renderDetailedInformationContent() {
        if (!asset) {
            return;
        }
        return (
            <>
                <NotificationStatusBar />
                <Header asset={asset} />
                {renderTabContent()}
            </>
        );
    }

    return (
        <div className='pt-detailed-information-container'>
            <div className='details-wrapper'>
                <div className='detailed-information-content'>
                    {renderDetailedInformationContent()}
                </div>
            </div >
        </div >
    );
};

export default DetailedInformationComponent;
