import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map, filter } from 'lodash';
import Asset from '../AssetRowComponent';
import { ScrollableList } from '../ScrollableListComponent';
import { pageSize } from '../constants';
import { formatConditionIndexOverviewRequest } from '../helpers';


const AssetOverviewComponent = (props) => {
    const { t: translate } = useTranslation();
    const [timer, setTimer] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState({});
    const [finalAssetOverviewLoadingStatus, setFinalAssetOverviewLoadingStatus] = useState(null);
    const [filteredAssetOverviewList, setFilteredAssetOverviewList] = useState(null);
    const [isHorizontalScrollActive, setIsHorizontalScrollActive] = useState(false);
    const {
        pageNumber,
        assetOverviewLoadingStatus,
        assetOverviewList,
        hasMore,
    } = props.model;
    const { getAssetConditionIndicesRequest } = props.actions;
    const {
        assetNumber,
        selectedFilters,
        searchBoxFilter,
        conditionFilter,
        assetTypeFilter,
        withVisibleFilter,
        asset
    } = props;
    const overviewData = useSelector(state => state?.overview?.assets);

    useEffect(() => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            getAssetConditionIndicesRequest(formatConditionIndexOverviewRequest(
                1,
                selectedFilters,
                searchBoxFilter,
                conditionFilter,
                assetTypeFilter
            ));
        }, searchBoxFilter?.length > 0 ? 700 : 0);

        setTimer(newTimer);
    }, [
        searchBoxFilter,
        JSON.stringify(selectedFilters),
        JSON.stringify(conditionFilter),
        assetTypeFilter
    ]);


    const handleSelectedItem = (asset) => {
        setSelectedAsset(asset?.asset || asset);
    };

    useEffect(() => {
        handleSelectedItem(asset);
    }, [asset]);

    const handleReset = () => {
        setSelectedAsset(null);
    };

    const getFilteredList = (overviewData) => {
        const activeAssetId = map(overviewData?.data, 'id');
        return filter(assetOverviewList, item => activeAssetId.includes(item.assetId));
    };

    const onHorizontalScrollHandler = (isScroll) => {
        setIsHorizontalScrollActive(isScroll);
    };

    useEffect(() => {
        if (overviewData?.data?.length > 0 && assetOverviewLoadingStatus === 'success') {
            setFinalAssetOverviewLoadingStatus('success');
            setFilteredAssetOverviewList(getFilteredList(overviewData));
        } else if (overviewData?.data?.length === 0 && assetOverviewLoadingStatus === 'success') {
            setFinalAssetOverviewLoadingStatus(null);
        } else {
            setFinalAssetOverviewLoadingStatus('loading');
        }
    }, [overviewData, assetOverviewLoadingStatus]);

    const renderAssets = () => {
        const filteredAssetOverviewList = getFilteredList(overviewData);
        return map(filteredAssetOverviewList, (item) => {
            return <Asset
                key={item.assetId}
                selectedAsset={selectedAsset}
                asset={item}
                onSelectedItem={handleSelectedItem}
                isHorizontalScrollActive={isHorizontalScrollActive}
            />;
        });
    };

    return <ScrollableList
        role='scrollable-list'
        noDataLabel={translate('ABB.Powertrain.Frontend.conditionIndicesOverviewNoItems')}
        className={` asset overview ${withVisibleFilter ? 'with-filter' : ''}`}
        assetNumber={assetNumber}
        loadingStatus={finalAssetOverviewLoadingStatus}
        hasMore={hasMore}
        pageSize={pageSize}
        loadMore={() => getAssetConditionIndicesRequest(formatConditionIndexOverviewRequest(
            pageNumber,
            selectedFilters,
            searchBoxFilter,
            conditionFilter,
            assetTypeFilter
        ))}
        renderList={renderAssets}
        selectedAsset={selectedAsset}
        hasData={filteredAssetOverviewList}
        asset={selectedAsset}
        onReset={handleReset}
        onHorizontalScroll={onHorizontalScrollHandler}
    />;
};

AssetOverviewComponent.propTypes = {
    model: PropTypes.shape({
        pageNumber: PropTypes.number,
        assetOverviewLoadingStatus: PropTypes.string,
        assetOverviewList: PropTypes.array,
        hasMore: PropTypes.bool
    }),
    actions: PropTypes.shape({
        getAssetConditionIndicesRequest: PropTypes.func
    }),
    assetNumber: PropTypes.number,
    selectedFilters: PropTypes.object,
    searchBoxFilter: PropTypes.string,
    conditionFilter: PropTypes.array,
    assetTypeFilter: PropTypes.string,
    withVisibleFilter: PropTypes.bool
};

export default AssetOverviewComponent;
