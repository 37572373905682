export const actionTypes = {
    SET_ACTIVE_TAB: 'crossAssetAnalysis/SET_ACTIVE_TAB',
    SET_DATE_SELECTION: 'overview/SET_DATE_SELECTION',
    RESET_CHART: 'crossAssetAnalysis/RESET_CHART',
    CLEAR_STORE: 'crossAssetAnalysis/CLEAR_STORE',
    SET_SELECTED_ASSET: 'crossAssetAnalysis/SET_SELECTED_ASSET',
    GET_ASSET_DETAILS: 'crossAssetAnalysis/GET_ASSET_DETAILS',
    GET_ASSET_CAPABILITY: 'crossAssetAnalysis/GET_ASSET_CAPABILITY',
    SET_CHART_DATA_LOADING_STATUS: 'crossAssetAnalysis/SET_CHART_DATA_LOADING_STATUS',
    LOAD_KPI_DATA: 'crossAssetAnalysis/LOAD_KPI_DATA',
    REMOVE_SELECTED_KPI: 'crossAssetAnalysis/REMOVE_SELECTED_KPI',
    REMOVE_SELECTED_KPI_CHKBOX: 'crossAssetAnalysis/REMOVE_SELECTED_KPI_CHKBOX',
    SET_ZOOMED: 'crossAssetAnalysis/SET_ZOOMED'
};

export const opButtons = {
    trend: 'Trend',
    scatterPlot: 'ScatterPlot',
    histogram: 'Histogram',
};

export const plotTypeOptions = {
    trend: 'Trend',
    scatterPlot: 'Scatter',
    histogram: 'Histogram',
};

export const CHART_MAX_KPI_LIMIT = 6;

