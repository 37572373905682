import { createSelector } from 'reselect';
import { dateKind } from '../../../constants';


const getSelectedEvent = ({ eventLog }) => {
	return eventLog.selectedEvent;
};

const getEventLogs = ({ eventLog }) => {
	return eventLog.eventLogs;
};

const getPostCommentStatus = ({ selfFaultEvaluation }) => {
	return selfFaultEvaluation.postComment.loadingStatus;
};

const getGetCommentsStatus = ({ selfFaultEvaluation }) => {
	return selfFaultEvaluation.comments.loadingStatus;
};

const getDeleteCommentStatus = ({ selfFaultEvaluation }) => {
	return selfFaultEvaluation.deleteComment.loadingStatus;
};

const getComments = ({ selfFaultEvaluation }) => {
	return selfFaultEvaluation.comments?.data || [];
};

const getShowUtcDates = ({ eventLog }) => {
	return eventLog.dateKind === dateKind.UTC;
};

const getUser = ({ overview }) => {
	return overview.user?.data;
};

export const getModel = createSelector([
	getSelectedEvent,
	getEventLogs,
	getPostCommentStatus,
	getGetCommentsStatus,
	getComments,
	getShowUtcDates,
	getDeleteCommentStatus,
	getUser
], (
	selectedEvent,
	eventLogs,
	postCommentStatus,
	getCommentStatus,
	comments,
	showUtcDates,
	deleteCommentStatus,
	user
) => {
	return {
		selectedEvent,
		eventLogs,
		postCommentStatus,
		getCommentStatus,
		comments,
		showUtcDates,
		deleteCommentStatus,
		user
	};
});
