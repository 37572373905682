import { actionTypes } from './constants';
import {
    removeSelectedTrendKpiData,
    addSelectKpiData
} from './helpers';
import { removeSelectedIndex } from '../helpers';
import { getActionWithDefaultData } from 'helpers/actionHelper';
import { mapTrendKpiListV2 } from '../../../../commonTrendHelpers';

const initialState = {
    selectedTrendsData: [],
    trendKpis: {
        data: [],
        loadingStatus: null
    },
    trend: {
        data: [],
        loadingStatus: null,
    },
    trendChartZoom: {
        isZoomed: false,
        from: null,
        to: null
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_SELECTED_TREND_KPI_DATA:
        case actionTypes.RELOAD_ALL_SELECTED_TREND_KPI_DATA:
            return {
                ...state,
                selectedTrendsData: addSelectKpiData([...state.selectedTrendsData], action.newKpiData),
                trendChartZoom: action.trendChartZoom
            };
        case actionTypes.REMOVE_SELECTED_TREND_KPI_DATA: {
            const newSelected = removeSelectedTrendKpiData([...state.selectedTrendsData], action.selectedKpi);
            return {
                ...state,
                selectedTrendsData: newSelected,
            };
        }

        case actionTypes.REMOVE_SELECTED_TREND_LEGEND: {
            const newSelected = removeSelectedIndex([...state.selectedTrendsData], action.signalIndex);
            return {
                ...state,
                selectedTrendsData: newSelected,
            };

        }

        case actionTypes.GET_TREND_KPIS:
            return {
                ...state,
                trendKpis: {
                    ...state.trendKpis,
                    data: mapTrendKpiListV2(action.data)
                }
            };
        case actionTypes.GET_TREND_DATA:
            return {
                ...state,
                trend: getActionWithDefaultData(action, initialState.trend)
            };
        case actionTypes.SET_ZOOMED:
            return {
                ...state,
                trendChartZoom: {
                    isZoomed: action.isTrendChartZoomed,
                    from: action.from,
                    to: action.to
                }
            };
        case actionTypes.CLEAR_TREND_SIGNAL_STORE:
            return initialState;
        default:
            return state;
    }
};
