import { createSelector } from 'reselect';

const getTrendKPIsList = createSelector([
    ({ eventDetailsTrendSignal }) => eventDetailsTrendSignal.trendKpis?.data,
], (
    trendKPIs,
) => {
    const mergedTrendKpiList = [];
    trendKPIs?.forEach((kpi) => {
        const kpiObj = kpi?.data;
        const keys = Object.keys(kpiObj);
        keys.forEach(key => {
            const newObj = kpiObj[key];
            mergedTrendKpiList.push(newObj);
        });
    });
    return mergedTrendKpiList;
});

const getTrendKPIsLoadingStatus = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.trendKpis?.loadingStatus;
};
const getTrendDataLoadingStatus = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.trend?.loadingStatus;
};

const getSelectedTrendsData = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.selectedTrendsData;
};

export const getModel = createSelector([
    getTrendKPIsLoadingStatus,
    getTrendDataLoadingStatus,
    getTrendKPIsList,
    getSelectedTrendsData
], (
    trendKPIsLoadingStatus,
    trendDataLoadingStatus,
    trendKPIsList,
    selectedTrendsData

) => {
    return {
        trendKPIsLoadingStatus,
        trendDataLoadingStatus,
        trendKPIsList,
        selectedTrendsData
    };
});
