import { actionTypes } from './constants';
import { EventLogsApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { getApiActionCreator } from 'helpers/actionHelper';
import { orderBy } from 'lodash';
import { setSelectedEvent } from '../../actions';


const postCommentAction = getApiActionCreator(actionTypes.POST_EVENT_COMMENT);
const getCommentsAction = getApiActionCreator(actionTypes.GET_EVENT_COMMENTS);
const closeEventLogAction = getApiActionCreator(actionTypes.CLOSE_EVENT_LOG);
const updateEventLogAction = getApiActionCreator(actionTypes.UPDATE_EVENT_LOGS);

export const getComments = (event) => {
    return dispatch => {
        dispatch(getCommentsAction(apiCallStatus.LOADING, []));
        EventLogsApi.getEventComments(event.eventId,).then((comments) => {
            let sortedComments = comments || [];
            sortedComments = orderBy(sortedComments, report => new Date(report.commentTimeStamp), 'desc');
            dispatch(getCommentsAction(apiCallStatus.SUCCESS, sortedComments));
            logger.debug('Event comments successfully loaded');
        }).catch((err) => {
            dispatch(getCommentsAction(apiCallStatus.ERROR, null, err));
        });
    };
};

export const postComment = (event, comment, authorEmail) => {
    return dispatch => {
        dispatch(postCommentAction(apiCallStatus.LOADING));
        EventLogsApi.postEventComment({
            eventIds: [event.eventId],
            comment,
            authorEmail
        }).then(() => {
            dispatch(postCommentAction(apiCallStatus.SUCCESS));
            dispatch(getComments(event));
        }).catch((err) => {
            dispatch(postCommentAction(apiCallStatus.ERROR, null, err));
        });
    };
};

export const closeEventLog = (event, comment, authorEmail, closingReasonCode) => {
    return (dispatch) => {
        dispatch(closeEventLogAction(apiCallStatus.LOADING));
        EventLogsApi.postEventComment({
            eventIds: [event.eventId],
            comment,
            authorEmail,
            closingReasonCode
        })
            .then(() => {
                dispatch(updateEventLogAction(apiCallStatus.SUCCESS, { eventId: event.eventId, closingReasonCode }));
                dispatch(closeEventLogAction(apiCallStatus.SUCCESS));
                dispatch(setSelectedEvent(null));
            }).catch((err) => {
                dispatch(closeEventLogAction(apiCallStatus.ERROR, null, err));
                dispatch(setSelectedEvent(null));
            });
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};
