import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DownloadMeasurementsComponent from './DownloadMeasurementsComponent';
import { getModel } from './selectors';
import * as actions from './actions';

const DownloadMeasurementsContainer = (props) => {

	const { actions } = props;

	useEffect(() => {
		return () => actions.clearStore();
	}, []);

	return <DownloadMeasurementsComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return { model };
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({ ...actions }, dispatch)
	};
};

const DownloadMeasurementsContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(DownloadMeasurementsContainer);

export default DownloadMeasurementsContainerConn;
