import React from 'react';
import { connect } from 'react-redux';
import { getModel } from './selectors';
import PowertrainAssetDetailsComponent from './PowertrainAssetDetailsComponent';

const PowertrainAssetDetailsContainer = (props) => {
	return <PowertrainAssetDetailsComponent {...props}/>;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return { model };
};

const PowertrainAssetDetailsContainerConn = connect(
	mapStateToProps
)(PowertrainAssetDetailsContainer);

export default PowertrainAssetDetailsContainerConn;
