import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import { MAX_TREND_SIGNALS } from '../constants';
import { setColor } from '../commonTrendHelpers';


const hasKpi = (kpiList, kpi) => {
    const flatKPIList = flatMap(kpiList, (kpiGroup) => kpiGroup.measurementTypes);

    return !!find(flatKPIList, (kpifomList) => {
        return kpifomList.measurementTypeIdentifier === kpi;
    });
};

export const isAssetCompatibleWithSelectedKPIs = (asset, selectedKPIs) => {
    return selectedKPIs.length && selectedKPIs[0]?.assetTypeID === asset?.componentType?.typeID ? true : false;
};

export const isAssetCompatibleWithSelectedTrendKPIs = (asset, assetTypeID) => {
    return assetTypeID && assetTypeID === asset?.componentType?.typeID ? true : false;
};

export const getCommonTrendKPIs = (newTrendKPIs, selectedTrendKPIs) => {
    const commonTrendKPIs = [];

    for (const selectedKPI of selectedTrendKPIs) {
        hasKpi(newTrendKPIs, selectedKPI) && commonTrendKPIs.push(selectedKPI);
    }
    return commonTrendKPIs;
};

export const getAvailableKPI = (newKPIs, selectedKPI) => {
    return find(newKPIs, newKPI => newKPI.measurementTypeIdentifier === selectedKPI?.measurementTypeIdentifier) ? selectedKPI : null;
};

export const addKPISelection = (selectedKPIs, newKPI) => {
    selectedKPIs.push(newKPI);
    if (selectedKPIs.length > MAX_TREND_SIGNALS) {
        selectedKPIs.shift();
    }
    return selectedKPIs;
};

export const addSelectOpTrendKPIData = (selectedTrendsData, newSelectedTrendKpiArray) => {

    if (!selectedTrendsData?.length && newSelectedTrendKpiArray?.length > 1) {
        newSelectedTrendKpiArray.forEach((newSelectedTrend) => {
            selectedTrendsData.push({
                kpiIdentifier: newSelectedTrend.config.measurementTypeIdentifier,
                values: newSelectedTrend.values
            });
        });
        return setColor(selectedTrendsData);
    }

    const newSelectedTrend = newSelectedTrendKpiArray && newSelectedTrendKpiArray.length ? newSelectedTrendKpiArray[0] : [];

    if (!find(selectedTrendsData, (selectedTrend) => selectedTrend?.kpiIdentifier === newSelectedTrend?.config?.measurementTypeIdentifier)) {
        selectedTrendsData.push({
            kpiIdentifier: newSelectedTrend?.config?.measurementTypeIdentifier,
            values: newSelectedTrend?.values
        });
        if (selectedTrendsData.length > MAX_TREND_SIGNALS) {
            selectedTrendsData.shift();
        }
    }

    selectedTrendsData = map(selectedTrendsData, (selectedTrend) => {
        const found = find(newSelectedTrendKpiArray, (newSelected) => newSelected?.config?.measurementTypeIdentifier === selectedTrend?.kpiIdentifier);
        if (found) {
            return {
                color: selectedTrend?.color,
                kpiIdentifier: found?.config?.measurementTypeIdentifier,
                values: found?.values
            };
        }
        else {
            return selectedTrend;
        }
    });

    selectedTrendsData?.length && setColor(selectedTrendsData);

    return selectedTrendsData;
};

export const removeSelectedOpTrendKpiData = (selectedTrendDataArrays, unselectedKPI) => {
    const foundIndex = findIndex(selectedTrendDataArrays, (trendDataArray) => trendDataArray.kpiIdentifier === unselectedKPI.measurementTypeIdentifier);
    foundIndex > -1 && selectedTrendDataArrays.splice(foundIndex, 1);

    return selectedTrendDataArrays;
};

export const removeSelectedTrendKpi = (selectedTrends, unselectedKPI) => {
    const foundIndex = findIndex(selectedTrends, (kpi) => kpi === unselectedKPI.measurementTypeIdentifier);
    foundIndex > -1 && selectedTrends.splice(foundIndex, 1);

    return selectedTrends;
};
