import { kpiTypes } from 'helpers/constants';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { paramListForChartDataRequest } from '../helpers';
import { Histogram } from './Histogram';
import { ScatterPlot } from './ScatterPlot';
import { Trend } from './Trend';

const GraphComponent = ({ model, actions }) => {
    const [isZoomed, setIsZoomed] = useState(false);

    const onKPISelection = () => {
        if (
            model.selectedKpi &&
            model.dateSelection.to &&
            model.dateSelection.from &&
            model.asset
        ) {
            actions.getChartData(model.selectedKpi, model.asset, model.selectedKPIType);
        }
    };

    useEffect(() => {
        onKPISelection();
    }, [model.selectedKpi]);

    useEffect(() => {
        setIsZoomed(false);
        if (model.chartData && model.chartData.length) {
            const list = paramListForChartDataRequest(model.chartData, model.selectedKPIType);
            actions.getChartDataForNewDate(list, false, model.dateSelection.from, model.dateSelection.to, model.selectedKPIType);
        }
    }, [model.dateSelection]);


    if (model.selectedKPIType) {
        switch (model.selectedKPIType) {
            case kpiTypes.HISTOGRAM:
                return <Histogram
                    asset={model.asset}
                    chartData={model.chartData}
                    removeKPIData={actions.removeKPIData}
                    chartLoadingStatus={model.chartLoadingStatus}
                    isZoomed={isZoomed}
                    setIsZoomed={setIsZoomed}
                />;
            case kpiTypes.SCATTER:
                return <ScatterPlot
                    asset={model.asset}
                    chartData={model.chartData}
                    removeKPIData={actions.removeKPIData}
                    chartLoadingStatus={model.chartLoadingStatus}
                    isZoomed={isZoomed}
                    setIsZoomed={setIsZoomed}
                />;
            default:
                return <Trend
                    asset={model.asset}
                    chartData={model.chartData}
                    selectedKPIType={model.selectedKPIType}
                    removeKPIData={actions.removeKPIData}
                    chartLoadingStatus={model.chartLoadingStatus}
                    setIsZoomed={actions.setIsZoomed}
                    zoomInToSelectedTrendKpiData={actions.zoomInToSelectedTrendKpiData}
                    resetChartData={actions.resetChartData}
                />;
        }
    }
};

GraphComponent.propTypes = {
    actions: PropTypes.shape({
        getChartData: PropTypes.func,
        getChartDataForNewDate: PropTypes.func,
        removeKPIData: PropTypes.func,
        setIsZoomed: PropTypes.func,
        zoomInToSelectedTrendKpiData: PropTypes.func,
        resetChartData: PropTypes.func
    }),
    model: PropTypes.shape({
        selectedKpi: PropTypes.object,
        dateSelection: PropTypes.shape({
            from: PropTypes.any,
            to: PropTypes.any
        }),
        asset: PropTypes.object,
        isZoomed: PropTypes.bool,
        chartData: PropTypes.array,
        selectedKPIType: PropTypes.number,
        chartLoadingStatus: PropTypes.string
    })
};

export default GraphComponent;
