import React from 'react';
import { map, indexOf, countBy, isNil, groupBy } from 'lodash';
import { apiCallStatus, allAssetTypes, newConditionIndices, LimitConditions } from 'helpers/constants';
import { toggleButtonOptions, pageSize, sortOrder } from './constants';
import localStorageHelper from 'helpers/localStorageHelper';
import Tippy from '@tippy.js/react';
import { translate } from 'helpers/translateHelper';
import {
    IconCondition,
} from 'svgIcons/MotionPortalIcons';
import { v4 as uuidv4 } from 'uuid';


export const getToggleButtonOptions = (translate) => [

    { label: translate('ABB.Powertrain.Frontend.powertrainsLabel'), value: toggleButtonOptions.POWERTRAINS },
    { label: translate('ABB.Powertrain.Frontend.assetGroupsLabel'), value: toggleButtonOptions.ASSETGROUPS },
    { label: translate('ABB.Powertrain.Frontend.assetsLabel'), value: toggleButtonOptions.ASSETS }
];

export const setFilterVisibilityToLocalStorage = (visibility) => {
    localStorageHelper.setItemForUser('assetPowertrainOverviewFilterVisibility', visibility);
};

export const getFilterVisibilityFromLocalStorage = () => {
    const localStorageExpandedStatus = localStorageHelper.getItemForUser('assetPowertrainOverviewFilterVisibility');
    return localStorageExpandedStatus === null ? true : localStorageHelper.getItemForUser('assetPowertrainOverviewFilterVisibility');
};

export const setSelectedConditions = (selectedConditions, newCondition) => {
    const isSelected = indexOf(selectedConditions, newCondition);
    if (isSelected >= 0) {
        selectedConditions.splice(isSelected, 1);
        return selectedConditions;
    }
    return [...selectedConditions, newCondition];
};

export const addNewPageToConditionIndicesOverviewList = (items, action) => {
    const data = action.loadingStatus !== apiCallStatus.LOADING &&
        action.data?.pageNumber === 1 ? [] : [...items.data];

    return action.loadingStatus !== apiCallStatus.SUCCESS ? {
        ...items,
        loadingStatus: action.loadingStatus
    } : {
        ...items,
        loadingStatus: action.loadingStatus,
        count: action.data.count,
        ...!isNil(action.data.assetCount) && { assetCount: action.data.assetCount },
        data: action.data && [...data, ...action.data?.items ? action.data.items : []],
        pageNumber: action.data.hasMore ? action.data?.pageNumber + 1 : action.data?.pageNumber,
        hasMore: action.data.hasMore
    };
};

export const getOverviewNumber = (
    selectedTab,
    assetNumber,
    assetGroupNumber,
    assetGroupAssetNumber,
    powertrainNumber,
    powertrainAssetNumber,
    translate
) => {
    switch (selectedTab) {
        case toggleButtonOptions.ASSETS: {
            return typeof assetNumber === 'number' &&
                `${assetNumber} ${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewAssetNumber')}`;
        }
        case toggleButtonOptions.ASSETGROUPS: {
            return typeof assetGroupNumber === 'number' &&
                `${assetGroupNumber} ${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewAssetGroupNumber')} | ${assetGroupAssetNumber} ${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewAssetNumber')} `;
        }
        case toggleButtonOptions.POWERTRAINS: {
            return typeof powertrainNumber === 'number' &&
                `${powertrainNumber} ${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewPowertrainNumber')} | ${powertrainAssetNumber} ${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewAssetNumber')} `;
        }
        default: return '';
    }
};

export const isTabContentLoading = (
    selectedTab,
    assetOverviewLoadingStatus,
    assetGroupOverviewLoadingStatus,
    powertrainOverviewLoadingStatus
) => {
    switch (selectedTab) {
        case toggleButtonOptions.ASSETS: {
            return assetOverviewLoadingStatus === apiCallStatus.LOADING;
        }
        case toggleButtonOptions.ASSETGROUPS: {
            return assetGroupOverviewLoadingStatus === apiCallStatus.LOADING;
        }
        case toggleButtonOptions.POWERTRAINS: {
            return powertrainOverviewLoadingStatus === apiCallStatus.LOADING;
        }
        default: return false;
    }
};

export const formatConditionIndexOverviewRequest = (
    pageNumber,
    selectedFilters,
    filter,
    conditionFilter,
    assetTypeFilter = ''
) => {
    return {
        pageNumber,
        pageSize,
        organizationIds: selectedFilters?.organizations?.length && selectedFilters?.organizations.map(organization => {
            return organization.organizationID;
        }) || [],
        siteIds: selectedFilters?.sitesChecked && selectedFilters?.sites?.length &&
            selectedFilters?.sites.map(site => {
                return site.siteID;
            }) || [],
        assetGroupIds: selectedFilters?.assetGroupChecked && selectedFilters?.assetGroup &&
            [selectedFilters.assetGroup.id] || [],
        filter,
        conditionFilter,
        assetTypeFilter: assetTypeFilter === allAssetTypes ? '' : assetTypeFilter,
        sortingCriteria: ['Threshold', 'Trend', 'Name'],
        sortingOrder: 'asc'
    };
};

const renderKPI = (kpi) => {
    return <div key={kpi.key} className='kpi-list-item'>
        <IconCondition condition={newConditionIndices[kpi.condition]?.iconID} />
        <p>{translate(kpi?.nameTextKey) || kpi?.name}</p>
    </div>;
};

const renderKPIList = (kpiList) => {
    return <Tippy
        content={<div>{<div className='kpi-list'>
            {map(kpiList, renderKPI)}
        </div>}</div>}
        interactive={true}
        interactiveBorder={10}
        animation='scale'
        theme='light-border'
        trigger='click'
        maxWidth={450}
        placement='right'
        offset='-85,0'>
        <div className='nr-of-kpis'>{`${kpiList?.length} ${kpiList?.length > 1 ? `${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewConditionIndicesColumnTitle')}` : `${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewConditionIndexColumnTitle')}`}`}</div>
    </Tippy>;
};

export const renderThreshold = (item) => {
    const groupOfKPIs = groupBy(item?.threshold?.KPIs, 'condition');
    const renderThresholdConditions = sortOrder
        .filter(condition => groupOfKPIs[condition])
        .map(condition => {
            const kpis = groupOfKPIs[condition];
            return (
                <div key={condition} className='render-condition'>
                    <IconCondition condition={newConditionIndices[condition]?.iconID} />
                    <div className='kpis'>
                        {renderKPIList(kpis)}
                    </div>
                </div>
            );
        });
    return <div div className='condition-index threshold cell'>
        <div>{renderThresholdConditions.length ? renderThresholdConditions : item?.threshold?.condition}</div>
    </div>;
};

export const renderTrend = (item) => {
    const groupOfKPIs = groupBy(item?.trend?.KPIs, 'condition');
    const renderTrendConditions = sortOrder
        .filter(condition => groupOfKPIs[condition])
        .map(condition => {
            const kpis = groupOfKPIs[condition];
            return (
                <div key={condition} className='render-condition'>
                    <IconCondition condition={newConditionIndices[condition]?.iconID} />
                    <div className='kpis'>
                        {renderKPIList(kpis)}
                    </div>
                </div>
            );
        });
    return <div div className='condition-index trend cell'>
        <div>{renderTrendConditions.length ? renderTrendConditions : item?.trend?.condition}</div>
    </div>;
};

const renderAssetNrByCondition = (assets, conditionType) => {
    const conditions = map(assets, (asset) => { return asset[conditionType]; });
    const groupedByCondition = countBy(conditions, 'condition');
    return <div className='nr-by-condition-index'>
        {groupedByCondition?.Good > 0 && <div>
            {`${translate('ABB.Powertrain.Frontend.' + newConditionIndices['Good']?.translationKey + 'Label')}: ${groupedByCondition.Good}`}
        </div>}
        {groupedByCondition?.Poor > 0 && <div>
            {`${translate('ABB.Powertrain.Frontend.' + newConditionIndices['Poor']?.translationKey + 'Label')}: ${groupedByCondition.Poor}`}
        </div>}
        {groupedByCondition?.Tolerable > 0 && <div>
            {`${translate('ABB.Powertrain.Frontend.' + newConditionIndices['Tolerable']?.translationKey + 'Label')}: ${groupedByCondition.Tolerable}`}
        </div>}
        {groupedByCondition?.Unknown > 0 && <div>
            {`${translate('ABB.Powertrain.Frontend.' + newConditionIndices['Unknown']?.translationKey + 'Label')}: ${groupedByCondition.Unknown}`}
        </div>}
    </div>;
};

const renderCondition = (condition, count) => {
    return count > 0 &&
        <div className='signals-status' key={uuidv4()}>
            <IconCondition condition={condition.iconID} />
            <div>{count} {count === 1 ? 'asset' : 'assets'}</div>
        </div>
        ;
};

export const renderConditionIndicesComponent = (item) => {
    const { goodAssets, poorAssets, tolerableAssets, unknownAssets } = item?.conditionIndexSummary || {};

    const conditions = [
        { condition: newConditionIndices.Good, count: goodAssets },
        { condition: newConditionIndices.Poor, count: poorAssets },
        { condition: newConditionIndices.Tolerable, count: tolerableAssets },
        { condition: newConditionIndices.Unknown, count: unknownAssets }
    ];

    return (
        <div className='condition-index condition-indices powertrain__cell'>
            {conditions.map(({ condition, count }) => renderCondition(condition, count))}
        </div>
    );
};


export const renderLimitsComponent = (item) => {
    const conditions = map(item.assets, asset => asset.limits);
    const groupedByCondition = countBy(conditions, 'condition');

    const conditionTypes = [
        { name: 'Good', iconID: newConditionIndices.Good?.iconID, count: groupedByCondition.Good },
        { name: 'Poor', iconID: newConditionIndices.Poor?.iconID, count: groupedByCondition.Poor },
        { name: 'Tolerable', iconID: newConditionIndices.Tolerable?.iconID, count: groupedByCondition.Tolerable },
        { name: 'Unknown', iconID: newConditionIndices.Unknown?.iconID, count: groupedByCondition.Unknown },
        { name: 'Unsupported', iconID: newConditionIndices.Unsupported?.iconID, count: groupedByCondition.Unsupported },
    ];

    return (
        <div className='condition-index condition-indices powertrain__cell'>
            {conditionTypes.map(({ name, iconID, count }) => renderCondition({ name, iconID }, count))}
        </div>
    );
};

const renderAssetNrByLimits = (asset, conditionType) => {
    const conditions = asset[conditionType];
    const groupedByCondition = countBy(conditions, 'condition');
    return <div className='nr-by-condition-index'>
        {groupedByCondition?.Alert > 0 && <div>
            {`${translate('ABB.Powertrain.Frontend.' + LimitConditions['Alert']?.translationKey + 'Label')}: ${groupedByCondition.Alert}`}
        </div>}
        {groupedByCondition?.Good > 0 && <div>
            {`${translate('ABB.Powertrain.Frontend.' + LimitConditions['Good']?.translationKey + 'Label')}: ${groupedByCondition.Good}`}
        </div>}
        {groupedByCondition?.Alarm > 0 && <div>
            {`${translate('ABB.Powertrain.Frontend.' + LimitConditions['Alarm']?.translationKey + 'Label')}: ${groupedByCondition.Alarm}`}
        </div>}
        {groupedByCondition?.Unsupported > 0 && <div>
            {`${translate('ABB.Powertrain.Frontend.' + LimitConditions['Unsupported']?.translationKey + 'Label')}: ${groupedByCondition.Unsupported}`}
        </div>}
        {groupedByCondition?.Unknown > 0 && <div>
            {`${translate('ABB.Powertrain.Frontend.' + LimitConditions['Unknown']?.translationKey + 'Label')}: ${groupedByCondition.Unknown}`}
        </div>}
    </div>;
};

export const renderAssetLimitsSummary = (item) => {
    return <div className='condition-index limits powertrain__cell'>
        <div className='kpis'>
            {item?.limits?.Limits?.length ? renderAssetNrByLimits(item?.limits, 'Limits') : item?.limits?.condition || '-'}
        </div>
    </div>;
};

export const renderThresholdSummary = (item) => {
    return <div className='condition-index threshold powertrain__cell'>
        <IconCondition condition={newConditionIndices[item?.thresholdCondition]?.iconID} />
        <div className='kpis'>
            {renderAssetNrByCondition(item?.assets, 'threshold')}
        </div>
    </div>;
};

export const renderTrendSummary = (item) => {
    return <div className='condition-index trend powertrain__cell'>
        <IconCondition condition={newConditionIndices[item?.trendCondition]?.iconID} />
        <div className='kpis'>
            {renderAssetNrByCondition(item?.assets, 'trend')}
        </div>
    </div>;
};
