import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from './selectors';
import * as sensorActions from '../sensorActions';
import { isSmSeAsset, getSensorTypeValueFromCode } from '../helpers';
import OperationalParametersComponent from './OperationalParametersComponent';
import { clearDownloadMeasurementStore } from '../actions';

const OperationalParametersContainer = (props) => {
    const { model, actions } = props;

    useEffect(() => {
        if (isSmSeAsset(props.asset) &&
            model.assetDetails?.details &&
            model.assetDetails?.details?.sensorIdentifier !== model.sensorProperties?.data?.sensorIdentifier &&
            props.asset?.isMonitored) {
            //actions.getSensorProperties(model.assetDetails.details.sensorIdentifier, model.assetDetails.details.sensorTypeID);
            actions.getSensorProperties(model.assetDetails.details.sensorIdentifier, getSensorTypeValueFromCode(model.assetDetails.details.sensorType));
            actions.getGatewayInfo(model.assetDetails.details.assetIdentifier);
        }
    }, [model.assetDetails?.details]);

    return <OperationalParametersComponent {...props} />;
};


const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...sensorActions, clearDownloadMeasurementStore }, dispatch)
    };
};

const OperationalParametersContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationalParametersContainer);


export default OperationalParametersContainerConn;
