export const PICTURE_TYPES = {
	'AssetImage': 'Asset', //1
	'NameplateImage': 'Nameplate', //2
	'Attachment': 'Attachment' //3
};

export const PICTURE_SIZES = {
	'Original': 'Original', //1
	'Reduced': 'ReducedSize', //2
	'Thumbnail': 'Thumbnail' //3
};

export const GET_ASSET_PICTURES = 'assetNameplate/GET_ASSET_PICTURES';
export const UPLOAD_ASSET_PICTURES = 'assetNameplate/UPLOAD_ASSET_PICTURES';
export const UPLOAD_ASSET_PICTURE_REQUEST = 'assetNameplate/UPLOAD_ASSET_PICTURE_REQUEST';
export const DELETE_ASSET_PICTURE = 'assetNameplate/DELETE_ASSET_PICTURE';
export const SHOW_CONFIRM_DELETE_ASSET_PICTURE = 'assetNameplate/SHOW_CONFIRM_DELETE_ASSET_PICTURE';

export const MAX_ALLOWED_PICTURE_SIZE = 10 * 1024 * 1024;
export const ALLOWED_PICTURE_FORMAT = '.png, .jpg, .jpeg';
export const ASSET_PICTURE_CONTAINER_NAME = 'asset-pictures';
export const CLEAR_ASSET_PICTURES = 'assetNameplate/CLEAR_ASSET_PICTURES';
