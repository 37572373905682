import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from './selectors';
import * as actions from './actions';
import AssetOverviewDetailsComponent from './AssetOverviewDetailsComponent';

const AssetOverviewDetailsContainer = (props) => {
    return <AssetOverviewDetailsComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};


const AssetOverviewDetailsContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(AssetOverviewDetailsContainer);

export default AssetOverviewDetailsContainerConn;
