import { NEWCAPABILITIES, apiCallStatus } from 'helpers/constants';
import { actionTypes } from './constants';
import { SubscriptionInfoApi, CapabilityApi, AssetDetailsApi } from 'api/index';
import { getApiActionCreator } from 'helpers/actionHelper';
export { getAssetDetails } from '../../PowertrainAssetDetails/DetailedInformation/actions';

const getContractsAction = getApiActionCreator(actionTypes.ACTIVE_SUB_DATA);
const getNewCapabilitiesAction = getApiActionCreator(actionTypes.GET_ASSET_NEWCAPABILITIES);
const getAssetOverviewAction = getApiActionCreator(actionTypes.GET_ASSET_OVERVIEW);

export const getSubscriptionInfo = (assetIdParams) => {
    return (dispatch) => {
        dispatch(getContractsAction(apiCallStatus.LOADING));
        SubscriptionInfoApi.getSubscriptionList(assetIdParams)
            .then((data) => {
                dispatch(getContractsAction(apiCallStatus.SUCCESS, data));
            }, (err) => {
                dispatch(getContractsAction(apiCallStatus.ERROR, null, err));
            });
    };

};

export const getApiAssetCapability = (asset) => {
    return (dispatch, getState) => {
        const organizationId = getState().generalFilter.selectedFilters.organizations[0].organizationID;
        const capabilityCodes = Object.values(NEWCAPABILITIES);
        dispatch(getNewCapabilitiesAction(apiCallStatus.LOADING));
        return CapabilityApi.getCapabilities(organizationId, [asset.id], capabilityCodes)
            .then((capabilities) => {
                dispatch(getNewCapabilitiesAction(apiCallStatus.SUCCESS, capabilities));
            }, (err) => {
                dispatch(getNewCapabilitiesAction(apiCallStatus.ERROR, [], err));
            });
    };
};

export const getAssetOverview = (assetID) => {
    return (dispatch) => {
        dispatch(getAssetOverviewAction(apiCallStatus.LOADING));
        AssetDetailsApi.getAssetOverview(assetID)
            .then(data => {
                dispatch(getAssetOverviewAction(apiCallStatus.SUCCESS, data));
            }, (err) => {
                dispatch(getAssetOverviewAction(apiCallStatus.ERROR, null, err));
            });
    };
};
