import { createSelector } from 'reselect';
import { getSelectedOrganization } from 'helpers/selectors';


const getEventLogs = ({ eventLog }) => {
    return eventLog.eventLogs?.events || [];
};

const getFilters = ({ eventLog, overview }) => {
    return {
        ...eventLog.filters,
        dateKind: eventLog.dateKind,
        dateSelection: overview.dateSelection
    };
};

const getEventLogLoadingStatus = ({ eventLog }) => {
    return eventLog.eventLogLoadingStatus;
};

const getEventClosingLoadingStatus = ({ eventLog }) => {
    return eventLog.eventClosingLoadingStatus;
};

const getIsTruncated = ({ eventLog }) => {
    return eventLog.isTruncated;
};

const getSelectedAssets = ({ eventLog }) => {
    return eventLog.selectedAssets;
};

const getUser = ({ overview }) => {
    return overview.user?.data;
};

export const getModel = createSelector([
    getEventLogs,
    getFilters,
    getEventLogLoadingStatus,
    getEventClosingLoadingStatus,
    getIsTruncated,
    getSelectedOrganization,
    getSelectedAssets,
    getUser
],
    (
        eventLogs,
        filters,
        eventLogLoadingStatus,
        eventClosingLoadingStatus,
        isTruncated,
        organizationID,
        selectedAssets,
        user
    ) => {
        return {
            eventLogs,
            filters,
            eventLogLoadingStatus,
            eventClosingLoadingStatus,
            isTruncated,
            organizationID,
            selectedAssets,
            user
        };
    });
