import { actionTypes } from './constants';
import {
    updateSelectedItems,
    updateAllSelection
} from './helpers';


const initialState = {
    notificationChannels: {
        data: null,
        error: null,
        loadingStatus: null
    },
    mutedAssets: {
        data: null,
        error: null,
        loadingStatus: null
    },
    selectedAssets: [],
    selectedNotificationGroups: [],
    assetMuteSettings: {
        data: null,
        loadingStatus: null
    },
    updateAssetMuteSettingsLoadingStatus: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_NOTIFICATION_SETTINGS: {
            return {
                ...state,
                notificationChannels: {
                    data: action.data,
                    error: action.error,
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.GET_MUTED_ASSETS: {
            return {
                ...state,
                selectedAssets: [],
                mutedAssets: {
                    data: action.data,
                    error: action.error,
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.SELECT_NOTIFICATION_GROUP: {
            return {
                ...state,
                selectedNotificationGroups: updateSelectedItems(
                    state.selectedNotificationGroups,
                    action.selectedGroup
                )
            };
        }
        case actionTypes.SELECT_MUTED_ASSET: {
            return {
                ...state,
                selectedAssets: action.assetId ?
                    updateSelectedItems(state.selectedAssets, action.assetId) :
                    updateAllSelection(state.selectedAssets, state.mutedAssets?.data)
            };
        }
        case actionTypes.GET_ASSET_MUTE_SETTINGS: {
            // SETTING muted AS FALSE DUE IF GETTING NULL FROM API RESPONSE, OW ITI'LL THROW 400 ERROR.
            const notificationGroups = action?.data?.notificationGroups?.map((grp) => {
                if (grp.muted === null) {
                    return {
                        ...grp,
                        muted: false
                    };
                }
                return grp;
            });
            return {
                ...state,
                assetMuteSettings: {
                    data: { ...action.data, notificationGroups },
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.SET_UPDATE_MUTE_SETTINGS_LOADING: {
            return {
                ...state,
                updateAssetMuteSettingsLoadingStatus: action.loadingStatus
            };
        }
        case actionTypes.CLEAR_STORE: {
            return initialState;
        }
        default:
            return state;
    }
}
