import find from 'lodash/find';
import {
    apiCallStatus,
    dataSources,
    contractInfoMessages,
    CAPABILITIES,
    kpiTypes,
    ASSET_TYPES_MS
} from 'helpers/constants';
import localStorageHelper from 'helpers/localStorageHelper';

export default class CapabilityHelper {

    constructor(capability, asset) {
        if (capability && asset) {
            const capabilityData = capability.data;
            const capabilities = capabilityData?.subscription?.capabilities;
            if (capabilities && asset.componentID === capabilityData.componentID) {
                this.componentAllCapabilities = capabilities;
                this.asset = asset;
            }
            this.isCapabilityLoading = capability.loadingStatus && capability.loadingStatus === apiCallStatus.LOADING;
            this.contractInfoMessage = capabilityData?.contractInfo?.message;
        }

    }

    hasCapability(capabilityToCheck) {
        let hasCapability = false;
        if (this.componentAllCapabilities) {
            const foundCapability = find(this.componentAllCapabilities, { key: capabilityToCheck });
            if (foundCapability) {
                hasCapability = foundCapability.value === true;
            }
        }
        return hasCapability;
    }

    hasEmptyAvailable() {
        return false;
    }

    hasCapabilityAvailable(capabilityToCheck) {
        let foundCap = false;
        if (this.componentAllCapabilities) {
            const foundCapability = find(this.componentAllCapabilities, { key: capabilityToCheck });
            if (foundCapability) {
                foundCap = true;
            }
        }
        return foundCap;
    }

    isLoading() {
        return this.isCapabilityLoading;
    }

    isSet() {
        return !!(this.componentAllCapabilities && this.asset);
    }

    hasNoVisibility() {
        return this.hasEmptyAvailable();
    }

    hasBulkUploadCapability() {
        return this.hasCapability(CAPABILITIES.bulkUpload);
    }

    hasBulkUploadCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.bulkUpload);
    }

    hasEditComponentCapability() {
        return this.hasCapability(CAPABILITIES.editComponent);
    }

    hasEditChkCapability() {
        return this.hasCapabilityAvailable(CAPABILITIES.editComponent);
    }

    hasParameterBackupCapability() {
        return this.hasCapability(CAPABILITIES.parameterBackup);
    }

    hasParameterBackupCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.parameterBackup);
    }

    hasChangedParameterListCapability() {
        return this.hasCapability(CAPABILITIES.changedParameterList);
    }

    hasChangedParameterListCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.changedParameterList);
    }

    hasUserLimitsCapability() {
        return this.hasCapability(CAPABILITIES.userLimits);
    }

    hasUserLimitsCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.userLimits);
    }

    hasConditionIndexCapability() {
        return this.hasCapability(CAPABILITIES.conditionIndex);
    }

    hasTrendCapability() {
        return this.hasCapability(CAPABILITIES.trend);
    }

    hasTrendCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.trend);
    }

    hasHistogramCapability() {
        return this.hasCapability(CAPABILITIES.histogram);
    }

    hasHistogramCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.histogram);
    }

    hasVibrationFFTCapability() {
        return this.hasCapability(CAPABILITIES.vibrationFFT);
    }

    hasScatterCapability() {
        return this.hasCapability(CAPABILITIES.scatter);
    }

    hasScatterCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.scatter);
    }

    hasEventSelfFaultEvaluationCapability() {
        return this.hasCapability(CAPABILITIES.eventSelfFaultEvaluation);
    }

    hasEventLoggerVisualizationCapability() {
        return this.hasCapability(CAPABILITIES.eventLoggerVisualization);
    }

    hasEventTrendSignalCapability() {
        return this.hasCapability(CAPABILITIES.eventTrendSignal);
    }

    hasEventLoggerDownloadCapability() {
        return this.hasCapability(CAPABILITIES.eventLoggerDownload);
    }

    hasEventListCapability() {
        return this.hasCapability(CAPABILITIES.eventList);
    }

    hasEventListCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.eventList);
    }

    hasAssistancePageCapability() {
        const hasCapability = this.hasEventSelfFaultEvaluationCapability() ||
            this.hasEventLoggerVisualizationCapability() ||
            this.hasEventTrendSignalCapability() ||
            this.hasEventLoggerDownloadCapability();
        return hasCapability;

    }

    hasEmailAlertCapability() {
        return this.hasCapability(CAPABILITIES.emailAlertDisconnectedDrive)
            || this.hasCapability(CAPABILITIES.emailAlertFaultsWarnings)
            || this.hasCapability(CAPABILITIES.emailAlertParameterChanged)
            || this.hasCapability(CAPABILITIES.emailAlertUserLimits);
    }

    hasEmailAlertCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.emailAlertDisconnectedDrive)
            || this.hasCapabilityAvailable(CAPABILITIES.emailAlertFaultsWarnings)
            || this.hasCapabilityAvailable(CAPABILITIES.emailAlertParameterChanged)
            || this.hasCapabilityAvailable(CAPABILITIES.emailAlertUserLimits);
    }

    hasLabelingCapability() {
        return this.hasCapability(CAPABILITIES.assetLabel);
    }

    hasNameplateGalleryCapability() {
        return this.hasCapability(CAPABILITIES.assetPicture);
    }

    isSmartSensor() {
        return this.asset?.componentType?.typeName !== ASSET_TYPES_MS.DRIVE;
    }

    hasAlertAlarmOffsetConfigCapability() {
        return this.hasCapability(CAPABILITIES.configureAssetHealth);
    }

    hasAlertAlarmCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.configureAssetHealth);
    }

    hasMeasurementExportCapability() {
        return this.hasCapability(CAPABILITIES.assetMeasurementExport);
    }

    hasMeasurementExportCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.assetMeasurementExport);
    }

    hasMeasurementGroupExportCapability() {
        return this.hasCapability(CAPABILITIES.assetGroupMeasurementExport);
    }

    hasCrossAssetAnalysisCapability() {
        return this.hasCapability(CAPABILITIES.comparison);
    }

    hasCBMCapability() {
        return this.hasCapability(CAPABILITIES.conditionBasedMaintenance);
    }

    hasPlotTypeCapability(plotType) {
        switch (plotType) {
            case kpiTypes.TREND: return this.hasCapability(CAPABILITIES.trend);
            case kpiTypes.SCATTER: return this.hasCapability(CAPABILITIES.scatter);
            case kpiTypes.HISTOGRAM: return this.hasCapability(CAPABILITIES.histogram);
            default: return false;
        }
    }

    hasLoadMeasurementOnDemandCapability() {
        return this.hasCapability(CAPABILITIES.loadMeasurementOnDemand);
    }

    hasCollectRawDataCapability() {
        return this.hasCapability(CAPABILITIES.collectRawData);
    }

    hasInstantReportCapability() {
        return this.hasCapability(CAPABILITIES.assetInstantReport) || this.hasCapability(CAPABILITIES.selfGeneratedReport);
    }

    hasInstantReportCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.assetInstantReport) || this.hasCapabilityAvailable(CAPABILITIES.selfGeneratedReport);
    }

    checkEventListMultipleAssetsCapability() {
        return this.hasCapability(CAPABILITIES.eventListMultipleAssets);
    }

    subscriptionStatus() {
        switch (this.contractInfoMessage) {
            case contractInfoMessages.noContract:
                return this.contractInfoMessage;
            case contractInfoMessages.noSubscription:
                return this.contractInfoMessage;
            case contractInfoMessages.trialPeriodExpired:
                return this.contractInfoMessage;
            case contractInfoMessages.contractExpired:
                return this.contractInfoMessage;
            default:
                return contractInfoMessages.activeSubscription;
        }
    }
}

export const roleBasedCapabilityHelper = {
    hasExtendedDateSelector() {
        const user = localStorageHelper.getItem('user');
        if (user) {
            return user.isSuperAdmin ||
                user.roles?.find(role => role.dataSourceType === dataSources.CMD4)?.userRoles[0]?.value;
        }
        return false;
    },

    hasMultiChart() {
        const user = localStorageHelper.getItem('user');
        if (user) {
            return user.roles?.find(role => role.dataSourceType === dataSources.CMD4)?.userRoles[0]?.value;
        }
        return false;
    },

    hasCMDAdminPortalMenu() {
        const user = localStorageHelper.getItem('user');
        if (user) {
            return user.roles?.find(role => role.dataSourceType === dataSources.CMD4)?.userRoles[0]?.value;
        }
        return false;
    },

    hasExpertReportCapability() {
        const user = localStorageHelper.getItem('user');
        if (user) {
            return !!(user.isSuperAdmin ||
                user.roles?.find(role => role.dataSourceType === dataSources.CMD4)?.userRoles[0]?.value);
        }
        return false;
    },

    hasCBMCapability() {
        const user = localStorageHelper.getItem('user');
        if (user) {
            return user.isSuperAdmin ||
                user.roles?.find(role => role.dataSourceType === dataSources.CMD4)?.userRoles[0]?.value;
        }
        return false;
    },

    hasAssetSearchCapability() {
        const user = localStorageHelper.getItem('user');
        if (user) {
            return user.isSuperAdmin ||
                user.roles?.find(role => role.dataSourceType === dataSources.CMD4)?.userRoles[0]?.value;
        }
        return false;
    },

    hasEventCommentDeleteCapability() {
        const user = localStorageHelper.getItem('user');
        if (user) {
            return user.isSuperAdmin ||
                user.roles?.find(role => role.dataSourceType === dataSources.CMD4)?.userRoles[0]?.value;
        }
        return false;
    }
};
