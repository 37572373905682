import { toLocaleDateTimeString } from 'helpers/dateHelper';
import { map, sortBy, filter } from 'lodash';
import { roleBasedCapabilityHelper } from 'helpers/capabilityHelper';
import { causeOfEvents } from '../../../constants';

const defaultAuthorType = 'Customer';

const getEventCause = (causeCode, translate) => {
    const key = Object.keys(causeOfEvents).find(key => causeOfEvents[key] === causeCode);
    return translate(`ABB.Powertrain.Frontend.eventCauseOption${causeOfEvents[key]}`);
};

export const getEventCauseOptions = (translate, includeAll = true) => {
    const listOfCauses = map(Object.keys(causeOfEvents), (cause) => {
        return { index: cause, label: translate(`ABB.Powertrain.Frontend.eventCauseOption${cause}`) };
    });
    if (!includeAll) {
        listOfCauses.shift();
    }
    return sortBy(listOfCauses, [entry => entry.label.toLowerCase()]);
};

const getAuthorType = (email) => {
    email = email.toLowerCase();
    const emailValidityRegexp = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (emailValidityRegexp.test(email)) {
        if (new RegExp(`.*[\\.@]${'abb.com'}$`).test(email)) {
            return 'ABB';
        }
    }
    return defaultAuthorType;
};

const canUserDeleteComment = (authorEmail, user) => {
    return roleBasedCapabilityHelper.hasEventCommentDeleteCapability() ||
        user.email === authorEmail;
};

export const formatComments = (comments, assetSerialNumber, showUtcDates, user, translate) => {
    return comments.map(item => {
        const cause = item.causeCode && item.causeCode !== causeOfEvents.All ? getEventCause(item.causeCode, translate) : '';
        const authorType = item.authorEmail ? translate(`ABB.Powertrain.Frontend.by${getAuthorType(item.authorEmail)}SelfEvaluate`) : '';
        const serialNumber = assetSerialNumber ? `${translate('ABB.Powertrain.Frontend.serialNumberSelfEvaluate')} ${assetSerialNumber}` : '';
        const timeStamp = item.timestamp ? toLocaleDateTimeString(item.timestamp, { showOffset: true, asUTC: showUtcDates }) : '';

        return {
            header: `${cause} | ${authorType} | ${serialNumber} | ${timeStamp}`,
            text: item.text,
            canDeleteComment: canUserDeleteComment(item.authorEmail, user),
            id: item.id
        };
    });
};

export const filterCommentsByCause = (comments, cause) => {
    return cause.index === causeOfEvents.All ? comments : filter(comments, comment => comment.causeCode === cause.index);
};

export const checkIfPreselectedCause = (rawEvent, causeOptions) => {
    //TO DO: add parameters for rawEvent, use initialCause from event object when it is provided by the event service
    return rawEvent.isInitialCause ?
        causeOptions.find(causeOption => causeOption.index === rawEvent.causeCode) :
        causeOptions.find(causeOption => causeOption.index === causeOfEvents.Unclassified);

};
