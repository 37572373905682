import { actionTypes, opButtons } from './constants';
import { getKpiTypeByActiveTab, getUpdatedChartData } from './helpers';
import { kpiTypes } from 'helpers/constants';
import clone from 'lodash/clone';

const initialState = {
    activeTab: opButtons.trend,
    chartStatus: {
        loadingStatus: undefined
    },
    chartData: null,
    selectedKPIType: kpiTypes.TREND,
    trendChartZoom: {
        isZoomed: false,
        from: null,
        to: null
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_STORE:
            return {
                ...initialState
            };
        case actionTypes.RESET_CHART:
            return {
                ...state,
                chartData: null
            };
        case actionTypes.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.activeTab,
                selectedKPIType: getKpiTypeByActiveTab(action.activeTab),
            };
        case actionTypes.SET_CHART_DATA_LOADING_STATUS:
            return {
                ...state,
                chartStatus: {
                    loadingStatus: action.loadingStatus
                },
            };
        case actionTypes.LOAD_KPI_DATA: {
            const chartData = getUpdatedChartData(state, action);
            return {
                ...state,
                chartData,
                trendChartZoom: action.trendChartZoom
            };
        }
        case actionTypes.REMOVE_SELECTED_KPI: {
            const data = clone(state.chartData);
            data?.splice(action.data, 1);
            return {
                ...state,
                chartData: data
            };
        }
        case actionTypes.REMOVE_SELECTED_KPI_CHKBOX: {
            const data = clone(state.chartData);
            const type = action.selectedKPIType;
            const chartData = type === 1 ?
                data?.filter((selectedKPI) => {
                    if (selectedKPI?.kpi.timeseriesKey === action.data.timeseriesKey) {
                        return false;
                    } else {
                        return true;
                    }
                }) :
                data?.filter((selectedKPI) => {
                    if (selectedKPI?.kpi?.componentID === action.data.componentID && selectedKPI?.kpi.measurementTypeKey === action.data.measurementTypeKey) {
                        return false;
                    } else {
                        return true;
                    }
                });
            return {
                ...state,
                chartData: chartData,
            };
        }
        case actionTypes.SET_ZOOMED: {
            return {
                ...state,
                trendChartZoom: {
                    isZoomed: action.isTrendChartZoomed,
                    from: action.from,
                    to: action.to
                }
            };
        }
        default:
            return state;
    }
};
