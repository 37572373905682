import { createSelector } from 'reselect';

const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

// export const getFormattedPowertrains = createSelector(
//     [getGeneralFilteredPowertrains, getFilters],
//     (powertrains, filters) => {
//         return orderByAlphabet(filterPowertrains(powertrains, filters));
//     }
// );

const getAssetsList = ({ overview }) => {
    return overview?.assets?.data;
};

export const getModel = createSelector([
    getSelectedAsset,
    getAssetsList
], (
    selectedAsset,
    assets
) => {
	return {
        selectedAsset,
        assets
	};
});
