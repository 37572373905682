import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippy.js/react';
import {
    TimeSelectorRow,
    SearchBox,
    GeneralSelect,
    Switch,
    LoadingStatusWrapper
} from 'sharedComponents';
import { IconInfo } from 'svgIcons/MotionPortalIcons';
import { useNewCapability } from 'sharedHooks';
import AssetMultiSelect from './AssetMultiSelect';
import EventTypeFilter from './EventTypeFilter';
import {
    getDateKindOptions,
    getCauseOfDriveEventsOptions,
    getDefaultTimeZoneValue,
    getDefaultCauseOfDriveEvent,
    getFormattedAssets
} from './helpers';
import { getFormattedAsset } from '../helpers';
import { EventTypeAssetApplicability, causeOfEvents } from '../constants';
import { selectState } from 'helpers/filterHelper';
import { roleBasedCapabilityHelper } from 'helpers/capabilityHelper';
import { apiCallStatus } from 'helpers/constants';


const EventLogFilterCMDComponent = ({ ...props }) => {
    const { t: translate } = useTranslation();
    const capability = useNewCapability();

    const [selectedAssetList, setSelectedAssetList] = useState([]);

    const dateKindOptions = getDateKindOptions(translate);
    const causeOfEventOptions = getCauseOfDriveEventsOptions(translate);

    const {
        selectedAssets,
        filters,
        assetCapabilityLoadingStatus,
        assetListCapabilities,
        capabilitiesLoadingStatus,
        dateKind,
        dateSelection,
        assets,
        selectedAsset,
        eventLogLoadingStatus
    } = props.model;

    const {
        setDateSelection,
        setFilters,
        setDateKind
    } = props.actions;

    useEffect(() => {
        selectedAssets?.length &&
            setSelectedAssetList(selectedAssets);
    }, [selectedAssets]);

    const handleSelectDate = (from, to) => {
        if (from && to) {
            setDateSelection({ from, to });
        }
    };

    const handleFilterByEventType = (typeID) => {
        const eventTypes = selectState(filters.eventTypes, typeID);
        setFilters({ eventTypes });
        props.setIsExapnded(false);
    };

    const onMenuClose = () => {
        props.setSelectedAssets(selectedAssetList);
    };

    return (
        <Container className='event-log-filter-content'>
            <LoadingStatusWrapper loadingStatus={[
                assetCapabilityLoadingStatus
            ]}>
                <div className='filter-area'>
                    <Row>
                        <Col md='auto'>
                            <label htmlFor='event-timezone-selector' >{translate('ABB.Powertrain.Frontend.occurredOnLbl')}</label>
                            <Row className='date-type-and-period-selector g-0'>
                                <GeneralSelect
                                    className='timezone-ddn'
                                    inputId='event-timezone-selector'
                                    options={dateKindOptions}
                                    getOptionLabel={option => option.title}
                                    getOptionValue={option => option.id}
                                    defaultValue={getDefaultTimeZoneValue(dateKind, translate)}
                                    menuShouldScrollIntoView={false}
                                    onChange={dateKind => setDateKind(dateKind.id)}
                                    isSearchable={false}
                                    isDisabled={eventLogLoadingStatus === apiCallStatus.LOADING}
                                />
                                <Col md='auto' className='time-select-row-wrapper'>
                                    <TimeSelectorRow
                                        fromDate={dateSelection.from}
                                        toDate={dateSelection.to}
                                        label=''
                                        handleSelectDate={handleSelectDate}
                                        maxDaysRange={90}
                                        maxDaysBack={roleBasedCapabilityHelper.hasExtendedDateSelector() ? 365 : 90}
                                        preSelectedDays={7}
                                        numberOfMonths={2}
                                        showLoading={false}
                                        footerIntervalText={translate('ABB.Powertrain.Frontend.datePickerMax90DaysNote')}
                                        disabled={eventLogLoadingStatus === apiCallStatus.LOADING}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className='event-log-filter' md='auto'>
                            <EventTypeFilter {...props}
                                assetType={EventTypeAssetApplicability.DRIVE}
                                selectedStates={filters.eventTypes}
                                selectState={handleFilterByEventType}
                                disabled={eventLogLoadingStatus === apiCallStatus.LOADING}
                            />
                        </Col>
                        <Row className='event-filter-dropdowns'>
                            <Col md='auto'>
                                <GeneralSelect
                                    className='cause-of-event-ddn'
                                    options={causeOfEventOptions}
                                    defaultValue={getDefaultCauseOfDriveEvent(filters.causeOfEvent || causeOfEvents.All, translate)}
                                    getOptionLabel={option => option.title}
                                    getOptionValue={option => option.id}
                                    onChange={(causeOfEvent) => setFilters({ causeOfEvent: causeOfEvent.id })}
                                    menuShouldScrollIntoView={false}
                                    isSearchable={false}
                                    isDisabled={eventLogLoadingStatus === apiCallStatus.LOADING}
                                />
                            </Col>
                            <Col md='auto'>
                                <SearchBox
                                    onChange={(searchTerm) => setFilters({ eventName: searchTerm })}
                                    value={filters.eventName}
                                    placeholder={translate('ABB.Powertrain.Frontend.searchEventByNameLbl')}
                                    disabled={eventLogLoadingStatus === apiCallStatus.LOADING}
                                />
                            </Col>
                        </Row>
                        <Row className='event-filter-switches'>
                            <Col md='auto' className='event-list-switch'>
                                <Switch
                                    onChange={() => setFilters({ groupByNames: !filters.groupByNames })}
                                    checked={filters.groupByNames}
                                    disabled={eventLogLoadingStatus === apiCallStatus.LOADING}
                                />
                                <div className='event-list-control-label'>
                                    {translate('ABB.Powertrain.Frontend.eventListFilterGroupByNamesLbl')}
                                </div>
                            </Col>
                            <Col md='auto' className='event-list-switch'>
                                <Switch
                                    onChange={() => setFilters({ withCommentsOnly: !filters.withCommentsOnly })}
                                    checked={filters.withCommentsOnly}
                                    disabled={eventLogLoadingStatus === apiCallStatus.LOADING}
                                />
                                <div className='event-list-control-label'>
                                    {translate('ABB.Powertrain.Frontend.eventListFilterWithCommentsOnlyLbl')}
                                </div>
                            </Col>
                            <Col md='auto' className='event-list-switch'>
                                <Switch
                                    onChange={() => setFilters({ withDataLoggersOnly: !filters.withDataLoggersOnly })}
                                    checked={filters.withDataLoggersOnly}
                                    disabled={eventLogLoadingStatus === apiCallStatus.LOADING}
                                />
                                <div className='event-list-control-label'>
                                    {translate('ABB.Powertrain.Frontend.eventListFilterWithDataLoggersLbl')}
                                </div>
                            </Col>
                        </Row>
                        <Row className='event-filter-drive-selector'>
                            <label htmlFor='multi-asset-selector' className='drive-selector-label'>
                                {translate('ABB.Powertrain.Frontend.eventLogFilterSelectAssetsLbl')}
                                <Tippy
                                    content={translate('ABB.Powertrain.Frontend.eventListAssetsInfoText')}
                                    interactive={true}
                                    animation='scale'
                                    theme='light-border'
                                    trigger='click'
                                    placement='auto'
                                >
                                    <div className='info-icon-wrapper'>
                                        <IconInfo color={'black'} />
                                    </div>
                                </Tippy>
                            </label>
                            <AssetMultiSelect
                                inputId='multi-asset-selector'
                                onChange={setSelectedAssetList}
                                onMenuClose={onMenuClose}
                                isLoading={capabilitiesLoadingStatus === apiCallStatus.LOADING}
                                isDisabled={!capability.checkEventListMultipleAssetsCapability() ||
                                    capabilitiesLoadingStatus === apiCallStatus.LOADING ||
                                    eventLogLoadingStatus === apiCallStatus.LOADING}
                                options={getFormattedAssets(
                                    assetListCapabilities,
                                    assets, selectedAsset
                                )}
                                selectedOptions={selectedAssetList}
                                lockedOption={getFormattedAsset(selectedAsset)}
                                isClearable={false}
                                allowSelectAll={true}
                            />
                        </Row>
                    </Row>
                </div>
            </LoadingStatusWrapper>
        </Container>
    );
};

EventLogFilterCMDComponent.propTypes = {
    actions: PropTypes.shape({
        setDateSelection: PropTypes.func,
        setFilters: PropTypes.func,
        setDateKind: PropTypes.func
    }),
    model: PropTypes.shape({
        selectedAssets: PropTypes.array,
        filters: PropTypes.object,
        assetCapabilityLoadingStatus: PropTypes.string,
        assetListCapabilities: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        capabilitiesLoadingStatus: PropTypes.string,
        dateKind: PropTypes.number,
        dateSelection: PropTypes.object,
        assets: PropTypes.array,
        selectedAsset: PropTypes.object,
        eventLogLoadingStatus: PropTypes.string
    }),
    setSelectedAssets: PropTypes.func,
    setIsExapnded: PropTypes.func,
};

export default EventLogFilterCMDComponent;
