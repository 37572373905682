import * as actionTypes from './constants';

const initialState = {
    assetPictureUpload: {
        data: [],
        loadingStatus: null
    },
    showDeleteConfirm: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.UPLOAD_ASSET_PICTURE_REQUEST:
            return {
                ...state,
                assetPictureUpload: { ...state.assetPictureUpload, ...action },
            };
        case actionTypes.UPLOAD_ASSET_PICTURES:
            return {
                ...state,
                assetPictureUpload: { ...state.assetPictureUpload, ...action },
            };
        case actionTypes.SHOW_CONFIRM_DELETE_ASSET_PICTURE:
            return {
                ...state,
                showDeleteConfirm: action.data
            };
        default:
            return state;
    }
}

