import React from 'react';
import PropTypes from 'prop-types';
import { GeneralSelect } from 'sharedComponents/GeneralSelect';
import { components } from 'react-select';
import { Checkbox } from 'sharedComponents/Checkbox';
import { useDispatch } from 'react-redux';
import { isKpiSelected, isKpiSelectedTrend } from '../helpers';
import './style.scss';

const ParameterSignalSelector = (props) => {

    const {
        ...otherProps
    } = props;
    const Option = props => {
        const componentID = otherProps?.selectedAsset?.componentID ?? null;
        const isSelected = otherProps?.selectedKPIType === 1 ? isKpiSelectedTrend(otherProps?.chartData ?? [], props?.data ?? {}, componentID) : isKpiSelected(otherProps?.chartData ?? [], props?.data ?? {});
        const dispatch = useDispatch();
        return (
            <components.Option {...props}>
                <div className={`parameter-kpi-list-item ${isSelected && 'selected'}`}
                    onClick={() => {
                        isSelected ? dispatch(props?.selectProps?.removeSelectedKPI(
                            props?.data ?? {},
                            props?.selectProps?.selectedAsset ?? {},
                            props?.selectProps?.selectedKPIType ?? {})) :
                            props.data?.isPlottableToTrendChart &&
                            dispatch(props?.selectProps?.setKpi(
                                props?.data ?? {},
                                props?.selectProps.selectedAsset ?? {}
                            ));
                    }} key={props.index} >
                    <div className='kpi-name-img-container'>
                        <Checkbox checked={!!isSelected} disabled={!props.data?.isPlottableToTrendChart} />
                    </div>
                    <div className='parameter-kpi-name'>
                        {props.label}
                    </div>
                </div>
            </components.Option>
        );
    };
    return <GeneralSelect
        {...otherProps}
        blurInputOnSelect={false}
        getOptionLabel={option => option.displayName}
        getOptionValue={option => option.timeseriesKey}
        menuShouldScrollIntoView={false}
        closeMenuOnSelect={true}
        components={{ Option }}
        className='parameter-signal-selector-container'
        menuPosition='absolute'
        controlShouldRenderValue={false}
        minMenuHeight={300}
        menuPlacement='auto'
    />;
};
ParameterSignalSelector.propTypes = {
    options: PropTypes.array,
    placeholder: PropTypes.string,
    selectedAsset: PropTypes.object,
    value: PropTypes.array,
    setKpi: PropTypes.func,
    selectProps: PropTypes.shape({
        selectedAsset: PropTypes.object,
        selectedKPIType: PropTypes.number,
        setKpi: PropTypes.func,
        removeSelectedKPI: PropTypes.func,
        isChecked: PropTypes.bool
    }),
    data: PropTypes.object,
    label: PropTypes.string
};

export default ParameterSignalSelector;
