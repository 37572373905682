import { createSelector } from 'reselect';
import { getGeneralFilteredAssets } from 'helpers/selectors';


const getEventLogFilters = ({ eventLog }) => {
    return eventLog.filters;
};

const getEventLogDateKind = ({ eventLog }) => {
    return eventLog.dateKind;
};

const getDateSelection = ({ overview }) => {
    return overview.dateSelection;
};

const getAssetListCapabilities = ({ eventLog }) => {
    return eventLog?.capabilities;
};

const getCapabilitiesLoadingStatus = ({ eventLog }) => {
    return eventLog?.capabilitiesLoadingStatus;
};

const getAssetCapabilityLoadingStatus = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.newAssetCapability?.loadingStatus;
};

const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getSelectedAssets = ({ eventLog }) => {
    return eventLog.selectedAssets;
};

const getEventLogLoadingStatus = ({ eventLog }) => {
    return eventLog.eventLogLoadingStatus;
};

export const getModel = createSelector([
    getEventLogFilters,
    getEventLogDateKind,
    getDateSelection,
    getGeneralFilteredAssets,
    getAssetListCapabilities,
    getCapabilitiesLoadingStatus,
    getAssetCapabilityLoadingStatus,
    getSelectedAsset,
    getSelectedAssets,
    getEventLogLoadingStatus
], (
    filters,
    dateKind,
    dateSelection,
    assets,
    assetListCapabilities,
    capabilitiesLoadingStatus,
    assetCapabilityLoadingStatus,
    selectedAsset,
    selectedAssets,
    eventLogLoadingStatus
) => {
    return {
        filters,
        dateKind,
        dateSelection,
        assets,
        assetListCapabilities,
        capabilitiesLoadingStatus,
        assetCapabilityLoadingStatus,
        selectedAsset,
        selectedAssets,
        eventLogLoadingStatus
    };
});
