import { map, sortBy, isArray, sumBy } from 'lodash';
import { routes } from 'routes';
import { ASSET_TYPES, filterTabs, gatewayTypes, gatewayTypeTitles, NOTIFICATION_TYPES } from 'helpers/constants';
import featureConfigHelper from 'helpers/featureConfigHelper';
import { pagesWithTrendChart } from './constants';


export const getTabOptions = (translate) => {
    const featureConfig = new featureConfigHelper();

    return {
        conditionBasedMaintenance: {
            title: translate('ABB.Powertrain.Frontend.ptAssetDetailsCBMTab'),
            route: routes.ConditionBasedMaintenance,
            disabled: true,
            hidden: featureConfig.isCBMFeatureEnabled(),
            tabID: translate('ABB.Powertrain.Frontend.ptAssetDetailsCBMTabID')
        }
    };
};

export const createParamMeasurementAssetObj = (measurement, assetObj) => {
    const measurementArray = isArray(measurement) ? measurement : [measurement];
    return [{
        measurementTypes: map(measurementArray, (measurementObj) => {
            return {
                displayName: measurementObj.displayName,
                measurementTypeIdentifier: measurementObj.measurementTypeIdentifier,
                measurementTypeCode: measurementObj.measurementTypeCode,
                measurementTypeKey: measurementObj.measurementTypeKey,
                allSignal: measurementObj.allSignal
            };
        }),
        componentID: assetObj.componentID,
        componentTypeID: assetObj.componentType.typeID,
        dataSourceType: assetObj.componentType.dataSourceType
    }];
};

export const createParamMeasurementAssetObjTrend = (measurement, assetObj) => {
    const measurementArray = isArray(measurement) ? measurement : [measurement];
    return map(measurementArray, (kpi) => {
        return {
            assetId: assetObj?.id,
            timeseries: {
                timeseriesKey: kpi?.timeseriesKey
            }
        };
    });
};

export const afterChartReDraw = (chartContainerId, removeFn) => {
    const htmlElements = document.querySelectorAll(`#${chartContainerId} .remove-button`);
    map(htmlElements, element => {
        // Clone the node, in order to remove all previous event listeners
        const newElement = element.cloneNode(true);
        element.parentNode.replaceChild(newElement, element);
        newElement.addEventListener('click', (e) => {
            removeFn(parseInt(e.currentTarget.id));
            e.stopPropagation();
        });
    });
};

export const afterChartReDrawStringData = (chartContainerId, removeFn) => {
    const htmlElements = document.querySelectorAll(`#${chartContainerId} .remove-button`);
    map(htmlElements, element => {
        // Clone the node, in order to remove all previous event listeners
        const newElement = element.cloneNode(true);
        element.parentNode.replaceChild(newElement, element);
        newElement.addEventListener('click', (e) => {
            removeFn(parseInt(e.currentTarget.id.split('_')[1]));
            const allButtons = document.querySelectorAll('div.highcharts-legend-item');
            const hiddenButtons = document.querySelectorAll('div.highcharts-legend-item-hidden');
            if (allButtons && hiddenButtons) {
                const nonHiddenCount = allButtons.length - hiddenButtons.length;
                if (nonHiddenCount === 1 && hiddenButtons.length > 0) {
                    hiddenButtons.forEach(hiddenButton => hiddenButton.querySelector('.item-kpi-name').click());
                }
            }
            e.stopPropagation();
        });
    });
};


export const removeSelectedIndexData = (array, index) => {
    array.splice(index, 1);
    return array;
};

export const chartLegendItemClick = (e) => {
    if (e.target.visible) {
        const totalVisible = sumBy(
            e.target.chart.series,
            ({ visible, xData, index, legendItem }) => {
                return Number(visible === true
                    && (xData.length === 0 && e.target.index === index || xData.length > 0)
                    && !!legendItem);
            }
        );
        if (totalVisible === 1) {
            e.preventDefault();
            return false;
        }
    }
};

export const getGatewayTypeOptions = (translate) => {
    const gatewayTypeOptions = map(gatewayTypes, (gatewayType) => {
        return {
            id: gatewayType,
            title: gatewayType === gatewayTypes.ALL ?
                translate(`ABB.Powertrain.Frontend.gatewayType${gatewayType}`)
                : gatewayTypeTitles[gatewayType]
        };
    });

    return sortBy(gatewayTypeOptions, [entry => entry.title.toLowerCase()]);
};

export const getDefaultValue = (defaultValue, translate) => {
    return {
        id: defaultValue,
        title: defaultValue === gatewayTypes.ALL ?
            translate(`ABB.Powertrain.Frontend.gatewayType${defaultValue}`)
            : gatewayTypeTitles[defaultValue]
    };
};

const getDriveContractInfo = (endDate, trialPeriodEndDate, now) => {
    const link = {
        url: 'https://new.abb.com/contact-centers',
        description: 'ABB.Powertrain.Frontend.ContactDriveSupport'
    };
    const next60Days = new Date();
    next60Days.setDate(now.getDate() + 60);
    const next30Days = new Date();
    next30Days.setDate(now.getDate() + 30);
    if (!endDate && !trialPeriodEndDate) {
        return {
            message: 'ABB.Powertrain.Frontend.NoContractForDrive',
            link,
            type: NOTIFICATION_TYPES.ERROR
        };
    }
    endDate = new Date(endDate);
    if (!trialPeriodEndDate) {
        const formattedEndDate = endDate.toLocaleDateString();
        if (endDate < now) {
            return {
                message: 'ABB.Powertrain.Frontend.DriveContractExpired',
                messageParams: { endDate: formattedEndDate },
                link,
                type: NOTIFICATION_TYPES.ERROR
            };
        }
        endDate = new Date(endDate);
        if (endDate <= next30Days && endDate >= now) {
            return {
                message: 'ABB.Powertrain.Frontend.DriveContractExpiring',
                messageParams: { endDate: formattedEndDate },
                link,
                type: NOTIFICATION_TYPES.WARN
            };
        }
        if (endDate <= next60Days) {
            return {
                message: 'ABB.Powertrain.Frontend.DriveContractExpiring',
                messageParams: { endDate: formattedEndDate },
                link,
                type: NOTIFICATION_TYPES.WARN
            };
        }
    } else {
        const formattedEndDate = endDate.toLocaleDateString();
        trialPeriodEndDate = new Date(trialPeriodEndDate);
        const formattedTrialPeriodEndDate = trialPeriodEndDate.toLocaleDateString();
        if (endDate < now && trialPeriodEndDate < now) {
            if (endDate < trialPeriodEndDate) {
                return {
                    message: 'ABB.Powertrain.Frontend.DriveTrialPeriodExpired',
                    messageParams: { endDate: formattedTrialPeriodEndDate },
                    link,
                    type: NOTIFICATION_TYPES.ERROR
                };
            }
            else {
                return {
                    message: 'ABB.Powertrain.Frontend.DriveContractExpired',
                    messageParams: { endDate: formattedEndDate },
                    link,
                    type: NOTIFICATION_TYPES.ERROR
                };
            }
        }
        if (endDate < now && trialPeriodEndDate > next60Days) {
            return {
                message: 'ABB.Powertrain.Frontend.DriveTrialPeriodActivated',
                messageParams: { endDate: formattedTrialPeriodEndDate },
                type: NOTIFICATION_TYPES.INFO
            };
        }
        if (endDate < now && trialPeriodEndDate <= next60Days) {
            return {
                message: 'ABB.Powertrain.Frontend.DriveTrialPeriodEnding',
                messageParams: { endDate: formattedTrialPeriodEndDate },
                link,
                type: NOTIFICATION_TYPES.WARN
            };
        }
        if (endDate <= next60Days && trialPeriodEndDate < now) {
            return {
                message: 'ABB.Powertrain.Frontend.DriveContractExpiring',
                messageParams: { endDate: formattedEndDate },
                link,
                type: NOTIFICATION_TYPES.WARN
            };
        }
        if (endDate <= next60Days && trialPeriodEndDate > next60Days) {
            return {
                message: 'ABB.Powertrain.Frontend.DriveTrialPeriodActivated',
                messageParams: { endDate: formattedTrialPeriodEndDate },
                type: NOTIFICATION_TYPES.INFO
            };
        }
        if (endDate <= next60Days && trialPeriodEndDate <= next60Days) {
            return {
                message: 'ABB.Powertrain.Frontend.DriveContractExpiring',
                messageParams: { endDate: formattedEndDate },
                link,
                type: NOTIFICATION_TYPES.WARN
            };
        }
    }
};

const getSmartSensorContractInfo = (isMonitored, endDate, now) => {
    const link = {
        url: 'https://new.abb.com/contact-centers',
        description: 'ABB.Powertrain.Frontend.ContactDriveSupport'
    };
    const next30Days = new Date();
    next30Days.setDate(now.getDate() + 30);
    if (!isMonitored) {
        return {
            message: 'ABB.Powertrain.Frontend.AssetIsUnmonitored',
            type: NOTIFICATION_TYPES.INFO
        };
    }
    if (!endDate) {
        return {
            message: 'ABB.Powertrain.Frontend.NoSubscription',
            link,
            type: NOTIFICATION_TYPES.ERROR
        };
    }
    endDate = new Date(endDate);
    if (endDate < now) {
        return {
            message: 'ABB.Powertrain.Frontend.NoSubscription',
            link,
            type: NOTIFICATION_TYPES.ERROR
        };
    }
    endDate = new Date(endDate);
    if (endDate <= next30Days && endDate >= now) {
        const formattedEndDate = endDate.toLocaleDateString();
        return {
            message: 'ABB.Powertrain.Frontend.SubscriptionExpiring',
            messageParams: { endDate: formattedEndDate },
            type: NOTIFICATION_TYPES.WARN
        };
    }
};


export const getContractInfo = (capability) => {
    if (capability && capability.subscription && capability.assetType && !capability.subscription.isLifetimeSubscription) {
        const endDate = capability.subscription.endDate;
        const trialPeriodEndDate = capability.subscription.trialPeriodEndDate;
        const now = new Date();

        if (capability.assetType.assetTypeID === ASSET_TYPES.DRIVE) {
            return getDriveContractInfo(endDate, trialPeriodEndDate, now);
        } else {
            return getSmartSensorContractInfo(capability.isMonitored, endDate, now);
        }
    }
    return null;
};

export const toggleTabsWhenSelectionChanges = (query, activeTab, setActiveTabAction) => {
    if (query.powertrainId && activeTab !== filterTabs.POWERTRAINS) {
        setActiveTabAction(filterTabs.POWERTRAINS);
    }
    else if (!query.powertrainId && query.assetId && activeTab !== filterTabs.ASSETS) {
        setActiveTabAction(filterTabs.ASSETS);
    }
};

export const getZoomingDates = (state, page) => {
    let trendChartZoom = {};
    let anyKpiSelected = false;

    switch (page) {
        case pagesWithTrendChart.operationalParameters: {
            trendChartZoom = state?.operationalParameters?.charts?.single?.trend[0]?.zoom;
            anyKpiSelected = state?.operationalParameters?.charts?.single?.trend[0]?.kpiList?.length > 0;
            break;
        }
        case pagesWithTrendChart.operationalParametersV2: {
            trendChartZoom = state?.operationalParametersV2?.charts?.single?.trend[0]?.zoom;
            anyKpiSelected = state?.operationalParametersV2?.charts?.single?.trend[0]?.kpiList?.length > 0;
            break;
        }
        case pagesWithTrendChart.crossAssetVisualization: {
            trendChartZoom = state.chartView.trendChartZoom;
            anyKpiSelected = state.chartView.chartData?.length > 0;
            break;
        }
        default: {
            trendChartZoom = state.operationalParameters.trendChartZoom;
            anyKpiSelected = state.operationalParameters.trend.data.length > 0;
            break;
        }
    }

    const isZoomed = trendChartZoom.isZoomed;

    let { from, to } = state.overview.dateSelection;
    let zoomed = false;

    if (anyKpiSelected && isZoomed && trendChartZoom.from && trendChartZoom.to) {
        from = trendChartZoom.from;
        to = trendChartZoom.to;
        zoomed = trendChartZoom.isZoomed;
    }

    return { fromDate: from, toDate: to, zoomed };
};
