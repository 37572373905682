import { createSelector } from 'reselect';

export const getActiveDetailedInformationTab = ({ detailedInformation }) => {
    return detailedInformation?.activeTab;
};

export const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails?.selectedAsset;
};

export const getActiveTab = ({ operationalParameters }) => {
    return operationalParameters?.activeTab;
};

export const getMultiChart = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.multiChart;
};

const getAssetDetails = ({ detailedInformation }) => {
    return {
        loadingStatus: detailedInformation?.assetDetails?.loadingStatus,
        details: detailedInformation?.assetDetails?.data?.details
    };
};

const getSensorProperties = ({ sensorProperties }) => {
    return sensorProperties?.sensorProperties;
};

const getDownloadConfirmationParams = ({ downloadMeasurements }) => {
    return downloadMeasurements.measurementDownloadDialogParams;
};

export const getModel = createSelector([
    getActiveTab,
    getAssetDetails,
    getSensorProperties,
    getMultiChart,
    getDownloadConfirmationParams
], (
    activeTab,
    assetDetails,
    sensorProperties,
    multiChart,
    measurementDownloadDialogParams
) => {
    return {
        activeTab,
        assetDetails,
        sensorProperties,
        multiChart,
        measurementDownloadDialogParams
    };
});
