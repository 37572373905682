import { actionTypes } from './constants';
import { apiCallStatus } from 'helpers/constants';
import { UserLimitsApi } from 'api';
import { getApiActionCreator } from 'helpers/actionHelper';


const getUserLimitsAction = getApiActionCreator(actionTypes.GET_USER_LIMITS);

export const getUserLimits = (component, forceReload) => {
    return (dispatch, getState) => {
        const state = getState();
        const { loadingStatus, data } = state.detailedInformationHeader.userLimits;
        let promise = Promise.resolve(data);
        if (!loadingStatus || forceReload) {
            dispatch(getUserLimitsAction(apiCallStatus.LOADING));
            promise = UserLimitsApi.getUserLimits(component)
                .then((res) => {
                    dispatch(getUserLimitsAction(apiCallStatus.SUCCESS, res));
                })
                .catch((err) => {
                    dispatch(getUserLimitsAction(apiCallStatus.ERROR, null, err));
                });
        }
        return promise;
    };
};

