import { actionTypes } from '../constants';
import { getApiActionCreator } from 'helpers/actionHelper';

export const getAssetMuteSettingsAction = getApiActionCreator(actionTypes.GET_ASSET_MUTE_SETTINGS);
export const updateAssetMuteSettingsAction = getApiActionCreator(actionTypes.SET_UPDATE_MUTE_SETTINGS_LOADING);

export const getMutedAssetList = (organizationID) => ({
    type: actionTypes.LOAD_MUTED_ASSETS_REQUEST,
    organizationID
});

export const selectMutedAsset = (assetId = null) => ({
    type: actionTypes.SELECT_MUTED_ASSET,
    assetId
});

export const saveMutedAssetSettings = (organizationID, notificationGroups, period, assetId, close) => ({
    type: actionTypes.UPDATE_MUTED_ASSET_REQUEST,
    organizationID,
    assetId,
    notificationGroups,
    startDate: period?.from || null,
    endDate: period?.to || null,
    close
});

export const getAssetMutedSettings = (assetID) => ({
    type: actionTypes.LOAD_ASSET_MUTE_SETTINGS_REQUEST,
    assetID
});
