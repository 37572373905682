import { actionTypes } from './constants';
import { apiCallStatus } from 'helpers/constants';
import { EventLogsApi } from 'api';
import { getApiActionCreator } from 'helpers/actionHelper';


export const setActiveTabAction = (activeTab) => {
    return {
        type: actionTypes.SET_ACTIVE_TAB,
        activeTab
    };
};

const getEventLoggersAction = getApiActionCreator(actionTypes.GET_EVENT_LOGGERS);
const getEventLoggerId = getApiActionCreator(actionTypes.GET_LOGGERID);

export const getLoggerId = (event) => {
    return dispatch => {
        dispatch(getEventLoggersAction(apiCallStatus.LOADING));
        EventLogsApi.getLoggerId(event?.eventId)
            .then((data) => {
                dispatch(getEventLoggerId(apiCallStatus.SUCCESS, data));
                dispatch(getEventLoggersAction(apiCallStatus.SUCCESS));
            })
            .catch((err) => {
                dispatch(getEventLoggerId(apiCallStatus.ERROR, null, err));
                dispatch(getEventLoggersAction(apiCallStatus.ERROR));
            });
    };
};


export const setLoggers = (loggers) => ({
    type: actionTypes.SET_LOGGERS,
    payload: loggers
});

export const getLoggers = (loggerId) => {
    return async (dispatch) => {
        try {
            dispatch(getEventLoggersAction(apiCallStatus.LOADING));
            const data = await EventLogsApi.getLoggers(loggerId);
            dispatch(getEventLoggersAction(apiCallStatus.SUCCESS, data));
        } catch (err) {
            dispatch(getEventLoggersAction(apiCallStatus.ERROR, null, err));
        }
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_ASSISTANCE_PAGE });
        dispatch({ type: actionTypes.CLEAR_SELF_EVALUATION_STORE });
        dispatch({ type: actionTypes.CLEAR_TREND_SIGNAL_STORE });
    };
};
