import { actionTypes, opButtons, multiChartOptions } from './constants';
import { actionTypes as scatterActions } from './SingleChartView/ScatterPlot/constants';
import { actionTypes as histogramActions } from './SingleChartView/Histogram/constants';
import { actionTypes as trendSectionActions } from './SingleChartView/Trend/TrendKPIList/constants';
import { actionTypes as trendCommonActions } from './SingleChartView/Trend/constants';
import { actionTypes as FFTActions } from './VibrationFFT/constants';
import { commonActionTypes } from '../constants';
import { getInitialMultiTrendCharts } from '../helpers';
import { getActionWithDefaultData } from 'helpers/actionHelper';
import {
    mapTrendKpiListV2
} from '../commonTrendHelpers';
import {
    getCommonTrendKPIs,
    getAvailableKPI,
    isAssetCompatibleWithSelectedKPIs,
    isAssetCompatibleWithSelectedTrendKPIs,
    addKPISelection,
    addSelectOpTrendKPIData,
    removeSelectedOpTrendKpiData,
    removeSelectedTrendKpi
} from './helpers';
import { apiCallStatus } from 'helpers/constants';
import { removeSelectedIndexData } from '../../helpers';


const initialState = {
    activeTab: opButtons.trends,
    multiChart: multiChartOptions.SINGLE,
    charts: {
        single: {
            trend: [
                {
                    kpiList: [],
                    zoom: {
                        isZoomed: false,
                        from: null,
                        to: null
                    },
                    loadingStatus: null,
                    chartName: '',
                    chartID: 'op-trend-chart'
                }
            ]
        },
        multi: {
            trend: []
        }
    },
    scatterKpis: {
        data: [],
        loadingStatus: null,
    },
    scatter: {
        data: [],
        loadingStatus: null,
    },
    histogramKpis: {
        data: [],
        loadingStatus: null,
    },
    histogram: {
        data: [],
        loadingStatus: null,
    },
    measurementDownloadDialogParams: {
        showDownloadConfirmation: false,
        measurementTypeIds: '',
    },
    trendHealthStatuses: {
        data: [],
        loadingStatus: null,
    },
    measurementExport: {
        loadingStatus: undefined
    },
    FFT: {
        loadingStatus: null,
        data: [],
    },
    availableKPIs: {
        trend: {
            loadingStatus: null,
            data: []
        }
    },
    preselectedKPIs: {
        assetTypeID: null,
        single: {
            trend: []
        },
        multi: {
            trend: []
        }
    },
    loadMeasurementLoadingStatus: apiCallStatus.SUCCESS,
    collectRawDataLoadingStatus: apiCallStatus.SUCCESS,
    selectedScatterKPI: null,
    selectedHistogramKPI: null,
};


export default (state = initialState, action) => {
    switch (action.type) {
        //COMMON SECTION
        case actionTypes.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.activeTab,
                multiChart: initialState.multiChart
            };
        case actionTypes.LOAD_MEASUREMENT:
            return {
                ...state,
                loadMeasurementLoadingStatus: action.loadingStatus
            };
        case actionTypes.SET_SELECTED_ASSET:
            return {
                ...state,
                multiChart: initialState.multiChart,
                charts: initialState.charts,
                measurementExport: initialState.measurementExport,
                measurementDownloadDialogParams: initialState.measurementDownloadDialogParams,
                trendHealthStatuses: initialState.trendHealthStatuses,
                scatterKpis: { ...initialState.scatterKpis },
                scatter: { ...initialState.scatter },
                histogramKpis: { ...initialState.histogramKpis },
                histogram: { ...initialState.histogram },
                availableKPIs: { ...initialState.availableKPIs },
                preselectedKPIs: {
                    ...state.preselectedKPIs,
                    assetTypeID: action.asset?.componentType?.typeID,
                    single: {
                        ...state.preselectedKPIs.single,
                        trend: state.activeTab === opButtons.trends &&
                            isAssetCompatibleWithSelectedTrendKPIs(action.asset, state.preselectedKPIs.assetTypeID) ?
                            state.preselectedKPIs.single.trend : initialState.preselectedKPIs.single.trend,
                    },
                    multi: { ...initialState.preselectedKPIs.multi }
                },
                selectedScatterKPI: state.activeTab === opButtons.scatterPlot &&
                    isAssetCompatibleWithSelectedKPIs(action.asset, [state.selectedScatterKPI]) ?
                    state.selectedScatterKPI : initialState.selectedScatterKPI,
                selectedHistogramKPI: state.activeTab === opButtons.histogram &&
                    isAssetCompatibleWithSelectedKPIs(action.asset, [state.selectedHistogramKPI]) ?
                    state.selectedHistogramKPI : initialState.selectedHistogramKPI,
            };

        //TREND SECTION
        case actionTypes.SET_ACTIVE_TREND_TAB:
            return {
                ...state,
                multiChart: action.multiChart
            };
        case trendSectionActions.SET_MEASUREMENT_DIALOG_PARAMS:
            return {
                ...state,
                measurementDownloadDialogParams: { ...action.measurementDownloadDialogParams }
            };
        case trendSectionActions.EXPORT_EXCEL_MEASUREMENT:
            return {
                ...state,
                measurementExport: { ...state.measurementExport, ...action }
            };
        /*case commonActionTypes.GET_ASSET_TREND_KPIS: {
            const commonTrendKPIs = action.loadingStatus !== apiCallStatus.LOADING && action.data.length ?
                getCommonTrendKPIs(action.data, state.preselectedKPIs.single.trend) : state.preselectedKPIs.single.trend;
            return {
                ...state,
                preselectedKPIs: {
                    ...state.preselectedKPIs,
                    single: {
                        ...state.preselectedKPIs.single,
                        trend: commonTrendKPIs
                    }
                },
                availableKPIs: {
                    trend: {
                        loadingStatus: action.loadingStatus,
                        data: mapTrendKpiListV2(action.data)
                    }
                }
            };
        }*/
        case commonActionTypes.GET_ASSET_TREND_KPIS_V2: {
            const commonTrendKPIs = action.loadingStatus !== apiCallStatus.LOADING && action?.data?.length ?
                getCommonTrendKPIs(action.data, state.preselectedKPIs.single.trend) : state.preselectedKPIs.single.trend;
            return {
                ...state,
                preselectedKPIs: {
                    ...state.preselectedKPIs,
                    single: {
                        ...state.preselectedKPIs.single,
                        trend: commonTrendKPIs
                    }
                },
                availableKPIs: {
                    trend: {
                        loadingStatus: action.loadingStatus,
                        data: mapTrendKpiListV2(action.data)
                    }
                }
            };
        }
        case trendCommonActions.ADD_TREND_KPI_SELECTION:
            return {
                ...state,
                preselectedKPIs: {
                    ...state.preselectedKPIs,
                    single: {
                        ...state.preselectedKPIs.single,
                        trend: addKPISelection([...state.preselectedKPIs.single.trend || []], action.selectedTrendKPI.timeseriesKey)
                    }
                }
            };
        case trendCommonActions.GET_TREND_KPI_LIST_SELECTION:
            return {
                ...state,
                charts: {
                    ...state.charts,
                    single: {
                        ...state.charts.single,
                        trend: [
                            {
                                ...state.charts.single.trend[0],
                                kpiList: action.data,
                            }
                        ]
                    }
                }
            };
        case trendCommonActions.ADD_SELECTED_TREND_KPI_DATA:
        case trendCommonActions.RELOAD_ALL_SELECTED_TREND_KPI:
            return {
                ...state,
                charts: {
                    ...state.charts,
                    single: {
                        ...state.charts.single,
                        trend: [
                            {
                                ...state.charts.single.trend[0],
                                kpiList: addSelectOpTrendKPIData([...state.charts.single.trend[0].kpiList || []], action.newKpiData),
                                zoom: action.trendChartZoom
                            }
                        ]
                    }
                }
            };
        case trendCommonActions.REMOVE_TREND_KPI_SELECTION:
            return {
                ...state,
                preselectedKPIs: {
                    ...state.preselectedKPIs,
                    single: {
                        ...state.preselectedKPIs.single,
                        trend: removeSelectedTrendKpi([...state.preselectedKPIs.single.trend], action.unselectedTrendKPI)
                    }
                },
                charts: {
                    ...state.charts,
                    single: {
                        ...state.charts.single,
                        trend: [
                            {
                                ...state.charts.single.trend[0],
                                kpiList: removeSelectedOpTrendKpiData([...state.charts.single.trend[0].kpiList], action.unselectedTrendKPI),
                            }
                        ]
                    }
                }
            };
        case trendCommonActions.REMOVE_TREND_KPI_SELECTION_LEGEND:
            return {
                ...state,
                preselectedKPIs: {
                    ...state.preselectedKPIs,
                    single: {
                        ...state.preselectedKPIs.single,
                        trend: removeSelectedIndexData([...state.preselectedKPIs.single.trend], action.signalIndex)
                    }
                },
                charts: {
                    ...state.charts,
                    single: {
                        ...state.charts.single,
                        trend: [
                            {
                                ...state.charts.single.trend[0],
                                kpiList: removeSelectedIndexData([...state.charts.single.trend[0].kpiList], action.signalIndex),
                            }
                        ]
                    }
                }
            };

        case trendCommonActions.RESET_TREND_KPI_SELECTION:
            return {
                ...state,
                preselectedKPIs: {
                    ...state.preselectedKPIs,
                    single: {
                        ...state.preselectedKPIs.single,
                        trend: []
                    }
                },
                charts: {
                    ...state.charts,
                    single: {
                        ...state.charts.single,
                        trend: [
                            {
                                ...state.charts.single.trend[0],
                                kpiList: [],
                            }
                        ]
                    }
                }
            };
        case trendCommonActions.SET_ZOOMED:
            return {
                ...state,
                charts: {
                    ...state.charts,
                    single: {
                        ...state.charts.single,
                        trend: [
                            {
                                ...state.charts.single.trend[0],
                                zoom: {
                                    isZoomed: action.isTrendChartZoomed,
                                    from: action.from,
                                    to: action.to
                                }
                            }
                        ]
                    }
                }
            };
        case trendCommonActions.GET_ASSET_TREND_DATA:
            return {
                ...state,
                charts: {
                    ...state.charts,
                    single: {
                        ...state.charts.single,
                        trend: [
                            {
                                ...state.charts.single.trend[0],
                                loadingStatus: action.loadingStatus
                            }
                        ]
                    }
                }
            };
        case trendSectionActions.GET_ASSET_MEASUREMENT_HEALTH:
            return {
                ...state,
                trendHealthStatuses: getActionWithDefaultData(action, initialState.trendHealthStatuses)
            };

        // MULTI TREND
        case actionTypes.SET_MULTICHART_CONFIG:
            return {
                ...state,
                preselectedKPIs: {
                    ...state.preselectedKPIs,
                    multi: {
                        ...state.preselectedKPIs.multi,
                        trend: action.data
                    }
                },
                charts: {
                    ...state.charts,
                    multi: {
                        ...state.charts.multi,
                        trend: getInitialMultiTrendCharts(action.data)
                    }
                }
            };
        case actionTypes.GET_MULTI_TREND_DATA:
            return {
                ...state,
                charts: {
                    ...state.charts,
                    multi: {
                        ...state.charts.multi,
                        trend: action.data
                    }
                }
            };
        case actionTypes.CLEAR_MULTICHART_DATA:
            return {
                ...state,
                charts: {
                    ...state.charts,
                    multi: { ...initialState.charts.multi }
                },
                preselectedKPIs: {
                    ...state.preselectedKPIs,
                    multi: { ...initialState.preselectedKPIs.multi }
                }
            };
        case actionTypes.CLEAR_UNMONITORED_CHART_DATA:
            return {
                ...state,
                preselectedKPIs: {
                    ...state.preselectedKPIs,
                    single: {
                        ...state.preselectedKPIs.single,
                        trend: []
                    }
                },
                charts: {
                    ...state.charts,
                    single: {
                        ...state.charts.single,
                        trend: [
                            {
                                ...state.charts.single.trend[0],
                                kpiList: [],
                            }
                        ]
                    }
                }
            };
        //SCATTER SECTION
        case scatterActions.GET_ASSET_SCATTER_KPIS: {
            const availableKPI = action.loadingStatus !== apiCallStatus.LOADING && action.data.length ?
                getAvailableKPI(action.data, state.selectedScatterKPI) : state.selectedScatterKPI;
            return {
                ...state,
                scatterKpis: getActionWithDefaultData(action, initialState.scatterKpis),
                selectedScatterKPI: availableKPI
            };
        }
        case scatterActions.GET_SCATTER:
            return {
                ...state,
                scatter: getActionWithDefaultData(action, initialState.scatter)
            };
        case scatterActions.SET_SCATTER_KPI:
            return {
                ...state,
                selectedScatterKPI: action.scatterSelectedKpi
            };
        case scatterActions.REMOVE_TREND_KPI_SELECTION_LEGEND_SCATTER: {
            return {
                ...state,
                selectedScatterKPI: null
            };
        }

        //HISTOGRAM SECTION
        case histogramActions.GET_ASSET_HISTOGRAM_KPIS: {
            const availableKPI = action.loadingStatus !== apiCallStatus.LOADING && action.data.length ?
                getAvailableKPI(action.data, state.selectedHistogramKPI) : state.selectedHistogramKPI;
            return {
                ...state,
                histogramKpis: getActionWithDefaultData(action, initialState.histogramKpis),
                selectedHistogramKPI: availableKPI
            };
        }
        case histogramActions.GET_HISTOGRAM:
            return {
                ...state,
                histogram: getActionWithDefaultData(action, initialState.histogram)
            };
        case histogramActions.SET_HISTOGRAM_KPI:
            return {
                ...state,
                selectedHistogramKPI: action.histogramSelectedKpi
            };
        case histogramActions.REMOVE_TREND_KPI_SELECTION_LEGEND_HISTOGRAM:
            return {
                ...state,
                selectedHistogramKPI: null
            };

        //FFT SECTION
        case FFTActions.GET_FFT:
            return {
                ...state,
                FFT: { ...action }
            };
        case actionTypes.COLLECT_RAW_DATA:
            return {
                ...state,
                collectRawDataLoadingStatus: action.loadingStatus
            };
        default:
            return state;
    }
};
