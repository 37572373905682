import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNewCapability } from 'sharedHooks';
import { filterAndFormatCMDAssets, getFormattedAsset } from './helpers';
import { EventLogs } from './EventLogs';
import { EventLogFilter } from './EventLogFilter';
import { EventDetailsSS } from './EventDetails/EventDetailsSS';
import { EventDetailsCMD } from './EventDetails/EventDetailsCMD';
import { apiCallStatus } from 'helpers/constants';


const EventLogComponent = (props) => {
    const capability = useNewCapability();
    const isSmartSensor = capability.isSmartSensor();

    const {
        generalFilteredAssets,
        selectedAsset,
        selectedOrganization,
        isExpanded,
        assetCapabilityLoadingStatus
    } = props.model;

    const {
        loadCapabilitiesForAssets,
        setSelectedAssets,
        setSelectedEvent,
        setIsExapnded
    } = props.actions;

    useEffect(() => {
        generalFilteredAssets?.length && !isSmartSensor &&
            loadCapabilitiesForAssets(selectedOrganization?.organizationID, filterAndFormatCMDAssets(generalFilteredAssets));
    }, [generalFilteredAssets]);

    useEffect(() => {
        setSelectedAssets([getFormattedAsset(selectedAsset)]);
    }, [selectedAsset]);

    const renderEventDetails = () => {
        return isSmartSensor ?
            <EventDetailsSS
                {...props}
                setSelectedEvent={setSelectedEvent}
            />
            :
            <EventDetailsCMD
                {...props}
                setSelectedEvent={setSelectedEvent}
            />;
    };

    return (
        <div className='event-log-content'>
            {!props.model.selectedEvent ?
                <>
                    <EventLogFilter {...props}
                        isSS={isSmartSensor}
                        setSelectedAssets={setSelectedAssets}
                        setIsExapnded={setIsExapnded}
                    />
                    {assetCapabilityLoadingStatus === apiCallStatus.SUCCESS && <EventLogs
                        {...props}
                        isSS={isSmartSensor}
                        setSelectedEvent={setSelectedEvent}
                        setIsExapnded={setIsExapnded}
                        isExpanded={isExpanded}
                        assistanceCapability={
                            capability.hasAssistancePageCapability()
                        }
                    />}
                </>
                : renderEventDetails()
            }
        </div>
    );
};

EventLogComponent.propTypes = {
    actions: PropTypes.shape({
        setSelectedEvent: PropTypes.func,
        loadCapabilitiesForAssets: PropTypes.func,
        setSelectedAssets: PropTypes.func,
        setIsExapnded: PropTypes.func
    }),
    asset: PropTypes.any,
    model: PropTypes.shape({
        selectedAsset: PropTypes.object,
        selectedOrganization: PropTypes.number,
        selectedEvent: PropTypes.object,
        generalFilteredAssets: PropTypes.array,
        isExpanded: PropTypes.bool,
        assetListCapabilityLoadingStatus: PropTypes.string,
        assetCapabilityLoadingStatus: PropTypes.string
    })
};


export default EventLogComponent;
