import { LEVEL, LIMIT_MENU_OPTIONS, LIMIT_SYMBOL } from './constants';

export const getContextMenuOptions = (isEditable, isOffsetEnabled, options) => {
    return options.map(item => {
        if (item.id === LIMIT_MENU_OPTIONS.REMOVE_SIGNAL && !isEditable) {
            return { ...item, disabled: true };
        }
        if (item.id === LIMIT_MENU_OPTIONS.EDIT_OFFSET) {
            return isOffsetEnabled ? { ...item, disabled: false } : { ...item, disabled: true };
        }
        return item;
    });
};


export const getSliderValues = (limits) => {
    return limits.map(limit => ({
        type: limit.limitLevel,
        value: limit.limitValue,
        symbol: limit.limitDirection === LEVEL.LowerLimit ? LIMIT_SYMBOL.Lower : LIMIT_SYMBOL.Greater
    }));
};
export const getSliderValue = {
    oneDirectionAlarmOrAlert: [
        { type: 'Alarm', value: 75, symbol: '>' },
    ],
    oneDirectionAlarmAndAlert: [
        { type: 'Alert', value: 35, symbol: '>' },
        { type: 'Alarm', value: 75, symbol: '>' },
    ],
    inRangeAlarmOrAlert: [
        { type: 'Alarm', value: 35, symbol: '>' },
        { type: 'Alarm', value: 75, symbol: '<' },
    ],
    outOfRangeAlarmOrAlert: [
        { type: 'Alarm', value: 35, symbol: '<' },
        { type: 'Alarm', value: 75, symbol: '>' },
    ],
    inRangeAlarmAndAlert: [
        { type: 'Alert', value: 35, symbol: '>' },
        { type: 'Alarm', value: 75, symbol: '>' },
        { type: 'Alert', value: 100, symbol: '>' },
        { type: 'Alarm', value: 130, symbol: '<' },
    ],
    outOfRangeAlarmAndAlert: [
        { type: 'Alert', value: 80, symbol: '>' },
        { type: 'Alarm', value: 30, symbol: '<' },
        { type: 'Alarm', value: 100, symbol: '>' },
        { type: 'Alert', value: 50, symbol: '<' },
    ],
};
