import React, { useEffect, useState } from 'react';

import { translate } from 'helpers/translateHelper';
import { afterChartReDraw } from '../../../../helpers';
import { CHART_PLUGINS } from 'sharedComponents/Chart/constants';
import { getSeries } from './helpers';

import { TrendTooltip } from 'sharedComponents/ChartComponents/Trend';

import colors from 'theme/_colors.scss';
import { renderToStaticMarkup } from 'react-dom/server';
import { RemovableLegend } from 'sharedComponents/ChartComponents/CommonLegend';
import { ChartNoDataText } from 'sharedComponents/ChartComponents';
import { defaultBoostThreshold } from 'helpers/constants';

export const chartPlugins = [CHART_PLUGINS.HIGHCHARTS_MORE, CHART_PLUGINS.NO_DATA_TO_DISPLAY, CHART_PLUGINS.BOOST];

const getChartConfig = removeKPIData => {
    return {
        title: {
            text: null
        },
        chart: {
            zoomType: 'x',
            height: '500',
            events: {
                render: function (chartInstance) {
                    afterChartReDraw(chartInstance.target.container.id, removeKPIData);
                }
            },
            marginBottom: 220
        },
        boost: {
            allowForce: false //Workaround for highchart bug. Allows correctly disabling boost, when zooming in.
        },
        time: {
            useUTC: false
        },
        xAxis: {
            type: 'datetime',
            id: 'x',
            dateTimeLabelFormats: {
                millisecond: '%H:%M:%S',
            },
            crosshair: {
                enabled: true,
                width: 2,
                color: colors.tundora,
            },
            minPadding: 0,
            maxPadding: 0
        },
        legend: {
            useHTML: true,
            layout: 'vertical',
            align: 'left',
            enabled: true,
            floating: true,
            reversed: true,
            verticalAlign: 'top',
            itemDistance: 16,
            itemMarginTop: 10,
            itemStyle: {
                fontSize: '14px',
                fontFamily: 'ABBvoice-Regular',
                letterSpacing: '0',
                lineHeight: '17px',
            },
            symbolRadius: 0,
            squareSymbol: false,
            symbolHeight: 8,
            symbolWidth: 8,
            y: 300,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            valueDecimals: 2,
            useHTML: true,
            backgroundColor: null,
            borderWidth: 0,
            dateTimeLabelFormats: {
                day: '%d.%m.%Y<br/>%H:%M',
                hour: '%d.%m %H:%M',
            },
        },
        plotOptions: {
            series: {
                turboThreshold: 0,
                animation: false,
                boostThreshold: 0
            }
        },
        lang: {
            noData: 'No data'
        },
        noData: {
            useHTML: true
        },
        exporting: {
            enabled: false,
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG'],
                    verticalAlign: 'bottom',
                    x: 10,
                    y: 0
                }
            }
        }
    };
};

const useChartConfig = (selectedKpisData, removeKPIData) => {

    const [options, setOptions] = useState({});

    const chartConfig = getChartConfig(removeKPIData);

    useEffect(() => {
        const { yAxis, seriesData, allSeriesLength } = getSeries(selectedKpisData);

        chartConfig.yAxis = yAxis;
        chartConfig.series = seriesData;
        chartConfig.lang.noData = renderToStaticMarkup(
            <ChartNoDataText
                title={translate('ABB.Powertrain.Frontend.chartNoMeasurementData')}
                text={translate('ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide')} />);
        chartConfig.enableNoDataZoom = true;
        chartConfig.plotOptions.series.boostThreshold = allSeriesLength > defaultBoostThreshold ? 1 : 0;
        chartConfig.tooltip.formatter = function () {
            return renderToStaticMarkup(<TrendTooltip points={this.points} timeStamp={this.x} />);
        };

        chartConfig.legend.labelFormatter = function () {
            return renderToStaticMarkup(<RemovableLegend
                className='cross-asset__legend'
                signalSourceName={this.userOptions.component}
                legend={this}
            />);
        };

        setOptions({ ...chartConfig });
    }, [selectedKpisData]);

    return options;
};

export default useChartConfig;
