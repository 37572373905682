import { kpiTypes } from 'helpers/constants';
import { translate } from 'helpers/translateHelper';
import { CHART_MAX_KPI_LIMIT, opButtons } from './constants';
import { PlotApi, TimeSeriesApi } from 'api/';
import { Histogram } from './Graph/Histogram';
import { ScatterPlot } from './Graph/ScatterPlot';
import { Trend } from './Graph/Trend';

import { clone, map, findIndex } from 'lodash';
import { createParamMeasurementAssetObj, createParamMeasurementAssetObjTrend } from '../../helpers';
import { setColor } from '../../DetailedInformation/commonTrendHelpers';

export const transformPlotRequestData = (component, series) => {
    return [{
        measurementTypes: [{
            displayName: series[0]?.displayName,
            measurementTypeIdentifier: series[0]?.measurementTypeIdentifier,
            measurementTypeCode: series[0]?.measurementTypeCode,
            measurementTypeKey: series[0]?.measurementTypeKey,
            allSignal: series[0]?.allSignal
        }],
        componentID: component[0]?.componentID,
        componentTypeID: component[0]?.componentType.typeID,
        dataSourceType: component[0]?.componentType.dataSourceType
    }];
};

export const transformPlotRequestDataTrend = (list) => {
    const data = list.map((val) => val?.componentKPIType[0]);
    return data.map((l) => {
        return {
            assetId: l.assetId,
            timeseries: { timeseriesKey: l?.timeseries?.timeseriesKey }
        };
    });
};

export const getToggleOptions = () => {
    const allTabs = [{
        label: translate('ABB.Powertrain.Frontend.crossAssetAnalysisTrendSignalsBtn'),
        value: opButtons.trend,
        component: Trend
    },
    {
        label: translate('ABB.Powertrain.Frontend.opScatterPlotTabBtn'),
        value: opButtons.scatterPlot,
        component: ScatterPlot
    },
    {
        label: translate('ABB.Powertrain.Frontend.opHistogramTabBtn'),
        value: opButtons.histogram,
        component: Histogram,
    }];

    return allTabs;
};

const checkCompatibility = (selected, current) => {
    if (selected.kpi.measurementTypeIdentifier !== current.kpi.measurementTypeIdentifier) {
        return false;
    }
    const checkProperties = item => item && item.data && item.data.length && item.data[0] && item.data[0].config;
    if (!checkProperties(selected) || !checkProperties(current)) {
        return true;
    }
    return selected.data[0].config.xUnitGroupID === current.data[0].config.xUnitGroupID
        && selected.data[0].config.yUnitGroupID === current.data[0].config.yUnitGroupID;
};

export const getUpdatedChartData = (state, action) => {
    let chartData = state.chartData && state.chartData.length > 0 ? clone(state.chartData) : [];
    if (chartData.length > 0 && action.kpiType !== kpiTypes.TREND && !checkCompatibility(action, state.chartData[0])) {
        chartData = [];
    }

    const chartDataItem = {
        data: action.data,
        component: action.component,
        powertrain: state.selectedPowertrain,
        kpi: action.kpi
    };

    const index = action.kpiType === kpiTypes.TREND ? findIndex(chartData, (item) => item?.kpi?.timeseriesKey === chartDataItem.kpi.timeseriesKey) : findIndex(chartData, (item) => item?.kpi?.measurementTypeIdentifier === chartDataItem.kpi.measurementTypeIdentifier && item?.component.componentID === chartDataItem.component.componentID);

    if (index >= 0) {
        chartData.splice(index, 1, { ...chartDataItem, color: chartData[index].color });
    } else {
        chartData.push(chartDataItem);
        if (chartData.length > CHART_MAX_KPI_LIMIT) {
            chartData.shift();
        }
    }

    setColor(chartData);
    return chartData;
};

export const getKpiTypeByActiveTab = (tabName) => {
    switch (tabName) {
        case opButtons.trend: {
            return kpiTypes.TREND;
        }
        case opButtons.scatterPlot: {
            return kpiTypes.SCATTER;
        }
        case opButtons.histogram: {
            return kpiTypes.HISTOGRAM;
        }
        default:
            return;
    }
};


export const paramListForChartDataRequest = (chartData, selectedKPIType) => {
    return chartData ? map(chartData, (item) => {
        return {
            componentKPIType: selectedKPIType === 1 ? createParamMeasurementAssetObjTrend(item.kpi, item.component) : createParamMeasurementAssetObj(item.kpi, item.component),
            kpi: item.kpi,
            component: item.component,
            color: item.color
        };
    }) : null;
};

export const getPlotAPI = (state) => {
    const { chartView: { selectedKPIType } } = state;
    switch (selectedKPIType) {
        case kpiTypes.SCATTER:
            return PlotApi.getScatter;
        case kpiTypes.HISTOGRAM:
            return PlotApi.getHistogram;
        default:
            return TimeSeriesApi.getTrend;
    }
};
