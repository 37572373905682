export const actionTypes = {
    SET_SELECTED_ASSET: 'powertrainAssetDetails/SET_SELECTED_ASSET',
    GET_ASSET_DETAILS: 'detailedInformation/GET_ASSET_DETAILS',
    SET_SELECTED_POWERTRAIN: 'powertrainAssetDetails/SET_SELECTED_POWERTRAIN',
    SET_ACTIVE_TAB: 'detailedInformation/SET_ACTIVE_TAB',
    CLEAR_STORE: 'detailedInformation/CLEAR_STORE',
    SET_SELECTED_ASSET_CONDITION_INSIGHTS_STATUS: 'detailedInformation/SET_SELECTED_ASSET_CONDITION_INSIGHTS_STATUS',
};

export const commonActionTypes = {
    GET_ASSET_TREND_KPIS: 'downloadMeasurements/GET_ASSET_TREND_KPIS',
    GET_ASSET_TREND_KPIS_V2: 'downloadMeasurements/GET_ASSET_TREND_KPIS_V2',
    CLEAR_STORE: 'downloadMeasurements/CLEAR_STORE'
};

export const sensorActionTypes = {
    GET_SENSOR_FEATURES: 'sensorProperties/GET_SENSOR_FEATURES',
    GET_SENSOR_PROPERTIES: 'sensorProperties/GET_SENSOR_PROPERTIES',
    GET_GATEWAY_INFO: 'sensorProperties/GET_GATEWAY_INFO',
};

export const MAX_TREND_SIGNALS = 6;

export const requestStatuses = {
    NOT_REQUESTED_INITIAL: null,
    NOT_REQUESTED: 0,
    PENDING: 1,
    COMPLETED: 2,
    ERROR: 3
};

export const responseStatuses = {
    COMPLETED_SUCCESSFULLY: 0,
    SENSOR_NOT_FOUND: 1,
    WRONG_PASSKEY: 2,
    COULD_NOT_CONNECT_TO_THE_SENSOR: 3,
    NO_MEASUREMENT_AVAILABLE: 4,
    INVALID_PARAMETERS: 5,
    DEGRADED_MODE: 6,
    INTERNAL_ERROR: 999
};

export const sensorFeatureKeys = {
    AccelerometerRangeConfiguration: 'AccelerometerRangeConfiguration',
    BatteryLevel: 'BatteryLevel',
    BatteryMode: 'BatteryMode',
    BatteryPercentage: 'BatteryPercentage',
    EnableSpeedSync: 'EnableSpeedSync',
    JoinKey: 'JoinKey',
    MeasuredCurrent: 'MeasuredCurrent',
    OnDemandDataCollectionRequest: 'OnDemandDataCollectionRequest',
    OnDemandDataCollectionResponseMessage: 'OnDemandDataCollectionResponseMessage',
    OperatingMode: 'OperatingMode',
    PowerSavingMode: 'PowerSavingMode',
    PowerSavingTimeZone: 'PowerSavingTimeZone',
    RawDataCollectionRequest: 'RawDataCollectionRequest',
    RawDataCollectionResponseMessage: 'RawDataCollectionResponseMessage',
    RunningStateCalibration: 'RunningStateCalibration',
    SensorMeasurementInterval: 'SensorMeasurementInterval',
    SensorRSSIValue: 'SensorRSSIValue',
    ThreeAxisVibrationRawData: '3AxisVibrationRawData',
};

export const tabIDs = {
    assetDetails: 'assetDetails',
    operationalParameters: 'operationalParameters',
    operationalParametersv2: 'operationalParametersV2',
    limitConfig: 'limitConfig',
    eventLog: 'eventLog',
    limitsConfiguration: 'limitsConfiguration',
    sensorProperties: 'sensorProperties',
    advancedAnalytics: 'advancedAnalytics',
    conditionInsights: 'conditionInsights'
};

// eslint-disable-next-line import/no-unused-modules
export const sensorTypeMappings = {
    ['SmartSensorMotor']: 1,
    ['SmartSensorPump']: 2,
    ['SmartSensorBearing']: 3,
    ['AmbientSensor']: 4,
    ['CoMo']: 5,
    ['MACHsense-R']: 6,
    ['ECMDrive']: 7,
    ['GenericMachinery']: 8,
    ['CoMoLight']: 9,
    ['CoMoOG']: 10,
    ['SmartHMISensor']: 12,
    ['SALTsensor']: 13,
    ['SALTStarVario']: 14,
    ['Integer']: 15,
    ['DummySensorType1']: 1000,
    ['DummySensorType2']: 1001,
    ['CoMoHP']: 5,
    ['CoMoSP']: 9
};

export const signalDataTypes = ['string', 'bool', 'boolean'];
