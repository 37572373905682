import { EventLogsApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import * as constants from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { downloadLoggerFile } from './helpers';


//Actions
const downloadLoggerAction = getApiActionCreator(constants.DOWNLOAD_LOGGER);

const clearLoggerFileAction = () => {
    return {
        type: constants.CLEAR_LOGGER_FILE,
    };
};

const clearLoggerFile = () => {
    return (dispatch) => {
        dispatch(clearLoggerFileAction());
    };
};

// Action Creators
export const downloadLogger = (loggers) => {
    return dispatch => {
        dispatch(downloadLoggerAction(apiCallStatus.LOADING));
        EventLogsApi.downloadLogger(loggers?.id)
            .then((downloadData) => {
                downloadLoggerFile(downloadData.content, loggers?.fileFullName);
                dispatch(downloadLoggerAction(apiCallStatus.SUCCESS, downloadData.content));
                dispatch(clearLoggerFile());
            })
            .catch((err) => {
                dispatch(downloadLoggerAction(apiCallStatus.ERROR, null, err));
            });
     };
};
