import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { useCapability } from 'sharedHooks';
import { AssetLabels } from './AssetLabels';
import { AssetNameplateImageHolder } from './AssetNameplateImageHolder';

import './style.scss';

const HeaderComponent = (props) => {

    const capability = useCapability();

    return (
        <Col className='header-container' md={12}>
            {capability.hasLabelingCapability() && <Row><AssetLabels asset={props.asset} /></Row>}
            <div className='asset-conditions'>
                {/*capability.hasNameplateGalleryCapability() && <AssetNameplateImageHolder asset={props.asset} />*/}
                <AssetNameplateImageHolder asset={props.asset} />
            </div>
        </Col>
    );
};

HeaderComponent.propTypes = {
    asset: PropTypes.object.isRequired,
};

export default HeaderComponent;
