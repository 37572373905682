import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { IconAssetGroup } from 'svgIcons/MotionPortalIcons';
import { Expandable } from 'sharedComponents/Expandable';
import Asset from '../AssetRowComponent';
import { ScrollableList } from '../ScrollableListComponent';
import { pageSize } from '../constants';
import {
    formatConditionIndexOverviewRequest,
    renderConditionIndicesComponent,
    renderLimitsComponent,
    renderThresholdSummary,
    renderTrendSummary
} from '../helpers';


const AssetOverviewComponent = (props) => {
    const { t: translate } = useTranslation();
    const [timer, setTimer] = useState(null);
    const [selectedAssetGroup, setSelectedAssetGroup] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState({});
    const [isHorizontalScrollActive, setIsHorizontalScrollActive] = useState(false);
    const {
        pageNumber,
        assetGroupOverviewLoadingStatus,
        assetGroupOverviewList,
        hasMore
    } = props.model;
    const { getAssetGroupConditionIndicesRequest } = props.actions;
    const {
        assetNumber,
        selectedFilters,
        searchBoxFilter,
        conditionFilter,
        assetTypeFilter,
        withVisibleFilter,
        assetGroup
    } = props;

    useEffect(() => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            getAssetGroupConditionIndicesRequest(formatConditionIndexOverviewRequest(
                1,
                selectedFilters,
                searchBoxFilter,
                conditionFilter,
                assetTypeFilter
            ));
        }, searchBoxFilter?.length > 0 ? 700 : 0);

        setTimer(newTimer);
    }, [
        searchBoxFilter,
        JSON.stringify(selectedFilters),
        JSON.stringify(conditionFilter),
        assetTypeFilter
    ]);

    const handleSelectedItem = (asset) => {
        setSelectedAsset(asset);
    };

    useEffect(() => {
        setSelectedAssetGroup(assetGroup?.assetGroupId);
        handleSelectedItem(assetGroup?.selectedAsset);
    }, [assetGroup]);

    const handleReset = () => {
        setSelectedAsset(null);
    };

    const handleAssetGroupClick = (assetGroup) => {
        setSelectedAssetGroup(assetGroup?.assetGroupId);
    };

    const onHorizontalScrollHandler = (isScroll) => {
        setIsHorizontalScrollActive(isScroll);
    };

    const renderExpandableTitle = (item) => {
        return <><div className={`item-name powertrain__cell-fixed ${isHorizontalScrollActive ? 'horizontal-scroll-active' : ''}`}>
            <div className='item-icon'><IconAssetGroup /></div>
            <div className='name'>{item.assetGroupName} ({item?.assets?.length})</div>
        </div>
            {renderConditionIndicesComponent(item)}
            {renderLimitsComponent(item)}
            {renderThresholdSummary(item)}
            {renderTrendSummary(item)}
        </>;
    };

    const renderAssetGroups = () => {
        return map(assetGroupOverviewList, (item) => {
            return <Expandable
                id={item.assetGroupId}
                key={item.assetGroupId}
                title={renderExpandableTitle(item)}
                onClick={() => handleAssetGroupClick(item)}
                open={item.assetGroupId === selectedAssetGroup}
                isExpanderInsideContent={true}
            >
                {!item.assets || item.assets.length === 0 ?
                    <div className='no-available-assets'>
                        {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewNoAvailableAssets')}
                    </div> :
                    <div key={item.assetGroupId} className='asset-list-of-asset-group'>
                        {map(item.assets, (asset) => {
                            return <Asset
                                key={asset.assetId}
                                asset={asset}
                                selectedAsset={selectedAsset}
                                onSelectedItem={handleSelectedItem}
                                isHorizontalScrollActive={isHorizontalScrollActive}
                            />;
                        })}
                    </div>}
            </Expandable>;
        });
    };

    return <ScrollableList
        noDataLabel={translate('ABB.Powertrain.Frontend.conditionIndicesOverviewNoItems')}
        className={` asset overview ${withVisibleFilter ? 'with-filter' : ''}`}
        assetNumber={assetNumber}
        loadingStatus={assetGroupOverviewLoadingStatus}
        hasMore={hasMore}
        pageSize={pageSize}
        loadMore={() => getAssetGroupConditionIndicesRequest(formatConditionIndexOverviewRequest(
            pageNumber,
            selectedFilters,
            searchBoxFilter,
            conditionFilter,
            assetTypeFilter
        ))}
        renderList={renderAssetGroups}
        selectedAsset={selectedAsset}
        hasData={assetGroupOverviewList}
        selectedAssetGroup={selectedAssetGroup}
        onReset={handleReset}
        onHorizontalScroll={onHorizontalScrollHandler}
    />;
};

AssetOverviewComponent.propTypes = {
    model: PropTypes.shape({
        pageNumber: PropTypes.number,
        assetOverviewLoadingStatus: PropTypes.string,
        assetOverviewList: PropTypes.array,
        hasMore: PropTypes.bool
    }),
    actions: PropTypes.shape({
        getAssetGroupConditionIndicesRequest: PropTypes.func
    }),
    assetNumber: PropTypes.number,
    selectedFilters: PropTypes.object,
    searchBoxFilter: PropTypes.string,
    conditionFilter: PropTypes.array,
    assetTypeFilter: PropTypes.string,
    withVisibleFilter: PropTypes.bool
};


export default AssetOverviewComponent;
