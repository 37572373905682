import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { omit, some } from 'lodash';

import { Expandable, GeneralButton, GeneralDialog, GeneralSelect, SearchBox } from 'sharedComponents';
import { translate } from 'helpers/translateHelper';
import { IconPlus } from 'svgIcons/MotionPortalIcons';
import LimitTitle from './components/LimitTitle';
import { addSignal, limitTypeChange, resetState, cancel, postLimitRequest } from './actions';
import { LimitTypeOptions, SLIDER_VALUES, RANGE, WINDOW_EVENTS } from './components/constants';
import Limit from './components/Limit';
import { MultiPointSlider } from 'sharedComponents/Slider';
import { getSliderValues } from './components/helper';
import Option from './components/Option';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';

const getPayload = (timeSeries) => {
    const filteredTimeSeriesWithModifiedLimits = timeSeries.filter(item => item.isModified).map(item => ({
        ...omit(item, ['isModified', 'uniqId', 'isNew']),
        limits: item.limits.map(limit =>
            omit(limit, ['isModified', 'uniqId', 'isError']),
        )
    }));

    return filteredTimeSeriesWithModifiedLimits;
};

const adjustMinMax = (data, type) => {
    if (data.length === 0) {
        return null;
    }

    const values = data.map(item => Number(item.value));

    let minVal = Math.min(...values);
    let maxVal = Math.max(...values);

    // Custom logic to adjust min and max values
    if (minVal === maxVal) {
        if (type === RANGE.Min) {
            minVal -= 1; // Ensuring minimum is adjusted down
        }
        if (type === RANGE.Max) {
            maxVal += 1; // Ensuring maximum is adjusted up
        }
    }

    if (type === RANGE.Min) {
        let adjustedMin = minVal <= 0 ? Math.ceil(minVal * SLIDER_VALUES.MAX) : Math.floor(minVal * SLIDER_VALUES.MIN);
        if (minVal === 1 || minVal === 0) {
            adjustedMin = -1;
        }
        return adjustedMin;
    } else if (type === RANGE.Max) {
        const adjustedMax = maxVal < 0 ? Math.ceil(maxVal * SLIDER_VALUES.MIN) : Math.floor(maxVal * SLIDER_VALUES.MAX);
        return adjustedMax;
    }
};

const getMinValue = (timeSeries, type) => {
    const minValue = timeSeries.minValue;
    const maxValue = timeSeries.maxValue;

    if (type === RANGE.Min) {
        return minValue !== undefined && minValue !== null ? minValue : adjustMinMax(getSliderValues(timeSeries.limits), RANGE.Min);
    } else if (type === RANGE.Max) {
        return maxValue !== undefined && maxValue !== null ? maxValue : adjustMinMax(getSliderValues(timeSeries.limits), RANGE.Max);
    }
};

const getSearchMatcher = (search) => {
    const searchToLower = search?.toString()?.toLowerCase();
    return (value) => value?.toString()?.toLowerCase()?.includes(searchToLower);
};

const LimitConfigComponent = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { model, asset, actions } = props;
    const [isDisableSave, setIsDisableSave] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [expandedItem, setExpandedItem] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);
    const unblockRef = useRef(null);

    const hasModifiedItem = model.currentTimeSeries.some(item =>
        item.isModified || item.limits.some(limit => limit.isModified)
    );

    useEffect(() => {
        if (hasModifiedItem) {
            const handleBeforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = '';
            };

            unblockRef.current = history.block((location) => {
                if (location.pathname !== routes.LimitsConfiguration && hasModifiedItem) {
                    setNextLocation(location);
                    setShowModal(true);
                    return false;
                }
                return true;
            });

            window.addEventListener(WINDOW_EVENTS.BEFORE_UNLOAD, handleBeforeUnload);

            return () => {
                window.removeEventListener(WINDOW_EVENTS.BEFORE_UNLOAD, handleBeforeUnload);
                if (unblockRef.current) { unblockRef.current(); }
            };
        }
    }, [hasModifiedItem, history]);

    useEffect(() => {
        if (model.timeSeries.isLoading) {
            setIsDisableSave(true);
        } else {
            const hasModifiedItemWithoutDisplayName = model.currentTimeSeries.some(item =>
                item.isModified && (!item.displayName || item.displayName.length === 0)
            );

            const hasInvalidLimits = model.currentTimeSeries.filter(item => item.isModified).some(item =>
                some(item.limits, limit =>
                    !limit.limitLevel || !limit.limitValue || limit.limitValue === null || limit.limitValue === '' || !limit.limitDirection
                )
            );

            const hasNoLimits = model.currentTimeSeries.filter(item => item?.limits?.length === 0);

            const hasModifiedSignals = model.currentTimeSeries.filter(item => item.isModified);
            if (hasModifiedSignals.length) {
                if (hasModifiedItemWithoutDisplayName || hasInvalidLimits || hasNoLimits.length > 0) {
                    setIsDisableSave(true);
                } else {
                    setIsDisableSave(false);
                }
            } else {
                setIsDisableSave(true);
            }
        }
    }, [model.currentTimeSeries]);

    useEffect(() => {
        if (asset.id === model.timeSeries.assetId) {
            return;
        }

        dispatch(resetState());
        actions.getLimitKPI(asset.id);
        actions.getTimeSeries(asset.id);
    }, [asset.id]);

    const handleAddSignal = () => {
        const newSignal = {
            uniqId: crypto.randomUUID(),
            limits: [],
            isModified: true,
            isNew: true,
        };
        dispatch(addSignal(newSignal));
    };

    const handleLimitTypeChange = (value, item) => {
        setExpandedItem(item.uniqId);
        dispatch(limitTypeChange(item, value));
    };

    const handleCancel = () => {
        dispatch(cancel());
    };

    const handleSaveConfig = () => {
        const payload = getPayload(model.currentTimeSeries, model.limitKPI.kpiList);
        dispatch(postLimitRequest(asset.id, payload));
    };

    const filteredList = useMemo(() => {
        const isSearchMatch = getSearchMatcher(searchValue);

        const timeseries = model.currentTimeSeries ?? [];

        return searchValue
            ? timeseries.filter((item) =>
                isSearchMatch(item.displayName) ||
                isSearchMatch(item.timeseriesKey) ||
                isSearchMatch(item.trendKey)
            )
            : timeseries;
    }, [searchValue, model.currentTimeSeries]);

    const displayNameTextKeys = new Set(model.currentTimeSeries.map(item => item.displayNameTextKey));

    const updatedConfigList = model.limitKPI.kpiList.flatMap(configItem =>
        configItem.timeseries.filter(series => !displayNameTextKeys.has(series.timeseriesNameTextKey))
    );

    const hasEditableLimit = updatedConfigList?.length > 0;

    const handleDiscardAndContinue = () => {
        setShowModal(false);
        handleCancel();
        if (nextLocation) {
            if (unblockRef.current) { unblockRef.current(); }
            history.push(`${nextLocation.pathname}${nextLocation.search}`);
        }
    };

    return (
        <div className='limit-config-container'>
            <GeneralDialog
                show={showModal}
                close={() => setShowModal(false)}
                notificationType='warning'
                title={translate('ABB.Powertrain.Frontend.unsavedChangesWarningPopupTitle')}
                cancelButtonProps={{
                    text: translate('ABB.Powertrain.Frontend.limitDiscardButtonTitle'),
                    onClick: handleDiscardAndContinue
                }}
                acceptButtonProps={{
                    text: translate('ABB.Powertrain.Frontend.limitButtonGoBack'),
                    onClick: () => setShowModal(false)
                }}
                closeButton={true}
                persistent={false}
            >
                <div>
                    {translate('ABB.Powertrain.Frontend.unsavedLimitsPromtMessage')}
                </div>
            </GeneralDialog>
            <div className='limit-config-header'>
                <div className='header-left'>
                    <GeneralButton
                        type='primary'
                        text={translate('ABB.Powertrain.Frontend.addSignal')}
                        icon={<IconPlus color={'#ffffff'} />}
                        className='limit-btn'
                        onClick={handleAddSignal}
                        disabled={model.timeSeries.isLoading || !hasEditableLimit}
                    />
                    {/* Needed for future use case */}
                    {/* <GeneralButton
                        type='normal'
                        text={translate('ABB.Powertrain.Frontend.expandAll')}
                        className='limit-btn'
                        onClick={() => setIsExpanded(!isExpanded)}
                        disabled={model.timeSeries.isLoading}
                    /> */}
                </div>
                <div className='header-right'>
                    {!isDisableSave &&
                        <>
                            <GeneralButton
                                type='normal'
                                className='limit-config-Cancel-button'
                                text={translate('ABB.Powertrain.Frontend.generalFilterCancelButton')}
                                onClick={handleCancel}
                                disabled={isDisableSave}
                            />
                            <GeneralButton
                                disabled={isDisableSave}
                                style={{ backgroundColor: '#9CBDF7 !important' }}
                                type='primary'
                                className='limit-config-save-button'
                                text={translate('ABB.Powertrain.Frontend.saveButton')}
                                onClick={handleSaveConfig}
                            />
                        </>}
                    {filteredList && filteredList.length > 0 &&
                        <div className='limit-count'>
                            {filteredList.length} {filteredList.length > 1 ? translate('ABB.Powertrain.Frontend.signals') : translate('ABB.Powertrain.Frontend.signal')}
                        </div>
                    }
                    <SearchBox
                        className='limit-search-box'
                        value={searchValue}
                        onChange={(value) => setSearchValue(value)}
                        placeholder={translate('ABB.Powertrain.Frontend.searchBySignalName')}
                    />
                </div>
            </div>

            <div className='signal-limit-container'>
                {(filteredList ?? []).map(timeSeries =>
                    <Expandable
                        disabled={!timeSeries.displayName}
                        open={!timeSeries.displayName ? false : isExpanded || expandedItem === timeSeries.uniqId}
                        style={{ border: '1px solid #bababa' }}
                        key={timeSeries.uniqId}
                        title={
                            <LimitTitle
                                {...props}
                                item={timeSeries}
                                setExpandedItem={setExpandedItem}
                                itemExpanded={!timeSeries.displayName ? false : isExpanded || expandedItem === timeSeries.uniqId}
                                setIsExpanded={setIsExpanded}
                            />
                        }
                    >
                        <div className='content-container' id={timeSeries.uniqId}>
                            {timeSeries.limits.length === 0 &&
                                <div className='limit-type-container'>
                                    <div className='limit-type'>
                                        <GeneralSelect
                                            options={LimitTypeOptions}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.value}
                                            components={{ Option }}
                                            onChange={(e) => {
                                                handleLimitTypeChange(e.value, timeSeries);
                                            }}
                                            placeholder={translate('ABB.Powertrain.Frontend.limitTypePlaceholder')}
                                        />
                                    </div>
                                    <div className='no-limits'>
                                        No limit preview. Select limit type to start.
                                    </div>
                                </div>
                            }
                            {timeSeries.limits.length > 0 &&
                                timeSeries.limits.map(limit =>
                                    <Limit key={limit.uniqId} item={limit} timeSeries={timeSeries} />
                                )
                            }
                            {timeSeries.limits.length > 0 &&
                                <div className='limit-slider-container'>
                                    <MultiPointSlider
                                        uniqId={timeSeries.uniqId}
                                        showPointer
                                        showMinMax={timeSeries.minValue !== null}
                                        min={getMinValue(timeSeries, 'min')}
                                        max={getMinValue(timeSeries, 'max')}
                                        value={getSliderValues(timeSeries.limits)}
                                    />
                                </div>
                            }
                        </div>
                    </Expandable>
                )}
            </div>
        </div>
    );
};

export default LimitConfigComponent;
