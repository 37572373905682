import { actionTypes } from './constants';


const initialState = {
	eventComments: {
		data: [],
		loadingStatus: null
	},
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_EVENT_COMMENTS:
			return {
				...state,
				eventComments: { ...state.eventComments, ...action }
			};
		case actionTypes.CLEAR_STORE:
			return initialState;
		default:
			return state;
	}
};
