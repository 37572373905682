import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router';
import { uniqBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import PropTypes from 'prop-types';
import { Col, Container, Row, Stack } from 'react-bootstrap';

import { DetailedInformation } from './DetailedInformation';
import { CrossAssetAnalysis } from './CrossAssetAnalysis';
import { GeneralRouterTabs } from 'sharedComponents';
import { getTabOptions } from './helpers';
import { AssetPowertrainSelector } from './AssetPowertrainSelector';
import { Breadcrumbs } from 'sharedComponents/Breadcrumbs';
import { GeneralButton } from 'sharedComponents/GeneralButton';
import './style.scss';

const PowertrainAssetDetailsComponent = ({ model, location }) => {
    const history = useHistory();
    const { t: translate } = useTranslation();
    const assetId = model.selectedAsset?.componentID;
    const name = model.selectedAsset?.componentName;

    const [tabOptions, setTabOptions] = useState(getTabOptions(translate));
    const [breadcrumbRoute, setBreadcrumbRoute] = useState([]);
    const [isCrossKPITab, setIsCrossKPITab] = useState(true);
    const [isNavigatingToCrossKPI, setIsNavigatingToCrossKPI] = useState(false);
    const [data, setData] = useState(() => {
        const storedData = localStorage.getItem('powertrainAssetDetails');
        return storedData ? JSON.parse(storedData) : location.state;
    });
    const [selectedAssetName, setSelectedAssetName] = useState(model.selectedAsset?.componentName);

    const routeData = Array.isArray(data?.routeData) ? data?.routeData[0] : data?.routeData;
    const powertrainName = routeData?.powertrainName || '';
    const assetGroupName = routeData?.assetGroupName || '';

    const updateBreadcrumbRoute = () => {
        let updatedRoute;
        if (isNavigatingToCrossKPI) {
            updatedRoute = [
                { path: routes.Overview, name: translate('ABB.Powertrain.Frontend.ptConditionMonitoringMainMenu') },
                { path: { pathname: routes.DetailedInformation, search: new URLSearchParams({ assetId }).toString() }, name },
                { path: '', name: translate('ABB.Powertrain.Frontend.ptAssetDetailsCrossKPITab') },
            ];
        } else if (isCrossKPITab) {
            const secondRouteName = powertrainName || assetGroupName || routeData?.assetName;
            updatedRoute = [
                { path: routes.Overview, name: translate('ABB.Powertrain.Frontend.ptConditionMonitoringMainMenu') },
                { path: routes.Overview, name: secondRouteName }
            ];
        } else {
            updatedRoute = [
                { path: routes.Overview, name: translate('ABB.Powertrain.Frontend.ptConditionMonitoringMainMenu') },
                { path: { pathname: routes.DetailedInformation, search: new URLSearchParams({ assetId }).toString() }, name }
            ];
        }
        setBreadcrumbRoute(updatedRoute);
    };

    const navigateToDetailedInformation = (clickedSearch) => {
        setIsCrossKPITab(true);
        updateBreadcrumbRoute(false);
        const assetId = clickedSearch.get('assetId');
        if (assetId) {
            const selectedAsset = routeData.assets.find(asset => asset.assetSerialNumber === assetId);
            if (selectedAsset) {
                setSelectedAssetName(selectedAsset.assetName);
            }
        }
    };

    const updateRouteData = () => {
        const storedData = localStorage.getItem('powertrainAssetDetails');
        if (!storedData) {
            return;
        }

        const parsedData = JSON.parse(storedData);
        const routeData = Array.isArray(parsedData?.routeData) ? parsedData?.routeData[0] : parsedData?.routeData;

        if (!routeData || !routeData.assets || routeData.assets.length === 0) {
            return;
        }

        const selectedAsset = routeData.assets.find(asset => asset.assetName === selectedAssetName);
        if (selectedAsset) {
            routeData.selectedAsset = selectedAsset;
            localStorage.setItem('powertrainAssetDetails', JSON.stringify({ routeData }));
            setData({ routeData });
        }
    };

    const navigateToOverview = (clickedSearch) => {
        const storedData = localStorage.getItem('powertrainAssetDetails');
        if (!storedData) {
            history.push(routes.Overview);
            return;
        }

        const parsedData = JSON.parse(storedData);
        const routeData = Array.isArray(parsedData?.routeData) ? parsedData?.routeData[0] : parsedData?.routeData;

        if (!routeData || !routeData.assets || routeData.assets.length === 0) {
            history.push(routes.Overview);
            return;
        }

        const clickedAssetId = clickedSearch.get('assetId');
        const selectedAsset = clickedAssetId
            ? routeData.assets.find(asset => asset.assetSerialNumber === clickedAssetId)
            : routeData.selectedAsset || routeData.assets[0];

        if (selectedAsset) {
            routeData.selectedAsset = selectedAsset;
            localStorage.setItem('powertrainAssetDetails', JSON.stringify({ routeData }));
            history.push({
                pathname: routes.Overview,
                state: { selectedAsset: selectedAsset.assetSerialNumber }
            });
            setSelectedAssetName(selectedAsset.assetName);
        } else {
            history.push(routes.Overview);
        }
    };

    const resetBreadcrumbRoute = () => {
        updateBreadcrumbRoute();
        setIsCrossKPITab(false);
    };

    useEffect(() => {
        setSelectedAssetName(model.selectedAsset?.componentName);
        setTabOptions(getTabOptions(translate));
    }, [model.selectedAsset?.componentName, translate]);

    useEffect(() => {
        if (model.assets) {
            setData({ routeData: { ...data.routeData, assets: model.assets } });
            routeData.assets = uniqBy([...routeData.assets, ...model.assets], 'assetID');
        }
    }, [model.assets, routeData.assets]);

    useEffect(() => {
        const storedData = localStorage.getItem('powertrainAssetDetails');
        if (storedData) {
            setData(JSON.parse(storedData));
        }
        if (location.state) {
            const updatedData = { ...JSON.parse(storedData), ...location.state };
            localStorage.setItem('powertrainAssetDetails', JSON.stringify(updatedData));
            setData(updatedData);
        }
    }, [location.state]);

    useEffect(() => {
        updateBreadcrumbRoute();
    }, [isCrossKPITab, powertrainName, assetGroupName, routeData?.assetName]);

    useEffect(() => {
        updateRouteData();
    }, [selectedAssetName]);

    useEffect(() => {
        if (isNavigatingToCrossKPI) {
            setIsNavigatingToCrossKPI(false);
        }
    }, [isNavigatingToCrossKPI]);

    const handleNavigate = () => {
        setIsCrossKPITab(false);
        history.push({ pathname: routes.CrossKpi, search: new URLSearchParams({ assetId }).toString() });
        setIsNavigatingToCrossKPI(true);
    };

    const handleBreadcrumbClick = (clickedRoute) => {
        const clickedPathname = clickedRoute.pathname || clickedRoute;
        const clickedSearch = clickedRoute.search ? new URLSearchParams(clickedRoute.search) : new URLSearchParams();

        if (clickedPathname === routes.Overview) {
            navigateToOverview(clickedSearch);
        } else if (clickedPathname === routes.DetailedInformation) {
            navigateToDetailedInformation(clickedSearch);
        } else if (clickedPathname === '') {
            resetBreadcrumbRoute();
        }
    };

    const options = isCrossKPITab && routeData?.assets
        ? routeData.assets.map(asset => ({
            value: asset.assetName || asset.componentName,
            label: asset.assetName || asset.componentName,
            route: {
                pathname: history.location.pathname,
                search: new URLSearchParams({ assetId: asset.assetSerialNumber || asset.serialNumber || asset.assetId }).toString() // assetSerialNumber, serialNumber for Drives, Motors etc. | assetId for SMSE.
            }
        }))
        : [];

    return (
        <div className='general-page-content'>
            <div className='general-page-content__container'>
                <Container fluid className='px-4'>
                    <Row>
                        <Col>
                            <Stack direction='horizontal' gap={3}>
                                <Breadcrumbs
                                    routes={breadcrumbRoute}
                                    onBreadcrumbClick={handleBreadcrumbClick}
                                    options={options}
                                    selectedAssetName={selectedAssetName}
                                />
                                {isCrossKPITab &&
                                    <GeneralButton
                                        className='general-page-content__nav-cross ms-auto'
                                        type='primary'
                                        text={translate('ABB.Powertrain.Frontend.condMonitoringOverviewCrossAssetLabel')}
                                        onClick={handleNavigate}
                                    />
                                }
                            </Stack>
                        </Col>
                    </Row>
                </Container>
            </div>
            <GeneralRouterTabs
                options={tabOptions}
                variant='primary'
            >
                <Switch>
                    <Route path={routes.DetailedInformation}>
                        <DetailedInformation />
                        <AssetPowertrainSelector isAssetDetailsNeeded autoSelectFirstAsset key='detailed-information' />
                    </Route>
                    <Route path={routes.CrossKpi}>
                        <CrossAssetAnalysis />
                        <AssetPowertrainSelector key='cross-asset' />
                    </Route>
                    <Route path={tabOptions.conditionBasedMaintenance.route}>
                        <div>
                            Work in progress...
                        </div>
                    </Route>
                    <Redirect from='*' to={routes.DetailedInformation} />
                </Switch>
            </GeneralRouterTabs>
        </div>
    );
};

PowertrainAssetDetailsComponent.propTypes = {
    model: PropTypes.shape({
        selectedAsset: PropTypes.shape({
            componentID: PropTypes.string,
            componentName: PropTypes.string,
        })
    }),
    location: PropTypes.object
};

export default PowertrainAssetDetailsComponent;
